import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { url } from '../environment'
import { localStorageService } from '../services/localStorage'
// import { setCredentials } from '../redux/reducers/authSlice'
import { logOut, setSecrets } from '../redux/reducers/authSlice'
import { tagArray } from './rtkTags'
import { closeModal } from '../redux/reducers/modalSlice'

const baseQuery = fetchBaseQuery({
  baseUrl: url,
  reducerPath: 'api',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.token
    const refreshToken = getState().auth?.refreshToken
    const apiToken = localStorageService.get('sct')
    const refToken = localStorageService.get('scrt')
    if (token || apiToken) {
      const accessToken = token ?? apiToken
      headers.set('x-access-token', `${accessToken}`)
    }
    if (refreshToken || refToken) {
      headers.set('refresh-token', refreshToken || refToken)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // console.log(args) // request url, method, body
  // console.log('api',api) // signal, dispatch, getState()
  // console.log(extraOptions) //custom like {shout: true}

  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 401 && result?.error?.data.error?.message === 'Not Authenticated') {
    api.dispatch(logOut())
    api.dispatch(closeModal())
  }

  if (
    (result?.error?.status === 403 &&
      result?.error?.data.error?.message === 'You are not authorized to access this URI') ||
    result?.error?.data.error?.message ===
      'You are not authorized to access this URI Account is deactivated'
  ) {
    api.dispatch(logOut())
    api.dispatch(closeModal())
  }
  if (
    result?.error?.status === 417 &&
    result?.error?.data?.error?.message === 'Expectation Failed'
  ) {
    // If you want, handle other status codes, too
    const id = api.getState().auth?.user?._id || localStorageService.get('sci')
    // send refresh token to get new access token
    const refreshResult = await baseQuery(`/account/${id}/token`, api, extraOptions)
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setSecrets({ ...refreshResult.data }))

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = 'Your login has expired.'
        api.dispatch(logOut())
        api.dispatch(closeModal())
      }
      return refreshResult
    }
  }

  return result
}

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: tagArray,
  // refetchOnFocus: true, // Refetch on window focus or tab switch.
  // refetchOnMountOrArgChange: true ,// Refetch on component mount.
  // refetchOnMountOrArgChange accepts either a boolean value, or a number as time in seconds.
  // refetchOnReconnect: true,// Re-fetching on network reconnection
  // refetch every api
  // if you want to refetch just some api-es then add as options to  hook const { data: Categories } = useGetDataQuery(arguments,{refetchOnFocus: true})
  // you can't add it to endpoint
  // eslint-disable-next-line no-unused-vars
  endpoints: (builder) => ({}),
})
