import PropTypes from 'prop-types'
import { MdOutlineFileDownload } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'

import styles from './ImageBox.module.scss'

const ImageBox = ({ sender, url, position, date, onClick }) => {
  return (
    <div
      style={
        position === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }
      }
      className={styles.msgBoxWrapper}
    >
      <div className={styles[position]}>
        <div className={styles.sender}>
          <span>{sender}</span>
          <span>
            <a data-tooltip-id={`download-image-${url}`}>
              <Tooltip
                id={`download-image-${url}`}
                className={styles.imageTooltip}
                content='Download image'
                place='top'
              />

              {onClick && typeof onClick === 'function' && (
                <MdOutlineFileDownload className={styles.downloadIcon} onClick={onClick} />
              )}
            </a>
          </span>
        </div>
        <img className={styles.image} src={url} />
        <div className={styles.date}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  )
}

ImageBox.propTypes = {
  sender: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default ImageBox
