import { Route, Routes, Navigate } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Login from '../pages/login/Login'
import Register from '../pages/register/Register'
import Home from '../pages/home/Home'
import Chat from '../components/Chat/Chat'
import PublicRoute from './PublicRoute'
import ChatMessages from '../components/Chat/ChatMessages2'
import App from '../App'
import TwilioVideo from '../components/TwilioVideo/TwilioVideo4'
import UpdateDisplayName from '../pages/UpdateDisplayName/UpdateDisplayName'
import InCall from '../components/InCall/InCall'

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<App />}>
        <Route element={<PublicRoute />}>
          <Route path='login' element={<Login />} />
          <Route path='update-display-name' element={<UpdateDisplayName />} />
          <Route
            path='register'
            element={
              <>
                <Register />
              </>
            }
          />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
        <Route path='video/:code' element={<TwilioVideo />} />
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<Home />}>
            <Route path='/chat' element={<Chat />}>
              <Route path=':roomId' element={<ChatMessages />} />
            </Route>
            <Route path='/video' element={<TwilioVideo />} />
            <Route path='/call' element={<InCall />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRouter
