import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom/dist'
import {
  MdSettings,
  MdOutlineAccountCircle,
  MdOutlineLogout,
  MdInfoOutline,
  MdCheck,
} from 'react-icons/md'

import { BsPersonFillDown } from 'react-icons/bs'
import { logOut } from '../../redux/reducers/authSlice'

import logo from '../../assets/images/defaultAvatar.svg'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import initMatrix from '../../lib/initMatrix'
import Button from '../Button/Button'
import Account from '../SettingsCmp/Account'
import Invites from '../Invites/Invites'

import { openModal } from '../../redux/reducers/modalSlice'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { selectPresence } from '../../redux/reducers/matrixSlice'
import { CUSTOM_PRESENCE_TYP_MSGS } from '../../constants/matrixConst'
import { localStorageService } from '../../services/localStorage'
import { setPresence } from '../../redux/reducers/matrixSlice'
import { store } from '../../redux/store'
import { useSetStatusMutation } from '../../api/authApiSlice'
import initTwilioVoice from '../../lib/initTwilioVoice'

import styles from './ProfileSidebar.module.scss'
import toastService from '../../services/toastService'

const presences = [
  CUSTOM_PRESENCE_TYP_MSGS.online,
  CUSTOM_PRESENCE_TYP_MSGS.offline,
  CUSTOM_PRESENCE_TYP_MSGS.busy,
  CUSTOM_PRESENCE_TYP_MSGS.dnd,
]

const ProfileSidebar = () => {
  const [showStatus, setShowStatus] = useState(false)
  const [showSettings, setShowSetings] = useState(false)
  const refStatus = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const settingsRef = useRef(null)

  let presence = useSelector(selectPresence) || localStorageService.get('presence')
  const [setPresenceMutation] = useSetStatusMutation()

  useOnClickOutside(settingsRef, () => setShowSetings(false))

  useEffect(() => {
    document.addEventListener('click', refHandler, true)
    return () => {
      document.removeEventListener('click', refHandler, true)
    }
  }, [])

  const openModalHandler = () => {
    dispatch(
      openModal({
        show: true,
        content: <Invites user={user} />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const openAccountModal = () => {
    dispatch(
      openModal({
        show: true,
        content: <Account />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const statusHandler = () => {
    setShowStatus(!showStatus)
  }

  const refHandler = (e) => {
    if (refStatus.current && !refStatus.current.contains(e.target)) {
      setShowStatus(false)
    }
  }

  const handleLogout = () => {
    initMatrix.logout()
    initTwilioVoice.logOut()
    dispatch(logOut())
    navigate('/register')
  }

  const handleChangePresence = async (value) => {
    try {
      const data = await setPresenceMutation({ accountId: user._id, status_msg: value })
      if (!data.error) {
        store.dispatch(setPresence(value))
        localStorageService.set('presence', value)
      } else {
        throw Error('Something went wrong, not able to set status')
      }
    } catch (err) {
      toastService.show('error', err.message)
    } finally {
      setShowStatus(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.userDiv}>
        <div className={styles[presence.toLowerCase()]}></div>
        {showStatus && (
          <div ref={refStatus} className={styles.statusDiv}>
            {presences.map((status) => {
              return (
                <div key={status.value} className={styles.checkStatus}>
                  {presence === status.value && <MdCheck className={styles.checkIcon} />}
                  <span
                    style={
                      presence === status.value ? { marginLeft: '2px' } : { marginLeft: '15px' }
                    }
                    onClick={() => handleChangePresence(status.value)}
                  >
                    {status.name}
                  </span>
                </div>
              )
            })}
          </div>
        )}
        <img src={logo} alt='' />
        <div className={styles.statusInfo}>
          {user?.displayName ? (
            <span>{user.displayName}</span>
          ) : user?.number ? (
            <span>{user.number}</span>
          ) : (
            <span>{user?.email}</span>
          )}
          <span onClick={statusHandler} className={styles.status}>
            {CUSTOM_PRESENCE_TYP_MSGS?.[presence]?.name}
          </span>
        </div>
      </div>
      <Button className={styles.settingsBtn} onClick={() => setShowSetings(!showSettings)}>
        <MdSettings className={styles.setting} />
      </Button>
      {showSettings && (
        <div className={styles.settingsWrapper} ref={settingsRef}>
          <div className={styles.settingsItem} onClick={openAccountModal}>
            <MdOutlineAccountCircle /> Account
          </div>
          <div className={styles.settingsItem} onClick={openModalHandler}>
            <BsPersonFillDown /> Invites
          </div>
          <div className={styles.settingsItem}>
            <MdInfoOutline />
            <a rel='noopener noreferrer' href='https://airtouch.me' target='_blank'>
              About
            </a>
          </div>
          <div className={styles.settingsItem} onClick={handleLogout}>
            <MdOutlineLogout />
            Logout
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileSidebar
