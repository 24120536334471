import { GoogleOAuthProvider } from '@react-oauth/google'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useSelector } from 'react-redux'
import { selectModal } from './redux/reducers/modalSlice'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ModalWrapper from './components/Modal/Modal'

import './App.css'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const SEGMENT_ANALYTICS_WRITE_KEY = process.env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY

export const segmentAnalytics = AnalyticsBrowser.load({ writeKey: SEGMENT_ANALYTICS_WRITE_KEY })

function App() {
  const modalStore = useSelector(selectModal)
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div>
        {modalStore.show ? (
          <ModalWrapper
            show={modalStore.show}
            content={modalStore.content}
            closeOnOutsideClick={modalStore.closeOnOutsideClick}
            size={modalStore.size}
            showCloseIcon={modalStore.showCloseIcon}
            callbackOnClose={modalStore.callbackOnClose}
            customStyle={modalStore.customStyle}
          />
        ) : null}
        <ToastContainer
          className='toast'
          toastClassName='toast-wrapper'
          bodyClassName='toast-body'
          closeButton={true}
          autoClose={4000}
        />
        <Outlet />
      </div>
    </GoogleOAuthProvider>
  )
}

export default App
