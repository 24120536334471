import PropTypes from 'prop-types'
import { MdInfo } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'
import {
  useGetInvitesQuery,
  useAcceptInvitationMutation,
  useRejectInvitationMutation,
} from '../../api/invitationApiSlice'

import styles from './Invites.module.scss'
import Button from '../Button/Button'
import toastService from '../../services/toastService'
import { closeModal } from '../../redux/reducers/modalSlice'
import { useDispatch } from 'react-redux'

const Invites = ({ user }) => {
  const dispatch = useDispatch()
  const { data, error, isLoading } = useGetInvitesQuery(user?._id, {
    skip: !user?._id,
    refetchOnMountOrArgChange: true,
  })
  const [acceptInvitation] = useAcceptInvitationMutation()
  const [rejectInvitation] = useRejectInvitationMutation()

  const handleAccept = async (invite) => {
    const response = await acceptInvitation({ accountId: user._id, invitationId: invite._id })
    if (!response.error) {
      toastService.show('success', `Successfully joined the group ${invite?.organizationName}`)
      dispatch(closeModal())
    } else {
      toastService.show('error', 'Somehting went wrong')
    }
  }

  const handleReject = async (invite) => {
    const response = await rejectInvitation({ accountId: user._id, invitationId: invite._id })
    if (!response.error) {
      toastService.show(
        'success',
        `Successfully rejected an invitation to join the group ${invite?.organizationName}`,
      )
      dispatch(closeModal())
    } else {
      toastService.show('error', 'Somehting went wrong')
    }
  }

  return (
    <div className={styles.invitesWrapper}>
      <h3>Invites</h3>
      {isLoading && <div>Loading...</div>}
      {error && <p>{error.message || 'Something went wrong, not able to get invites'}</p>}
      {!error && !isLoading && Array.isArray(data?.invites) && data.invites.length ? (
        data?.invites.map((invite) => {
          return (
            <div key={invite._id} className={styles.invite}>
              <div>
                <span>Group name:</span>
                <span>{invite.organizationName}</span>
              </div>
              <div className={styles.btnsWrapper}>
                <Button onClick={() => handleAccept(invite)} className={styles.btnAccept}>
                  Accept
                </Button>
                <Button onClick={() => handleReject(invite)} className={styles.btnReject}>
                  Reject
                </Button>
                <a data-tooltip-id='info'>
                  <MdInfo className={styles.info} />
                </a>
                <Tooltip
                  id='info'
                  content={`If you join ${invite.organizationName} you will be able to call from this group and spend its minuts.`}
                  openOnClick={['click']}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div>There are no invitations pending!</div>
      )}
    </div>
  )
}

Invites.propTypes = {
  user: PropTypes.object.isRequired,
}

export default Invites
