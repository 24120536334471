import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom/dist'
import { Provider } from 'react-redux'
import 'normalize.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import AppRouter from './router/routes'
import { store } from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (navigator && 'serviceWorker' in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator?.serviceWorker?.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).then(
    (registration) => {
      console.log('Service worker registration succeeded:', registration)
    },
    /*catch*/ (error) => {
      console.error(`Service worker registration failed: ${error}`)
    },
  )
} else {
  console.error('Service workers are not supported.')
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
