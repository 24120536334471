import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from '../../services/localStorage'

const initialToken =
  localStorage.getItem('sct') && localStorage.getItem('sct') !== 'undefined'
    ? JSON.parse(localStorage.getItem('sct'))
    : null
const initialId =
  localStorage.getItem('sci') && localStorage.getItem('sci') !== 'undefined'
    ? JSON.parse(localStorage.getItem('sci'))
    : null

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, accessToken: initialToken, id: initialId },
  reducers: {
    setCredentials: (state, action) => {
      const {
        accessToken,
        account: { _id },
        refreshToken,
      } = action.payload
      state.user = action.payload.account
      state.accessToken = accessToken
      state.id = _id
      state.refreshToken = refreshToken
      localStorageService.set('sct', accessToken)
      localStorageService.set('sci', _id)
      localStorageService.set('scrt', refreshToken)
    },
    logOut: (state) => {
      localStorageService.clearStorage()
      state.user = null
      state.accessToken = null
      state.refreshToken = null
      state.id = null
    },
    setUser: (state, action) => {
      state.user = action.payload
      localStorageService.set('ap', action.payload.activePackage)
    },
    setSecrets: (state, action) => {
      localStorageService.set('sct', action.payload.accessToken)
      state.accessToken = action.payload.accessToken
    },
  },
})

export const { setCredentials, logOut, setUser, setSecrets } = authSlice.actions

export default authSlice.reducer

export const selectCurrentId = (state) => state.auth.id || localStorageService.get('sci')
export const selectCurrentUser = (state) => state.auth.user
