import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BsLink } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { selectAirtouchContactsMap } from '../../redux/reducers/contactsSlice'
import toastService from '../../services/toastService'
import { formatSeconds } from '../../utils/dateParsing'
import styles from './TwilioVideo4.module.scss'

const InfoVideo = ({ showInfoRef, duration, videoLinkShare, remoteParticipants }) => {
  const airtouchContactsMap = useSelector(selectAirtouchContactsMap)
  const [timer, setTimer] = useState(duration)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const accountName = remoteParticipants.map((element, index) => {
    const foundContact = airtouchContactsMap[element.identity]
    const isUsernameMatch = foundContact && foundContact.endpoint.username === element.identity
    const displayName = isUsernameMatch
      ? foundContact?.displayName
      : foundContact?.name || foundContact?.email
    return (
      <React.Fragment key={index}>
        <span className={styles.participantIdentity}>{displayName}</span>
      </React.Fragment>
    )
  })

  return (
    <div ref={showInfoRef} className={styles.infoButton}>
      <div className={styles.callInformation}>
        {remoteParticipants?.length > 1 ? (
          <div className={styles.url}>
            <span>URL: </span>
            <span className={styles.urlLink}>
              {videoLinkShare?.inviteLink}
              <button
                onClick={() => {
                  navigator.clipboard.writeText(videoLinkShare?.inviteLink)
                  toastService.show('success', `Copied meeting link`)
                }}
                className={` ${styles.shareLink} `}
              >
                <BsLink />
              </button>
            </span>
          </div>
        ) : null}

        <div className={styles.elapsedTime}>
          <span>Elapsed Time:</span>
          <div className={styles.duration}>
            <span>{formatSeconds(timer)}</span>
          </div>
        </div>
        <div className={styles.identity}>
          <span>Participants: {remoteParticipants?.length}</span>
          <div className={styles.participantIdentityDiv}>{accountName}</div>
        </div>
      </div>
    </div>
  )
}

export default InfoVideo

InfoVideo.propTypes = {
  showInfoRef: PropTypes.any,
  duration: PropTypes.any,
  localVideoTrack: PropTypes.any,
  videoLinkShare: PropTypes.any,
  remoteParticipants: PropTypes.any,
}
