import React from 'react'
import { useSelector } from 'react-redux'
import Contact from '../../components/Contact/Contact'
import { selectFavorites } from '../../redux/reducers/contactsSlice'
import { getPresenceFromContact } from '../../utils/contacts'
import { selectAllMembers } from '../../redux/reducers/matrixSlice'

import styles from './Favorites.module.scss'

const Favorites = () => {
  const allMembers = useSelector(selectAllMembers)
  const favorites = useSelector(selectFavorites)
  if (!favorites || !Object.keys(favorites).length)
    return (
      <div className={styles.wrapper}>
        <div className={styles.noFavorites}>
          <span>You have not added any favorite contacts</span>
        </div>
      </div>
    )
  return (
    <div className={styles.wrapper}>
      {Object.keys(favorites).map((key) => {
        return (
          <div key={key}>
            <Contact
              contact={favorites[key]}
              isFavorite
              presence={getPresenceFromContact(allMembers, favorites[key])}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Favorites
