import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import SidebarOrganization from '../../components/SidebarOrganization/SidebarOrganization'
import Socket from '../../Socket.jsx'
import { getToken } from 'firebase/messaging'
import { messaging, onMessageListener } from '../../firebase'

import { useWebTokenMutation, useSendVoiceAnsweredMutation } from '../../api/authApiSlice'
import initMatrix from '../../lib/initMatrix'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import IncomingCall from '../../components/IncomingCall/IncomingCall.jsx'
import logo from '../../assets/images/logo.png'
import { setSearchText } from '../../redux/reducers/searchSlice'
import { selectIsTriggered } from '../../redux/reducers/pushSlice'
import toastService from '../../services/toastService'

import { closeModal, openModal } from '../../redux/reducers/modalSlice'
import { MdSearch } from 'react-icons/md'
import { matrixUrl } from '../../environment'

const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY

import styles from './Home.module.scss'
import initTwilioVoice from '../../lib/initTwilioVoice'
import { CUSTOM_EVENT_TYPES } from '../../constants/matrixConst'
import { parseString } from '../../utils/string'
import { PUSH_MESSAGE_TYPES } from '../../constants/pushConstants'
import { dispatchCustomEvent } from '../../utils/fireEvents'
import { selectIsInCall } from '../../redux/reducers/callSlice.js'

const Home = () => {
  const user = useSelector(selectCurrentUser)
  const isTirggered = useSelector(selectIsTriggered)

  const [webToken] = useWebTokenMutation()
  const [sendVoiceAnsweredMutation] = useSendVoiceAnsweredMutation()
  const dispatch = useDispatch()
  const store = useSelector(selectCurrentUser)
  const isInCall = useSelector(selectIsInCall)
  const navigate = useNavigate()

  const initMatrixOnlyOnce = useRef(false)
  const initTwilioVoiceOnce = useRef(false)
  useEffect(() => {
    if (user?.matrix && !initMatrixOnlyOnce.current) {
      initMatrix.init(user.matrix.username, user.matrix.password, matrixUrl, user.matrix.device_id)
      initMatrixOnlyOnce.current = true
    }
  }, [user?.matrix?.username, user?.matrix?.passowrd, user?.matrix])

  useEffect(() => {
    if (user?._id && !initTwilioVoiceOnce.current && !initTwilioVoice.device) {
      initTwilioVoice.init()
      initTwilioVoiceOnce.current = true
    }
  }, [user, user?._id])

  useEffect(() => {
    const handleAnswer = async (name, organizationName, callerId, newCallData) => {
      dispatch(closeModal())
      // calling this api server knows to send push notification
      await sendVoiceAnsweredMutation({
        callSid: initTwilioVoice?.connection?.customParameters?.get('callSid'),
        accountId: store?._id,
      })
      const incomingCallSid =
        newCallData?.incomingCallSid ||
        initTwilioVoice.connection?.customParameters.get('callSid') ||
        initTwilioVoice.connection?.parameters?.CallSid
      initTwilioVoice.acceptCall({
        caller: name,
        organizationName,
        callerId,
        incomingCallSid,
      })
      navigate('/call', { state: { incommingCall: true, name, organizationName } })
    }

    const handleReject = () => {
      initTwilioVoice.handleReject()
      navigate('/')
    }
    const handleIncommingCall = (event) => {
      let detail = null
      if (event?.detail?.data) {
        detail = event.detail.data
      }
      dispatch(
        openModal({
          show: true,
          content: (
            <IncomingCall
              incomingConnection={initTwilioVoice.connection}
              handleAnswer={handleAnswer}
              handleReject={handleReject}
              newCallData={detail}
            />
          ),
          size: 'md',
          closeOnOutsideClick: false,
          callbackOnClose: initTwilioVoice.handleReject,
          customStyle: 'incommingCall',
          showCloseIcon: false,
        }),
      )
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.incommingCall, handleIncommingCall)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.incommingCall, handleIncommingCall)
    }
  }, [dispatch, navigate, sendVoiceAnsweredMutation, store?._id, isTirggered])

  useEffect(() => {
    const handleCancelCall = () => {
      dispatch(closeModal())
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.cancelCall, handleCancelCall)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.cancelCall, handleCancelCall)
    }
  }, [dispatch])

  //-------------------------------------------------------------------------
  //twilio video

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
          const token = await getToken(messaging, {
            vapidKey: VAPID_KEY,
          })
          if (token) {
            await webToken({ token: token, accountId: store?._id })
          }
        } else if (permission === 'denied') {
          toastService.show('error', 'You denied for the notification')
        }
      } catch (err) {
        console.log('ERROR GETTING PUHS NOTIFICATION:', err)
      }
    }
    requestPermission()
  }, [store?._id, webToken])

  // LISTENING FOR THE PUSH NOTIFICATION
  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        const dataPayload = parseString(payload?.data?.payload)
        const message = parseString(payload?.data?.message)
        if (!isInCall && message?.type === PUSH_MESSAGE_TYPES.join_video_room) {
          const caller = dataPayload.caller
          const roomName = dataPayload.roomName
          const audioOnly = dataPayload?.audioOnly
          navigate('/video', {
            state: {
              caller,
              roomName,
              title: payload.notification.title,
              body: payload.notification.body,
              push: true,
              audioOnly,
            },
          })
        }
        if (!isInCall && message?.type === PUSH_MESSAGE_TYPES.merge_video_call) {
          initTwilioVoice.handleReject()
          navigate('/video', {
            state: {
              roomName: dataPayload.roomName,
              roomId: dataPayload.roomName,
              title: payload.notification.title,
              body: payload.notification.body,
              push: true,
              audioOnly: dataPayload.audioOnly,
            },
          })
        }
        const data = parseString(payload?.data?.payload)
        if (message?.type === PUSH_MESSAGE_TYPES.answered_call) {
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.answered_call, { message, data })
        }
        if (message?.type === PUSH_MESSAGE_TYPES.new_voice_call) {
          initTwilioVoice.setNewCall({ message, data })
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.incommingCall, { message, data })
        }
        if (message?.type === PUSH_MESSAGE_TYPES.call_ended) {
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.call_ended, { message, data })
        }
        if (message?.type === PUSH_MESSAGE_TYPES.put_on_hold) {
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.put_on_hold)
        }
        if (message?.type === PUSH_MESSAGE_TYPES.active_in_conference) {
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.active_in_conference)
        }
      })
      .catch((err) => console.log('PAYLOAD: HOME: CATCH failed: ', err))
  }, [navigate, isTirggered, dispatch, isInCall])

  useEffect(() => {
    /**
     * handleSocketMessage
     * @param {event} e Event
     */
    const hendleSocketMessages = (e) => {
      // TODO implement logic for hanling socket messages when we are ready to replace push messages
      console.log('===== This is socket message', { e })
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.socketResponse, hendleSocketMessages)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.socketResponse, hendleSocketMessages)
    }
  }, [])

  const handleFilterContacts = (e) => {
    const { value } = e.target
    dispatch(setSearchText(value.toLowerCase()))
  }

  return (
    <div className={styles.appWrapper}>
      <Socket />
      <header className={styles.appToolbar}>
        <img src={logo} />
        <div className={styles.searchInputWrapper}>
          <input
            type='text'
            onChange={handleFilterContacts}
            placeholder='Search'
            className={styles.input}
          />
          <MdSearch className={styles.icon} />
        </div>
      </header>
      <div className={styles.homeWrapper}>
        <div className={styles.sidibarOrganizationWrapper}>
          <SidebarOrganization />
        </div>
        <div className={styles.mainContentWrapper}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Home
