import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Contact from '../../components/Contact/Contact'
import { useGetContactsQuery } from '../../api/contactsApiSlice'
import { selectCurrentId, selectCurrentUser } from '../../redux/reducers/authSlice'
import { sortContacts } from '../../utils/contacts'
import { selectFavorites } from '../../redux/reducers/contactsSlice'
import { selectAllMembers } from '../../redux/reducers/matrixSlice'
// import { removePrefixFromString, getPresenceFromContact } from '../../utils/contacts'
import { getPresenceFromContact } from '../../utils/contacts'
import { filterOrganizationContacts } from '../../utils/contacts'
import { selectSearchText } from '../../redux/reducers/searchSlice'

import styles from './Contact.module.scss'

const Contacts = () => {
  const id = useSelector(selectCurrentId)
  const favorites = useSelector(selectFavorites)
  const searchText = useSelector(selectSearchText)
  const user = useSelector(selectCurrentUser)
  let { data } = useGetContactsQuery(id, { refetchOnMountOrArgChange: true })
  const filteredOrganizationContacts = filterOrganizationContacts(
    data?.accountContacts,
    data?.organizationContacts,
  )

  data = [...filteredOrganizationContacts, ...(data?.accountContacts || [])]

  const allMembers = useSelector(selectAllMembers)

  const sortedContacts = useMemo(() => sortContacts(data, searchText), [data, searchText])

  return (
    <div className={styles.wrapper}>
      {sortedContacts?.map((contact, index) => {
        if (
          (user?.number && contact?.number === user.number) ||
          (user?.email && contact?.email === user.email)
        ) {
          return null
        }
        return (
          <Contact
            contact={contact}
            key={contact._id || contact?.matrix?.matrixId || index}
            isFavorite={!!favorites?.[contact?.matrix?.matrixId]}
            presence={getPresenceFromContact(allMembers, contact)}
          />
        )
      })}
    </div>
  )
}

export default Contacts
