import { useEffect, useState } from 'react'
import { MdGroupAdd, MdPersonAdd } from 'react-icons/md'

import initMatrix from '../../lib/initMatrix'
import Room from '../Room/Room'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsLoadingMatrix } from '../../redux/reducers/matrixSlice'
import { openModal } from '../../redux/reducers/modalSlice'

import AddToChat from '../AddToChat/AddToChat'
import { CUSTOM_EVENT_TYPES } from '../../constants/matrixConst'

import styles from './RoomList.module.scss'

const RoomList = () => {
  const { rooms } = initMatrix
  const isLoading = useSelector(selectIsLoadingMatrix)
  const dispatch = useDispatch()
  const [hasNewRoom, setHasNewRoom] = useState(false)

  const handleAddGroupChat = () => {
    dispatch(
      openModal({
        show: true,
        content: <AddToChat isGroupChat={true} />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const handleAddChat = () => {
    dispatch(
      openModal({
        show: true,
        content: <AddToChat isGroupChat={false} />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  useEffect(() => {
    const handler = () => {
      setHasNewRoom(!hasNewRoom)
    }
    // listen for the new room event that is comming from initMatrix
    document.addEventListener(CUSTOM_EVENT_TYPES.newRoomCreated, handler)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.newRoomCreated, handler)
    }
  }, [hasNewRoom])

  if (isLoading) return <div>Loading...</div>
  if (!rooms || !rooms.size) return null

  const listOfRooms = [...rooms]
  listOfRooms.sort((a, b) => {
    const aTimeline = a.getLiveTimeline()
    const bTimeline = b.getLiveTimeline()

    let aEvents = aTimeline.getEvents()
    let bEvents = bTimeline.getEvents()
    aEvents = aEvents.filter((event) => event.getType() === 'm.room.message')
    bEvents = bEvents.filter((event) => event.getType() === 'm.room.message')

    const aLastEventTs =
      Array.isArray(aEvents) && aEvents.length
        ? aEvents[aEvents.length - 1].getTs()
        : Number.MIN_SAFE_INTEGER

    const bLastEventTs =
      Array.isArray(bEvents) && bEvents.length
        ? bEvents[bEvents.length - 1].getTs()
        : Number.MIN_SAFE_INTEGER

    return bLastEventTs - aLastEventTs
  })

  return (
    <div className={styles.roomListWrapper}>
      <div className={styles.creatChatBtns}>
        <button onClick={handleAddChat}>
          Create chat
          <MdPersonAdd className={styles.icon} />
        </button>
        <button onClick={handleAddGroupChat}>
          Start Conversation
          <MdGroupAdd className={styles.icon} />
        </button>
      </div>
      <div className={styles.roomList}>
        {listOfRooms?.map((room) => {
          if (!room?.roomId) return null
          return <Room key={room.roomId} room={room} />
        })}
      </div>
    </div>
  )
}

export default RoomList
