import PropTypes from 'prop-types'

import styles from './CustomIcon.module.scss'

const CustomIcon = ({ label, icon, onClick }) => {
  return (
    <div onClick={onClick} className={styles.iconWrapper}>
      {icon}
      {label && <span>{label}</span>}
    </div>
  )
}

CustomIcon.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}

export default CustomIcon
