import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useUpdateDisplayNameMutation } from '../../api/authApiSlice'
import { setCredentials } from '../../redux/reducers/authSlice'

import Button from '../../components/Button/Button'
import Input from '../../components/Input/Input'
import toastService from '../../services/toastService'

import logo from '../../assets/images/logo.png'
import styles from './UpdateDisplayName.module.scss'

const UpdateDisplayName = () => {
  const location = useLocation()
  const { state } = location
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [displayName, setDisplayName] = useState('')
  const [updateDisplayName] = useUpdateDisplayNameMutation()

  const displayNameHandler = async (e) => {
    e.preventDefault()
    try {
      if (displayName.trim() === '') {
        toastService.show('error', 'Display Name can not be empty')
        return
      }
      const data = await updateDisplayName({
        displayName: displayName,
        accountId: state?.data?.data?.account?._id,
        accessToken: state?.data?.data?.accessToken,
      })
      const emailState = state?.data?.data
      emailState.account = data?.data
      dispatch(setCredentials(emailState))
      navigate('/')
      return
    } catch (err) {
      toastService.show('Something went wrong')
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.registerContainer}>
        <div className={styles.logoDiv}>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        <p className={styles.confirmMsg}>Please Enter Display Name</p>
        <form onSubmit={displayNameHandler}>
          <div className={styles.confirmDiv}>
            <Input
              placeholder='Display Name'
              className={styles.displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <Button label='Confirm' />
            <Link className={styles.linkToRegister} to={'/register'}>
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateDisplayName
