import PropTypes from 'prop-types'
import styles from './RadioBtn.module.scss'

const RadioBtn = ({ value, label, checked, onChange }) => {
  return (
    <div className={styles.container}>
      <label key={value} className={styles.label}>
        <input
          type='radio'
          value={value}
          checked={checked}
          onChange={onChange}
          className={styles.radio}
        />
        {label}
      </label>
    </div>
  )
}

RadioBtn.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RadioBtn
