import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import styles from './TwilioVideo4.module.scss'

const RemoteParticipant = ({ room, participant, style }) => {
  const dominantSpeakerIdRef = useRef(null)
  const [remoteParticipant, setRemoteParticipant] = useState(null)

  useEffect(() => {
    if (participant?.sid) setRemoteParticipant(participant)
  }, [participant])

  useEffect(() => {
    if (!room) {
      return
    }
    const handleDominantSpeakerChanged = (participant) => {
      dominantSpeakerIdRef.current = participant?.sid
      const participantElements = document.getElementsByClassName('remote-participant')

      for (let i = 0; i < participantElements.length; i++) {
        const participantElement = participantElements[i]

        if (participant?.sid === participantElement.getAttribute('data-participant-id')) {
          participantElement.style.border = '4px solid #027ffe'
          participantElement.style.borderRadius = '0.5rem'
          participantElement.style.order = 1
        } else {
          participantElement.style.border = 'none'
        }
      }
    }

    room.on('dominantSpeakerChanged', handleDominantSpeakerChanged)

    return () => {
      room.off('dominantSpeakerChanged', handleDominantSpeakerChanged)
    }
  }, [room])

  // useEffect(() => {
  //   if (remoteParticipant?.on) {
  //     const handleTruckSubscribed = (track) => {
  //       const newParticipants = [...remoteParticipants].map((p) => {
  //         if (p?.sid === remoteParticipant?.sid) {
  //           console.log('THIS IS P: ', { p, track, remoteParticipant })
  //           const key = p.videoTracks.keys()[0]
  //           if (key) p.videoTracks.set(key, track)
  //           // return { ...p, videoTrack: track }
  //         }
  //         return p
  //       })
  //       setRemoteParticipants(newParticipants)
  //     }

  //     const handleTrackUnsubscribed = (data) => {
  //       console.log('handleTrackUnsubscribed = This is data: ', data)
  //       data.detach().forEach(function (mediaElement) {
  //         mediaElement.remove()
  //       })
  //     }

  //     remoteParticipant?.on('trackSubscribed', handleTruckSubscribed)

  //     remoteParticipant?.on('trackUnsubscribed', handleTrackUnsubscribed)

  //     return () => {
  //       remoteParticipant?.off('trackSubscribed', handleTruckSubscribed)
  //       remoteParticipant?.off('trackUnsubscribed', handleTrackUnsubscribed)
  //     }
  //   }
  // }, [remoteParticipant, remoteParticipants, setRemoteParticipants, remoteParticipant?.on])

  if (!remoteParticipant) return null

  let mediaStream = null
  const videoTracks = remoteParticipant?.videoTracks
  if (videoTracks && videoTracks.size > 0) {
    const videoTrack = Array.from(videoTracks.values())[0]?.track
    if (videoTrack) {
      mediaStream = new MediaStream([videoTrack.mediaStreamTrack])
    }
  }
  // let mediaStreams = []
  // const videoTracks = remoteParticipant?.videoTracks
  // if (videoTracks && videoTracks.size > 0) {
  //   const videoTrack = Array.from(videoTracks.values())
  //   if (Array.isArray(videoTrack)) {
  //     videoTrack.forEach((track) => {
  //       if (track?.track?.mediaStreamTrack) {
  //         mediaStreams.push(new MediaStream([track.track.mediaStreamTrack]))
  //       }
  //     })
  //     // mediaStream = new MediaStream([videoTrack.mediaStreamTrack])
  //   }
  // }

  return (
    <div
      className={`${styles.videoContainer} remote-participant`}
      data-participant-id={participant?.sid}
      style={style}
    >
      <video
        // key={`remoteVideo-${remoteParticipant?.sid}-${isSharing} `}
        className={styles.video}
        id={`remoteVideo-${remoteParticipant?.sid}`}
        ref={(ref) => {
          if (ref) {
            // const videoTracks = remoteParticipant?.videoTracks
            // if (videoTracks && videoTracks.size > 0) {
            //   const videoTrack = Array.from(videoTracks.values())[0]?.track
            //   if (videoTrack) {
            //     const mediaStream = new MediaStream([videoTrack.mediaStreamTrack])
            //     ref.srcObject = mediaStream
            //   }
            // }

            ref.srcObject = mediaStream || null
          }
        }}
        autoPlay
      />
      {/* {mediaStreams.map((mediaStream) => {
        return (
          <video
            key={`remoteVideo-${mediaStream.id} `}
            className={styles.videoParticipant}
            id={`remoteVideo-${remoteParticipant?.sid}`}
            ref={(ref) => {
              if (ref) {
                // const videoTracks = remoteParticipant?.videoTracks
                // if (videoTracks && videoTracks.size > 0) {
                //   const videoTrack = Array.from(videoTracks.values())[0]?.track
                //   if (videoTrack) {
                //     const mediaStream = new MediaStream([videoTrack.mediaStreamTrack])
                //     ref.srcObject = mediaStream
                //   }
                // }
                ref.srcObject = mediaStream || null
              }
            }}
            autoPlay
          />
        )
      })} */}
      <audio
        key={`remoteAudio-${participant?.sid}`}
        className={styles.audioParticipant}
        ref={(ref) => {
          if (ref) {
            const audioTracks = participant?.audioTracks
            if (audioTracks && audioTracks.size > 0) {
              const audioTrack = Array.from(audioTracks.values())[0]?.track
              if (audioTrack) {
                const mediaStream = new MediaStream([audioTrack.mediaStreamTrack])
                ref.srcObject = mediaStream
              }
            }
          }
        }}
        autoPlay
      />
    </div>
  )
}

export default RemoteParticipant

RemoteParticipant.propTypes = {
  room: PropTypes.any,
  participant: PropTypes.any,
  trackName: PropTypes.string,
  style: PropTypes.object,
}
