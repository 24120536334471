import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from '../api'
import authReducer from './reducers/authSlice'
import contactsReducer from './reducers/contactsSlice'
import matrixReducer from './reducers/matrixSlice'
import modalReducer from './reducers/modalSlice'
import extensionsReducer from './reducers/extensionsSlice'
import searchReducer from './reducers/searchSlice'
import callsReducer from './reducers/callSlice'
import pushReducer from './reducers/pushSlice'

// import { parseErrorMessage } from '../utils/errorParsing'
// import toastService from '../services/toastService'

const env = process.env.NODE_ENV

const rtkQueryErrorLogger = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // console.warn('We got a rejected action!', api, action.payload)
    // const base = action?.payload?.data
    // const msgErr = base?.error?.message || base?.error || base?.message || 'Something went wrong'
    // toastService.show('error', parseErrorMessage(msgErr))
  }
  return next(action)
}

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    contacts: contactsReducer,
    matrix: matrixReducer,
    modal: modalReducer,
    extensions: extensionsReducer,
    search: searchReducer,
    calls: callsReducer,
    push: pushReducer,
  },
  middleware: (getDefaultMiddleware) =>
    env === 'development'
      ? [
          ...getDefaultMiddleware({ serializableCheck: false }),
          logger,
          api.middleware,
          rtkQueryErrorLogger,
        ]
      : [
          ...getDefaultMiddleware({ serializableCheck: false }),
          api.middleware,
          rtkQueryErrorLogger,
        ],
  devTools: false,
})

setupListeners(store.dispatch)
