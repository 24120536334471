import PropTypes from 'prop-types'

import styles from './Checkbox.module.scss'

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <label className={styles.label}>
      <input type='checkbox' checked={checked} onChange={onChange} className={styles.checkbox} />
      {label}
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Checkbox
