import { createSlice } from '@reduxjs/toolkit'

const pushSlice = createSlice({
  name: 'push',
  initialState: {
    isTirggered: false,
  },
  reducers: {
    toggleTriggered: (state) => {
      state.isTirggered = !state.isTirggered
    },
  },
})

export const { toggleTriggered } = pushSlice.actions

export default pushSlice.reducer

export const selectIsTriggered = (state) => state.push.isTirggered
