import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  useLazyGetExtensionsQuery,
  useUpdateOrganizationMutation,
} from '../../api/extensionsApiSlice'
import { formatDate } from '../../utils/dateParsing'
import RadioBtn from '../Input/RadioBtn'

import { MdModeEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useSetStatusMutation, useUpdateDisplayNameMutation } from '../../api/authApiSlice'
import { ERROR_SET_STATUS } from '../../constants/errorConstants'
import { CUSTOM_PRESENCE_TYP_MSGS } from '../../constants/matrixConst'
import { selectCurrentUser, setUser } from '../../redux/reducers/authSlice'
import { selectPresence, setPresence } from '../../redux/reducers/matrixSlice'
import { closeModal } from '../../redux/reducers/modalSlice'
import { store } from '../../redux/store'
import { localStorageService } from '../../services/localStorage'
import toastService from '../../services/toastService'
import Button from '../Button/Button'
import Input from '../Input/Input'
import styles from './Account.module.scss'
import { setSelectedExtension } from '../../redux/reducers/extensionsSlice'
import { marketingSiteUrl } from '../../environment'

const Account = () => {
  const [selectedExtensionState, setSelectedExtensionState] = useState(null)
  const [displayName, setDisplayName] = useState('')
  const [getExtensionQuery, { data, isLoading, error }] = useLazyGetExtensionsQuery()
  const { extensions } = data || []
  const presence = useSelector(selectPresence)
  const [setStatusMutation] = useSetStatusMutation()
  const [updateDisplayName] = useUpdateDisplayNameMutation()
  const [updateOrganization] = useUpdateOrganizationMutation()
  const [showDisplayNameInput, setShowDisplayNameInput] = useState(false)
  const user = useSelector(selectCurrentUser)

  const dispatch = useDispatch()

  const userExtension = extensions?.find(
    (extension) => extension.organizationId === user.organizationId,
  )

  useEffect(() => {
    setSelectedExtensionState(userExtension)
    dispatch(setSelectedExtension(userExtension))
  }, [dispatch, userExtension])

  useEffect(() => {
    if (user?._id) getExtensionQuery(user?._id)
  }, [getExtensionQuery, user?._id])

  const handleChangeSelectionGroup = async (extension) => {
    const data = await updateOrganization({ accountId: user?._id, extensionId: extension?._id })
    if (data?.error) {
      toastService.show('error', data.error?.data?.error?.message)
    } else {
      setSelectedExtensionState(extension)
      store.dispatch(setUser(data.data))
    }
  }

  const handleChangePresence = async (e) => {
    try {
      const { value } = e.target
      const data = await setStatusMutation({ accountId: user._id, status_msg: value })
      if (!data.error) {
        store.dispatch(setPresence(value))
        localStorageService.set('presence', value)
      } else {
        throw Error(ERROR_SET_STATUS)
      }
    } catch (err) {
      toastService.show('error', err.message)
    }
  }

  const displayNameHandler = async () => {
    try {
      if (displayName.trim() === '') {
        toastService.show('error', 'Display Name can not be empty')
        return
      }
      const data = await updateDisplayName({ displayName: displayName, accountId: user?._id })
      store.dispatch(setUser(data.data))
      dispatch(closeModal())
    } catch (err) {
      toastService.show('Something went wrong')
    }
  }

  const showInputHandler = () => {
    setShowDisplayNameInput(!showDisplayNameInput)
  }

  const currentPlan = extensions?.find(
    (extension) => extension?.organizationId === selectedExtensionState?.organizationId,
  )

  if (!user) return null
  return (
    <div className={styles.accountWrapper}>
      <h3 className={styles.heading}>Account</h3>
      <div className={styles.userInfoWrapper}>
        {user?.displayName && (
          <div className={styles.infoItem}>
            <div className={styles.infoItemLeft}>
              <span>Display Name: </span>
            </div>
            <div className={styles.infoItemRight}>
              <span>{user?.displayName}</span>
              <MdModeEdit className={styles.editIcon} onClick={showInputHandler} />
            </div>
          </div>
        )}

        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Group: </span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{userExtension?.deviceName || ''}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Registered: </span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{user?.createdAt ? formatDate(user?.createdAt, 'MMM, DD YYYY HH:mm') : ''}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Organization Number:</span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{currentPlan?.organizationProviderNumber || 'No Organization Number'}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Organization Extension:</span>
          </div>
          <div className={styles.infoItemRight}>
            <span>
              {currentPlan?.organizationExtensionNumber || 'No Organization Extension Number'}
            </span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>{user.number ? 'Number:' : 'Email:'} </span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{user?.number || user.email}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Extension Number:</span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{currentPlan?.extensionNumber || 'No Extension Number'}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Current plan: </span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{currentPlan?.activePackage || 'No Plan'}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>Airtouch Number: </span>
          </div>
          <div className={styles.infoItemRight}>
            <span>{user?.twilioNumber || 'No Airtouch Number'}</span>
          </div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItemLeft}>
            <span>View plans on: </span>
          </div>
          <div className={styles.infoItemRight}>
            <a className={styles.link} href={marketingSiteUrl} target='_blank' rel='noreferrer'>
              Airtouch
            </a>
          </div>
        </div>
      </div>
      <div>
        <h3 className={styles.heading}>Groups</h3>

        <div className={styles.groupWraper}>
          {!error && !isLoading && extensions?.length ? (
            extensions?.map((extension) => {
              return (
                <div key={extension._id} className={styles.groups}>
                  <RadioBtn
                    checked={extension.organizationId === selectedExtensionState?.organizationId}
                    onChange={() => handleChangeSelectionGroup(extension)}
                  />
                  <div>
                    <div>
                      <span>Group name: </span>
                      <span>{extension?.deviceName}</span>
                    </div>
                    <div>
                      <span>Group number: </span>
                      <span>Test</span>
                    </div>
                    <div>
                      <span>Group Plan: </span>
                      <span>{extension?.activePackage}</span>
                    </div>
                  </div>
                </div>
              )
            })
          ) : error ? (
            <div className={styles.warning}>Not able to fetch groups</div>
          ) : (
            <div>There is no groups</div>
          )}
        </div>
      </div>
      <div>
        <h3>Set status </h3>
        <div className={styles.presenceBtnWrapper}>
          <RadioBtn
            checked={presence === CUSTOM_PRESENCE_TYP_MSGS.online.value}
            value={CUSTOM_PRESENCE_TYP_MSGS.online.value}
            onChange={handleChangePresence}
            label={CUSTOM_PRESENCE_TYP_MSGS.online.name}
          />
          <RadioBtn
            checked={presence === CUSTOM_PRESENCE_TYP_MSGS.offline.value}
            value={CUSTOM_PRESENCE_TYP_MSGS.offline.value}
            label={CUSTOM_PRESENCE_TYP_MSGS.offline.name}
            onChange={handleChangePresence}
          />
          <RadioBtn
            checked={presence === CUSTOM_PRESENCE_TYP_MSGS.busy.value}
            value={CUSTOM_PRESENCE_TYP_MSGS.busy.value}
            label={CUSTOM_PRESENCE_TYP_MSGS.busy.name}
            onChange={handleChangePresence}
          />
          <RadioBtn
            checked={presence === CUSTOM_PRESENCE_TYP_MSGS.dnd.value}
            value={CUSTOM_PRESENCE_TYP_MSGS.dnd.value}
            label={CUSTOM_PRESENCE_TYP_MSGS.dnd.name}
            onChange={handleChangePresence}
          />
        </div>
      </div>
      {showDisplayNameInput && (
        <div>
          <div>
            <Input
              placeholder='Enter Display Name'
              className={styles.displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
          <div className={styles.displayNameBtn}>
            <Button onClick={displayNameHandler} label='Update Display Name' />
          </div>
        </div>
      )}
    </div>
  )
}

export default Account

Account.propTypes = {
  user: PropTypes.object.isRequired,
}
