import * as TwilioClient from 'twilio-client'
import { localStorageService } from '../services/localStorage'
import toastService from '../services/toastService'
import { CUSTOM_EVENT_TYPES } from '../constants/matrixConst'
import { store } from '../redux/store'
import { toggleTriggered } from '../redux/reducers/pushSlice'
export const CALL_STATUS_TYPE = {
  end_and_accept: 'end_and_accept',
  no_call: '',
  hold_and_accept: 'hold_and_accept',
}

import { api } from '../../src/api'
import { dispatchCustomEvent } from '../utils/fireEvents'
import { resetCall, setCallSlot, setCurrentCall, setIsInCall } from '../redux/reducers/callSlice'
import { closeModal } from '../redux/reducers/modalSlice'

class InitTwilioVoiceSingleton {
  constructor() {
    if (InitTwilioVoiceSingleton.instance) {
      return InitTwilioVoiceSingleton.instance
    }
    this.device = null
    this.connection = null
    this.newCall = null
    this.updateToken = this.updateToken.bind(this)
    this.setConnection = this.setConnection.bind(this)
    this.acceptCall = this.acceptCall.bind(this)
    this.handleReject = this.handleReject.bind(this)
    this.connectDevice = this.connectDevice.bind(this)
    this.init = this.init.bind(this)
    this.setNewCall = this.setNewCall.bind(this)
    this.handleUnregisteredDevice = this.handleUnregisteredDevice.bind(this)
    this.objId = Date.now()

    InitTwilioVoiceSingleton.instance = this
  }

  /**
   * initliazed a TwilioClient Device and attach events
   */
  async init() {
    try {
      const { token } = await this.getToken()
      this.device = new TwilioClient.Device(token)
      this.device?.on('incoming', this.setConnection)
      this.device?.on('cancel', this.notifyForCancel)
      this.device?.on('unregistered', this.handleUnregistered)
      this.device?.on('offline', this.handleOffline)
      this.device?.on('tokenWillExpire', this.handleTokenWillExpire)
      this.device?.on(TwilioClient.Device.EventName.Disconnect, this.handleTokenWillExpire)
    } catch (error) {
      toastService.show('error', 'Error initiate Twilio', error)
    }
  }

  /**
   * handle offline and set new token
   */
  handleOffline = async () => {
    const { token } = await this.getToken()
    this.device.setup(token)
  }

  handleUnregistered = async () => {
    const { token } = await this.getToken()
    this.device.setup(token)
  }

  handleTokenWillExpire = async () => {
    const { token } = await this.getToken()
    this.device.updateToken(token)
  }

  handleUnregisteredDevice = async () => {
    try {
      window.location.reload()
      // const { token } = await this.getToken()
      // this.device.updateToken(token)
    } catch (err) {
      toastService.show('error', 'Error register device')
    }
  }

  /**
   * mute a call
   */
  muteCall = () => {
    const connection = this.device?.activeConnection()
    if (connection) {
      connection?.mute(true)
      // notify that call is muted
      dispatchCustomEvent(CUSTOM_EVENT_TYPES.muteCall)
    }
  }

  unmuteCall = () => {
    const connection = this.device?.activeConnection()
    if (connection) {
      connection?.mute(false)
      // notify that call is unmuted
      dispatchCustomEvent(CUSTOM_EVENT_TYPES.unmuteCall)
    }
  }

  /**
   * notifyForCancel dispatch a custom event for cancel call
   */
  notifyForCancel = () => {
    // notify that cancel call has occurred
    dispatchCustomEvent(CUSTOM_EVENT_TYPES.cancelCall)
  }

  async getToken() {
    try {
      const userId = localStorageService.get('sci')
      const response = await store.dispatch(
        api.endpoints.getCallToken.initiate(userId, { forceRefetch: true }),
      )
      if (response.data?.token) {
        return response.data
      } else {
        toastService.show('error', 'Error get twilio token')
      }
    } catch (err) {
      toastService.show('error', 'Error get twilio token')
      console.log('Error get token', err)
    }
  }

  updateToken(token) {
    this.device?.updateToken(token)
  }

  setConnection(connection) {
    const isInCall = store?.getState()?.calls?.isInCall
    const isInVideoCall = store?.getState()?.calls?.isInVideoCall
    if (this.connection?.status() === 'pending' || isInVideoCall) {
      connection?.reject()
      return
    }
    this.connection = connection
    const isVoice = connection?.customParameters?.get('isVoice')

    if (isVoice || !isInCall) {
      // notify that we have a new call
      dispatchCustomEvent(CUSTOM_EVENT_TYPES.incommingCall)
      return
    } else {
      this.connection.accept()
      return
    }
  }

  acceptCall(callData) {
    this.connection?.accept()
    store.dispatch(toggleTriggered())
    store.dispatch(setIsInCall(true))
    store.dispatch(setCurrentCall(callData))
    store.dispatch(setCallSlot({ ...callData, participants: [], isOnHold: false }))
  }

  async connectDevice(params) {
    store.dispatch(
      setCurrentCall({
        caller: params?.caller,
        callerId: params?.callerId,
        organizationName: params?.organizationName,
      }),
    )

    if (!this.device) {
      await this.init()
      this.connection = this.device.connect(params)
      store.dispatch(
        setCallSlot({
          caller: params?.caller,
          callerId: params?.callerId,
          organizationName: params?.organizationName,
          participants: [],
          isOnHold: false,
        }),
      )
    } else {
      if (!this.connection) {
        this.connection = this.device.connect(params)
        store.dispatch(
          setCallSlot({
            caller: params?.caller,
            callerId: params?.callerId,
            organizationName: params?.organizationName,
            participants: [],
            isOnHold: false,
          }),
        )
      }
    }
  }

  /**
   * reject current Twilio connection
   */
  handleReject() {
    if (this.connection?._status === 'pending') {
      this.connection.reject()
    } else if (this.connection?._status === 'open') {
      this.connection.disconnect()
    }
    this.connection?.disconnect()
    this.connection = null
    store.dispatch(resetCall())
    store.dispatch(closeModal())
  }

  setNewCall(call) {
    this.newCall = call
  }

  logOut() {
    this.device?.off('incoming', this.setConnection)
    this.device?.off('cancel', this.notifyForCancel)
    this.device?.off('unregistered', this.handleUnregistered)
    this.device?.off('offline', this.handleOffline)
    this.device?.off('tokenWillExpire', this.handleTokenWillExpire)
    this.device?.destroy()
  }
}

const initTwilioVoice = new InitTwilioVoiceSingleton()

export default initTwilioVoice
