import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import { MdClose } from 'react-icons/md'
import { closeModal } from '../../redux/reducers/modalSlice'

import styles from './Modal.module.scss'

const ModalWrapper = ({
  show,
  content,
  size,
  closeOnOutsideClick,
  showCloseIcon,
  callbackOnClose,
  customStyle,
}) => {
  const dispatch = useDispatch()

  const closeModalHandler = () => {
    if (callbackOnClose && typeof callbackOnClose === 'function') {
      callbackOnClose()
    }
    dispatch(closeModal())
  }

  return (
    <div className='modal-c'>
      <Modal
        isOpen={show}
        onRequestClose={closeModalHandler}
        contentLabel='Custom modal'
        className={`${customStyle ? styles[customStyle] : styles.customModal} ${styles[size]} `}
        overlayClassName={styles.customOverlay}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={closeOnOutsideClick}
      >
        <div className={styles.modalBody}>
          <div className={styles.scrollWrapper}>
            {showCloseIcon && (
              <span
                className={styles.close}
                style={customStyle ? { color: 'white' } : {}}
                onClick={closeModalHandler}
              >
                <MdClose />
              </span>
            )}
            {content}
          </div>
        </div>
      </Modal>
    </div>
  )
}

ModalWrapper.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.object,
  size: PropTypes.string,
  closeOnOutsideClick: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  callbackOnClose: PropTypes.func,
  customStyle: PropTypes.string,
}

export default ModalWrapper
