import { api } from './index'
import { initalizeFavorites, setPhoneContacts } from '../redux/reducers/contactsSlice'
import { filterOrganizationContacts } from '../utils/contacts'

const contactsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountContacts: builder.query({
      query: (id) => {
        return {
          url: `/account/${id}/contacts`,
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(initalizeFavorites(data))
        } catch (err) {
          console.log(err)
        }
      },
    }),
    getContacts: builder.query({
      query: (id) => {
        return {
          url: `/account/${id}/organization/accounts `,
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const filteredOrganizationContacts = filterOrganizationContacts(
            data.accountContacts,
            data?.organizationContacts,
          )
          dispatch(
            initalizeFavorites([...filteredOrganizationContacts, ...(data.accountContacts || [])]),
          )
          dispatch(setPhoneContacts(data.accountContacts) || [])
        } catch (err) {
          console.log(err)
        }
      },
    }),
  }),
})
export const { useGetAccountContactsQuery, useGetContactsQuery } = contactsApiSlice
