import { useState } from 'react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { localStorageService } from '../../services/localStorage'
import CustomIcon from '../../components/CustomIcon/CustomIcon'
// import audioIncoming from '../../assets/images/audioIncoming.png'
import { ReactComponent as AudioIncoming } from '../../assets/images/audioIncoming.svg'
// import audioMade from '../../assets/images/audioMade.svg'
import { ReactComponent as AudioMade } from '../../assets/images/audioMade.svg'
// import audioMissed from '../../assets/images/missed.svg'
import { ReactComponent as AudioMissed } from '../../assets/images/missed.svg'
// import videoIncoming from '../../assets/images/videoIncoming.svg'
import { ReactComponent as VideoIncoming } from '../../assets/images/videoIncoming.svg'
// import videoMade from '../../assets/images/videoMade.svg'
import { ReactComponent as VideoMade } from '../../assets/images/videoMade.svg'
// import videoMissed from '../../assets/images/videoMissed.svg'
import { ReactComponent as VideoMissed } from '../../assets/images/videoMissed.svg'
import { ReactComponent as MergedCall } from '../../assets/images/mergeOn.svg'
import { MdKeyboardArrowRight, MdKeyboardArrowDown, MdOutlineFileDownload } from 'react-icons/md'

import { formatDate, formatSeconds } from '../../utils/dateParsing'
import { truncateString } from '../../utils/string'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContactProfile from '../../components/ContactProfile/ContactProfile'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import {
  selectAirtouchContactsMap,
  selectPhoneContactsMap,
} from '../../redux/reducers/contactsSlice'
import { openModal } from '../../redux/reducers/modalSlice'

import { url } from '../../environment'

import styles from './Call.module.scss'
import toastService from '../../services/toastService'

const callDirectionIcons = {
  // inbound: <img className={styles.callsIcon} src={audioIncoming} alt='' />,
  inbound: <CustomIcon icon={<AudioIncoming className={styles.callsIcon} />} />,
  // 'inbound-no-answer': <img className={styles.callsIcon} src={audioMissed} alt='' />,
  'inbound-no-answer': <CustomIcon icon={<AudioMissed className={styles.callsIcon} />} />,
  // outbound: <img className={styles.callsIcon} src={audioMade} alt='' />,
  outbound: <CustomIcon icon={<AudioMade className={styles.callsIcon} />} />,
}

const videoCallDirectionIcons = {
  // inbound: <img className={styles.callsIcon} src={videoIncoming} alt='' />,
  inbound: <CustomIcon icon={<VideoIncoming className={styles.callsIcon} />} />,
  // 'inbound-no-answer': <img className={styles.callsIcon} src={videoMissed} alt='' />,
  'inbound-no-answer': <CustomIcon icon={<VideoMissed className={styles.callsIcon} />} />,
  // outbound: <img className={styles.callsIcon} src={videoMade} alt='' />,
  outbound: <CustomIcon icon={<VideoMade className={styles.callsIcon} />} />,
}

const getContactName = (contact, contactsMap, phoneContactMap) => {
  let contactName = contact || ''
  if (!contactName) return contactName
  if (phoneContactMap?.[contactName]?.name) return phoneContactMap[contactName].name
  const cotactWithoutPrefix = contactName.split('acc_')?.[1]

  if (phoneContactMap?.[`+${cotactWithoutPrefix}`]?.name) {
    contactName = phoneContactMap[`+${cotactWithoutPrefix}`].name
  } else {
    if (contactsMap?.[contactName]?.name) {
      contactName =
        contactsMap[contactName]?.name &&
        contactsMap[contactName]?.name.startsWith('acc_') &&
        contactsMap[contactName]?.displayName
          ? contactsMap[contactName]?.displayName
          : contactsMap[contactName]?.name &&
            contactsMap[contactName]?.email &&
            contactsMap[contactName]?.displayName
          ? contactsMap[contactName]?.displayName
          : contactsMap[contactName]?.name &&
            contactsMap[contactName]?.email &&
            !contactsMap[contactName]?.displayName
          ? contactsMap[contactName]?.email
          : contactsMap[contactName]?.name
    }
  }
  return contactName
}

const downloadFile = (recordingURL, accountId, name) => {
  if (!recordingURL || !accountId) return
  const bodyData = JSON.stringify({ recordingURL })
  const accessToken = localStorageService.get('sct')
  fetch(url + `twilio/${accountId}/recording/download`, {
    headers: { 'x-access-token': accessToken, 'Content-type': 'application/json' },
    method: 'POST',
    body: bodyData,
  })
    .then((response) => {
      const contentDisposition = response.headers.get('Content-Disposition')
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/)

      let filename = name

      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1]
      }

      return response
        .blob()
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: 'audio/wav; codecs=0' }))
          const a = document.createElement('a')
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch(() => {
          toastService.show('error', `Error downloading recording: ${name}`)
        })
    })
    .catch(() => {
      toastService.show('error', `Error downloading recording: ${name}`)
    })
}

const Call = forwardRef(({ call }, ref) => {
  const [isOpenRecording, setIsOpenRecording] = useState(false)
  const user = useSelector(selectCurrentUser)
  const airtouchContactsMap = useSelector(selectAirtouchContactsMap)
  const phoneContactsMap = useSelector(selectPhoneContactsMap)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  if (!call) return null

  const username = user?.endpoint?.username

  const contact = call.to === username ? call.from : call.to
  const callDirection = call.to === username ? 'inbound' : 'outbound'
  const contactName = getContactName(contact, airtouchContactsMap, phoneContactsMap)

  const callStatus = call?.callStatus === 'no-answer' && callDirection === 'inbound'
  const { isMergedCall } = call

  const { recordings = [] } = call
  const filteredRecodings = recordings?.filter(
    (recording) => recording?.from === user?.endpoint?.username,
  )

  const { participants = [] } = call
  const filteredParticipants = participants?.filter((participant) => participant !== username)

  const openModalHandler = (call) => {
    dispatch(
      openModal({
        show: true,
        content: (
          <ContactProfile
            roomId={contact}
            extensionId={call?.extensionId}
            organizationId={call?.organizationId}
            callHistoryAudio={true}
            contactName={contactName}
          />
        ),
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const handleVideoCall = async (roomName) => {
    navigate('/video', { state: { roomId: roomName, chatCalling: true } })
  }

  if (isMergedCall) {
    return (
      <div ref={ref} className={styles.callWrapper}>
        <div className={styles.callStart}>
          <span>{formatDate(call.callStart, 'HH:mm')}</span>
        </div>
        <div className={styles.call}>
          <div className={styles.callMade}>
            <CustomIcon icon={<MergedCall className={styles.callsIcon} />} />
          </div>
          <span className={styles.contact}>Merged call</span>
          {participants?.length ? (
            <span
              className={styles.callRecordingIcon}
              onClick={() => setIsOpenRecording((prev) => !prev)}
            >
              {isOpenRecording ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
            </span>
          ) : null}
        </div>
        <div className={styles.callDuration}>
          {callStatus ? (
            <span>Missed call</span>
          ) : (
            <span>Duration {formatSeconds(call?.duration)}</span>
          )}
        </div>
        {isOpenRecording ? (
          <div className={styles.callRecordings}>
            <span>Participants: </span>
            {filteredParticipants?.map((participant) => {
              const userName = getContactName(participant, airtouchContactsMap, phoneContactsMap)
              return (
                <div className={styles.record} key={userName}>
                  <span>{userName} </span>
                </div>
              )
            })}
          </div>
        ) : null}
        {isOpenRecording ? (
          <div className={styles.callRecordings}>
            {filteredRecodings?.length ? <span>Recordings: </span> : null}
            {filteredRecodings?.map((recording, index) => {
              const recordingName = `Recording ${index + 1} ${formatDate(
                call.callStart,
                'YYYY-MM-DD-HH:mm',
              )}`
              return (
                <div className={styles.record} key={recording.recordingURL}>
                  <span>{`Download ${recordingName}`}</span>
                  <span
                    className={styles.recordIcon}
                    onClick={() => downloadFile(recording.recordingURL, user?._id, recordingName)}
                  >
                    <MdOutlineFileDownload />
                  </span>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div ref={ref} className={styles.callWrapper}>
      <div className={styles.callStart}>
        <span>{formatDate(call.callStart, 'HH:mm')}</span>
      </div>
      <div className={styles.call}>
        <div
          onClick={() => {
            if (call?.type === 'video') {
              handleVideoCall(call?.roomName)
            } else if (call?.type === 'voice') {
              openModalHandler()
            }
          }}
          className={callStatus ? styles.missedCall : styles.callMade}
        >
          {call.type === 'voice' ? (
            <span>
              {callStatus
                ? callDirectionIcons[callDirection + '-' + call?.callStatus]
                : callDirectionIcons[callDirection]}
            </span>
          ) : (
            <span>
              {callStatus
                ? videoCallDirectionIcons[callDirection + '-' + call?.callStatus]
                : videoCallDirectionIcons[callDirection]}
            </span>
          )}
        </div>
        <span className={styles.contact}>{truncateString(contactName)}</span>
        {filteredRecodings?.length ? (
          <span
            className={styles.callRecordingIcon}
            onClick={() => setIsOpenRecording((prev) => !prev)}
          >
            {isOpenRecording ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
          </span>
        ) : null}
      </div>
      <div className={styles.callDuration}>
        {callStatus ? (
          <span>Missed call</span>
        ) : (
          <span>Duration {formatSeconds(call?.duration)}</span>
        )}
      </div>
      {isOpenRecording ? (
        <div className={styles.callRecordings}>
          {filteredRecodings?.length ? <span>Recordings: </span> : null}
          {filteredRecodings?.map((recording, index) => {
            const recordingName = `Recording ${index + 1} ${formatDate(
              call.callStart,
              'YYYY-MM-DD-HH:mm',
            )}`
            return (
              <div className={styles.record} key={recording.recordingURL}>
                <span>{`Download ${recordingName}`} </span>
                <span
                  className={styles.recordIcon}
                  onClick={() => downloadFile(recording.recordingURL, user?._id, recordingName)}
                >
                  <MdOutlineFileDownload />
                </span>
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
})

Call.displayName = 'Call'

Call.propTypes = {
  call: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    callStart: PropTypes.string.isRequired,
    callStatus: PropTypes.string,
    direction: PropTypes.string,
    duration: PropTypes.number,
    from: PropTypes.string,
    to: PropTypes.string,
    roomName: PropTypes.string,
    type: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    extensionId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    recordings: PropTypes.array,
    isMergedCall: PropTypes.bool,
    participants: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default Call
