import { CUSTOM_PRESENCE_TYP_MSGS } from '../constants/matrixConst'
import { localStorageService } from '../services/localStorage'

export const sortContacts = (contacts, searchText) => {
  if (!contacts || !Array.isArray(contacts) || !contacts.length) return []
  let sorted = [...contacts].sort((a, b) => {
    if (a.osc && !b.osc) return -1
    if (!a.osc && b.osc) return 1
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  if (!searchText) return sorted
  sorted = sorted.filter((contact) => {
    return (
      contact?.name?.toLowerCase()?.includes(searchText) ||
      contact?.email?.toLowerCase()?.includes(searchText) ||
      contact?.number?.toLowerCase()?.includes(searchText) ||
      contact?.displayName?.toLowerCase()?.includes(searchText)
    )
  })
  return sorted
}

export const addToFavorites = (contact, setState) => {
  let favorites = localStorageService.get('f')
  if (!favorites) favorites = {}
  favorites[contact._id] ? delete favorites[contact._id] : (favorites[contact._id] = contact)
  localStorageService.set('f', favorites)
  setState(favorites)
}

export const removePrefixFromString = (str) => {
  if (!str) return ''
  return str.replace(/\+/g, '')
}

export const getPresenceFromContact = (allMembers, contact) => {
  let presence = CUSTOM_PRESENCE_TYP_MSGS.offline.value
  if (!contact?.matrix?.matrixId) return presence
  const airtouchContact = contact.matrix.matrixId
  if (!allMembers[airtouchContact]?.presence) return presence
  presence = allMembers[airtouchContact]?.presence
  return presence
}

export const filterOrganizationContacts = (accountContacts, organizationContacts) => {
  if (!Array.isArray(organizationContacts)) return []
  if (!Array.isArray(accountContacts)) return organizationContacts

  return organizationContacts.filter((contact) => {
    return !accountContacts.some((c) => c?.number === contact?.number)
  })
}
