import { useState } from 'react'
import { MdChat, MdContacts, MdLocalPhone, MdOutlineDialpad, MdStar } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Contacts from '../../pages/contacts/Contacts'
import Calls from '../../pages/calls/Calls'
import Dialer from '../../pages/dialer/Dialer'
import Favorites from '../../pages/favorites/Favorites'
import RoomList from '../RoomList/RoomList'
import { localStorageService } from '../../services/localStorage'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { openModal } from '../../redux/reducers/modalSlice'
import ProfileSidebar from '../ProfileSidebar/ProfileSidebar'

import styles from './SidebarOrganization.module.scss'

const renderRoomList = (isMobile, activeTab, location) => {
  if (activeTab !== 5) return null
  if (isMobile) {
    if (location.pathname === '/') {
      return <RoomList />
    }
  } else {
    return <RoomList />
  }
}

const SidebarOrganization = () => {
  const [active, setActive] = useState(() => {
    const activeTab = localStorageService.get('activeTab')
    return activeTab || 3
  })
  const { isMobile } = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleClick = (tab) => {
    if (isMobile) navigate('/')
    setActive(tab)
    localStorageService.set('activeTab', tab)
  }

  const handleDialer = () => {
    dispatch(
      openModal({
        show: true,
        content: <Dialer />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  return (
    <div className={styles.globalWrapper}>
      <ProfileSidebar />
      <div className={styles.sideBarWrapper}>
        <div onClick={() => handleClick(1)} className={active === 1 ? styles.active : styles.link}>
          <MdStar />
          <span>Favorites</span>
        </div>
        <div onClick={() => handleClick(2)} className={active === 2 ? styles.active : styles.link}>
          <MdLocalPhone />
          <span>Calls</span>
        </div>
        <div onClick={() => handleClick(3)} className={active === 3 ? styles.active : styles.link}>
          <MdContacts />
          <span>Contacts</span>
        </div>
        <div onClick={handleDialer} className={active === 4 ? styles.active : styles.link}>
          <MdOutlineDialpad />
          <span>Dialer</span>
        </div>
        <div onClick={() => handleClick(5)} className={active === 5 ? styles.active : styles.link}>
          <MdChat />
          <span>Chat</span>
        </div>
      </div>
      {active === 1 && <Favorites />}
      {active === 2 && <Calls />}
      {active === 3 && <Contacts />}

      {renderRoomList(isMobile, active, location)}
    </div>
  )
}

export default SidebarOrganization
