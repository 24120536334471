import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const isSameDay = (prev, curr) => {
  return dayjs(curr).isSame(dayjs(prev), 'day')
}

export const formatDate = (date, format = 'YYYY:MM:DD') => {
  return dayjs(date).format(format)
}

export const formatSeconds = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  // Add leading zeros if necessary
  const formattedMinutes = minutes + 'm '
  const formattedSeconds = remainingSeconds + 's'

  let formattedTime = formattedSeconds
  if (formattedMinutes !== '0m ') {
    formattedTime = formattedMinutes + formattedSeconds
  } else {
    if (hours) formattedTime = formattedMinutes + formattedSeconds
  }
  if (hours) {
    const formattedHours = hours + 'h '
    formattedTime = formattedHours + formattedTime
  }

  return formattedTime
}
