import React from 'react'
import PropTypes from 'prop-types'

import atouch from '../../assets/images/atouch.svg'

import styles from './CallContact.module.scss'

const CallContact = ({ contact, handleTransferCall, searchText }) => {
  if (!contact && !searchText) return null

  const contactName =
    contact?.name && contact?.name.startsWith('acc_') && contact?.displayName
      ? contact?.displayName
      : contact?.name && contact?.email && contact?.displayName
      ? contact?.displayName
      : contact?.name && contact?.email && !contact?.displayName
      ? contact?.email
      : contact?.name || searchText

  return (
    <div>
      <div
        className={styles.contact}
        onClick={() => handleTransferCall(contact || { number: searchText })}
      >
        {/* <div className={styles.avatarWrapper}>
          <img className={styles.avatarImg} src={avatar} alt='' />
          {contact?.osc ? <span className={styles[presence]}></span> : null}
        </div> */}
        <div className={styles.contactInfo}>
          <div>
            <div className={styles.airtouchContacts}>
              <span className={styles.name}> {contactName} </span>
              {contact?.osc ? (
                <div className={styles.logoDiv}>
                  <img className={styles.googleImg} src={atouch} alt='' />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.organizationNameDiv}>
            <span className={styles.organizationName}>{contact.organizationName}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

CallContact.propTypes = {
  contact: PropTypes.object.isRequired,
  handleTransferCall: PropTypes.func.isRequired,
  searchText: PropTypes.string,
}

export default CallContact
