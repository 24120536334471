import PropTypes from 'prop-types'
import { MdOutlineFileDownload } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'
import ReactPlayer from 'react-player'

import styles from './VideoBox.module.scss'

const VideoBox = ({ sender, url, position, date, onDownload }) => {
  return (
    <div
      style={
        position === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }
      }
      className={styles.videoBoxWrapper}
    >
      <div className={styles[position]}>
        <div className={styles.sender}>
          <span>{sender}</span>
          <span>
            <a data-tooltip-id={`download-video-${url}`}>
              <Tooltip
                id={`download-video-${url}`}
                className={styles.videTooltip}
                content='Download video'
                place='top'
              />

              {onDownload && typeof onDownload === 'function' && (
                <MdOutlineFileDownload className={styles.downloadIcon} onClick={onDownload} />
              )}
            </a>
          </span>
        </div>
        {url && (
          <div className={styles.playerWrapper}>
            <ReactPlayer
              className={styles.video}
              url={url}
              playing={false}
              controls={true}
              width='300px'
              height='100%'
            />
          </div>
        )}
        <div className={styles.date}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  )
}

VideoBox.propTypes = {
  sender: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
}

export default VideoBox
