import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Dialer from '../../pages/dialer/Dialer'

import { FaSms } from 'react-icons/fa'
import { MdChat, MdLocalPhone, MdVideocam } from 'react-icons/md'
import { useCreateRoomMutation } from '../../api/messageApiSlice'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import { closeModal, openModal } from '../../redux/reducers/modalSlice'
import toastService from '../../services/toastService'
import { getRoomName } from '../../utils/matrix'

import avatar from '../../assets/images/defaultAvatar.svg'

import { selectContactsMap } from '../../redux/reducers/contactsSlice'
import styles from './ContactProfile.module.scss'
import { segmentAnalytics } from '../../App'
import { isProduction } from '../../environment'
import initTwilioVoice from '../../lib/initTwilioVoice'

const ContactProfile = ({ contact, roomId, chatRoomName, contactName, callHistoryAudio }) => {
  const contactsMap = useSelector(selectContactsMap)
  const [createRoom] = useCreateRoomMutation()

  const dispatch = useDispatch()
  const store = useSelector(selectCurrentUser)
  const navigate = useNavigate()

  const handleMakeCall = useCallback(async () => {
    if (!contact?.osc && !roomId && !callHistoryAudio) {
      dispatch(
        openModal({
          show: true,
          content: (
            <Dialer number={contact?.number} name={chatRoomName ? chatRoomName : contactName} />
          ),
          size: 'md',
          closeOnOutsideClick: false,
        }),
      )
    } else {
      const organizationName = contact?.organizationName || ''
      const params = {
        To: roomId ? roomId : contact?.endpoint?.username,
        organizationId: store?.organizationId,
        extensionId: store?.extensionId,
        caller: contactName,
        callerId: contact?.endpoint?.username,
        organizationName,
      }

      const { token } = await initTwilioVoice.getToken()

      if (token) initTwilioVoice.updateToken(token)
      initTwilioVoice.connectDevice(params)
      navigate('/call', {
        state: { params, name: chatRoomName ? chatRoomName : contactName, organizationName },
      })
      dispatch(closeModal())
      if (isProduction) {
        segmentAnalytics.track('Start audio call', { username: store?.endpoint?.username })
      }
    }
  }, [
    callHistoryAudio,
    chatRoomName,
    contact?.endpoint?.username,
    contact?.number,
    contact?.organizationName,
    contact?.osc,
    contactName,
    dispatch,
    navigate,
    roomId,
    store?.endpoint?.username,
    store?.extensionId,
    store?.organizationId,
  ])

  const onCloseHandler = () => {
    dispatch(closeModal())
  }

  const handleVideo = async () => {
    navigate('/video', { state: { contact, localCalling: true } })
    dispatch(closeModal())
  }

  const handleMessageStart = async (isSms) => {
    try {
      const createRoomData = {
        id: store._id,
        roomName: undefined,
      }
      if (contact?.osc && contact?.matrix?.matrixId) {
        createRoomData.accountContactNumbers = [contact.matrix.matrixId]
      } else {
        createRoomData.accountContactNumbers = [contact.number]
      }

      const data = await createRoom(createRoomData)
      if (data.error) throw Error(data?.error?.data?.error?.message)
      const roomName = await getRoomName(store?.matrix?.username, data.data.room_id, contactsMap)
      navigate(`chat/${data.data.room_id}`, {
        state: {
          isSms,
          roomName,
          contactName: contact?.name,
          displayName: contact?.displayName,
          isEmailContact: !!contact.email,
        },
      })
    } catch (err) {
      toastService.show('error', 'Error start chat ' + err, { autoClose: false })
    } finally {
      dispatch(closeModal())
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <img src={avatar} alt='' />
        <span>{chatRoomName ? chatRoomName : contactName}</span>
      </div>
      <div className={styles.callInfo}>
        <div className={styles.number}>
          {contact?.number || contact?.email || '+' + roomId?.split('acc_')?.[1]}
        </div>
        <div className={styles.iconBox}>
          <MdLocalPhone onClick={handleMakeCall} className={styles.call} />
          {contact?.osc && <MdVideocam onClick={handleVideo} className={styles.video} />}
          {contact?.osc && (
            <MdChat onClick={() => handleMessageStart(false)} className={styles.message} />
          )}
          {!roomId && <FaSms onClick={() => handleMessageStart(true)} className={styles.message} />}
        </div>
      </div>
      <hr />
      <div onClick={onCloseHandler} className={styles.footer}>
        <span>Cancel</span>
      </div>
    </div>
  )
}

export default ContactProfile

ContactProfile.propTypes = {
  contact: PropTypes.object,
  roomId: PropTypes.string,
  chatRoomName: PropTypes.string,
  contactName: PropTypes.any,
  extensionId: PropTypes.string,
  organizationId: PropTypes.string,
  callHistoryAudio: PropTypes.bool,
}
