import { api } from './index'

const callsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query({
      query: (data) => {
        return {
          url: `/account/${data.accountId}/calls/${data.limit}/${data.page}`,
        }
      },
    }),
    declineNewCall: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/decline`,
          method: 'POST',
          body: { incomingCallSid: data.incomingCallSid },
        }
      },
    }),
    transferNewCall: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/transfer`,
          method: 'POST',
          body: data?.incomingCallSid
            ? { incomingCallSid: data.incomingCallSid, callerId: data.callerId, to: data.to }
            : { callerId: data.callerId, to: data.to },
        }
      },
    }),
    holdAndAccept: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/holdAndAccept`,
          method: 'POST',
          body: {
            incomingCallSid: data.incomingCallSid,
            inProgressCallSid: data.inProgressCallSid,
            callerId: data.callerId,
          },
        }
      },
    }),
    endAndAccept: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/endAndAccept`,
          method: 'POST',
          body: {
            incomingCallSid: data.incomingCallSid,
            inProgressCallSid: data.inProgressCallSid,
            callerId: data.callerId,
          },
        }
      },
    }),
    mergeCall: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/merge`,
          method: 'POST',
          body: {
            incomingCallSid: data.incomingCallSid,
            inProgressCallSid: data.inProgressCallSid,
            callerId: data.callerId,
          },
        }
      },
    }),
    mergeConferenceCallOnHold: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/mergeConferenceCallOnHold`,
          method: 'POST',
          body: {
            callerId: data.callerId,
          },
        }
      },
    }),
    answerConferenceCallOnHold: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/answerConferenceCallOnHold`,
          method: 'POST',
          body: {
            callerId: data.callerId,
          },
        }
      },
    }),
    endConferenceCallLine: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/endConferenceCallLine`,
          method: 'POST',
          body: {
            callerId: data.callerId,
          },
        }
      },
    }),
    transferConferenceCallLine: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/newCAll/transferConferenceCallLine`,
          method: 'POST',
          body: {
            callerId: data.callerId,
            to: data.to,
          },
        }
      },
    }),
    recordCall: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/recordCall`,
          method: 'POST',
          body: {},
        }
      },
    }),
    stopRecordingCall: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/stopRecordingCall`,
          method: 'POST',
          body: {
            recordingSid: data.recordingSid,
            callSid: data.callSid,
          },
        }
      },
    }),
    voiceCallRecordings: builder.query({
      query: (accountId) => {
        return {
          url: `account/${accountId}/voiceCallRecordings`,
        }
      },
    }),
  }),
})
export const {
  useGetCallsQuery,
  useDeclineNewCallMutation,
  useTransferNewCallMutation,
  useHoldAndAcceptMutation,
  useEndAndAcceptMutation,
  useMergeCallMutation,
  useMergeConferenceCallOnHoldMutation,
  useAnswerConferenceCallOnHoldMutation,
  useEndConferenceCallLineMutation,
  useTransferConferenceCallLineMutation,
  useRecordCallMutation,
  useStopRecordingCallMutation,
  useVoiceCallRecordingsQuery,
} = callsApiSlice
