import * as msal from '@azure/msal-browser'
import { useGoogleLogin } from '@react-oauth/google'
import { useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom'
import {
  useRegisterMutation,
  useRegisterWithGoogleMutation,
  useRegisterWithMicrosoftMutation,
} from '../../api/authApiSlice'
import googleSvg from '../../assets/images/google.svg'
import logo from '../../assets/images/logo.png'
import microsoftSvg from '../../assets/images/microsoft.svg'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Button from '../../components/Button/Button'
import { clientUrl } from '../../environment'
import { setCredentials } from '../../redux/reducers/authSlice'

import styles from './Register.module.scss'
import toastService from '../../services/toastService'

const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID
const MICROSOFT_SECRET_CLIENT = process.env.REACT_APP_MICROSOFT_CLIENT_SECRET

const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    client_secret: MICROSOFT_SECRET_CLIENT,
    redirectUri: clientUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

const request = {
  scopes: ['User.ReadBasic.All'],
}

const Register = () => {
  const [number, setNumber] = useState('')
  const [register, { isLoading: isRegisterLoading, isError: isRegisterError }] =
    useRegisterMutation()
  const [
    registerWithGoogle,
    { isLoading: isGoogleRegisterLoading, isError: isGoogleRegisterError },
  ] = useRegisterWithGoogleMutation()
  const [
    registerWithMicrosoft,
    { isLoading: isMicrosoftRegisterLoading, isError: isMicrosoftRegisterError },
  ] = useRegisterWithMicrosoftMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const ref = useRef(null)

  useEffect(() => {
    ref.current.focus()
  }, [])

  const handleChange = (value) => {
    setNumber(value)
  }

  const msalInstance = new msal.PublicClientApplication(msalConfig)

  const onSuccessfullRegistration = (data, isRegisterLoading, isRegisterError) => {
    if (!data) {
      return
    }
    if (
      data.data?.account?._id &&
      ((!isGoogleRegisterLoading && !isGoogleRegisterError) ||
        (!isMicrosoftRegisterLoading && !isMicrosoftRegisterError))
    ) {
      if (!data?.data?.account?.displayName) {
        navigate('/update-display-name', { state: { data } })
        return
      }
      dispatch(setCredentials(data.data))
      navigate('/')
      return
    }
    if (!isRegisterLoading && !isRegisterError) {
      navigate('/login', { state: { ...data, number } })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = await register({ number })
    if (data?.error) {
      toastService.show(
        'error',
        data.error?.data?.error?.message ||
          'You are not authorized to access this URI Account is deactivated',
      )
      return
    }
    onSuccessfullRegistration(data, isRegisterLoading, isRegisterError)
  }

  const handleGoogleRegister = useGoogleLogin({
    onSuccess: async (accessToken) => {
      const data = await registerWithGoogle({
        accessToken: accessToken.access_token,
      })
      if (data?.error) {
        toastService.show('error', data?.error.data?.error?.message || 'Something went wrong')
        return
      } else {
        onSuccessfullRegistration(data, isGoogleRegisterLoading, data?.error)
      }
    },
    onError: (err) => {
      toastService.show('error', err?.data?.error?.message || 'Something went wrong')
    },
  })

  const handleMicrosoftRegister = () => {
    msalInstance
      .loginPopup(request)
      .then(async (response) => {
        const data = await registerWithMicrosoft({
          tenantId: response.uniqueId.replace(/^[0-]+|-/g, ''),
          account: response.account.username,
        })
        if (data?.error) {
          toastService.show('error', data?.error.data?.error?.message || 'Something went wrong')
          return
        } else {
          onSuccessfullRegistration(data, isMicrosoftRegisterLoading, data?.error)
        }
      })
      .catch((error) => {
        if (error?.errorCode === 'user_cancelled') {
          return
        }
        toast.error('Something went wrong', {
          hideProgressBar: true,
        })
        // Handle error
      })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.registerContainer}>
        <div className={styles.logoDiv}>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <PhoneInput
              international
              placeholder='Enter phone number'
              value={number}
              defaultCountry='RS'
              onChange={handleChange}
              className={styles.phoneInputCSS}
              ref={ref}
            />
          </div>
          <Button label={'Submit'} type={'submit'} disabled={isRegisterLoading} />
        </form>
        <div className={styles.authDiv}>
          <button
            disabled={isGoogleRegisterLoading}
            className={styles.googleBtn}
            onClick={handleGoogleRegister}
          >
            <img className={styles.googleImg} src={googleSvg} alt='' />
            <span>Continue with Google</span>
          </button>
          <button
            className={styles.googleBtn}
            id='sign-in-button'
            onClick={handleMicrosoftRegister}
          >
            <img className={styles.googleImg} src={microsoftSvg} alt='' />
            <span>Continue with Microsoft</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Register
