import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from '../../services/localStorage'
const getContactsMap = (contacts) => {
  const contactsMap = {}
  if (!contacts || !Array.isArray(contacts)) return contactsMap
  contacts.forEach((contact) => {
    if (contact?.number) contactsMap[contact.number] = contact
    if (!contact?.number && contact.email) contactsMap[contact.email] = contact
  })
  return contactsMap
}

const getAirtouchContactsMap = (contacts, contactsMap) => {
  const airtouchContactsMap = {}
  if (!Array.isArray(contacts)) return contactsMap
  contacts.forEach((contact) => {
    const existsInContacts = contactsMap[contact.number]

    if (contact?.osc) {
      existsInContacts
        ? (airtouchContactsMap[contact?.endpoint?.username] = { ...contact, ...existsInContacts })
        : (airtouchContactsMap[contact?.endpoint?.username] = contact)
    } else {
      airtouchContactsMap[contact.number] = contact
    }
  })
  return airtouchContactsMap
}

const getPhoneContactsMap = (contacts) => {
  const contactsMap = {}
  if (!Array.isArray(contacts)) return contactsMap
  contacts.forEach((contact) => {
    if (contact?.number) contactsMap[contact.number] = contact
    if (!contact?.number && contact.email) contactsMap[contact.email] = contact
  })
  return contactsMap
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
    favorites: {},
    contactsMap: {},
    airtouchContactsMap: {},
    phoneContactsMap: {},
  },
  reducers: {
    setContacts: (state, action) => {
      state.contacts = action.payload
      state.contactsMap = getContactsMap(action.payload)
      state.airtouchContactsMap = getAirtouchContactsMap(action.payload, state.contactsMap)
    },
    setFavorites: (state, action) => {
      const fav = state.favorites
      const favMap = localStorageService.get('f') || {}
      if (action.payload?.matrix?.matrixId) {
        favMap[action.payload.matrix.matrixId]
          ? delete favMap[action.payload.matrix.matrixId]
          : (favMap[action.payload.matrix.matrixId] = action.payload.matrix.matrixId)
        fav[action.payload.matrix.matrixId]
          ? delete fav[action.payload.matrix.matrixId]
          : (fav[action.payload.matrix.matrixId] = action.payload)
        state.favorites = fav
      }
      localStorageService.set('f', favMap)
    },
    initalizeFavorites: (state, action) => {
      const favMap = localStorageService.get('f') || {}
      if (!Object.keys(favMap)) {
        state.favorites = {}
      } else {
        if (action.payload && Array.isArray(action.payload)) {
          Object.keys(favMap).forEach((key) => {
            const favorite = action.payload.find((contact) => contact?.matrix?.matrixId === key)
            state.favorites[key] = favorite
          })
        }
      }
      state.contacts = action.payload || []
      state.contactsMap = getContactsMap(action.payload)
      state.airtouchContactsMap = getAirtouchContactsMap(action.payload, state.contactsMap)
    },
    setPhoneContacts: (state, action) => {
      state.phoneContactsMap = getPhoneContactsMap(action.payload)
    },
  },
})

export const { setContacts, setFavorites, initalizeFavorites, setPhoneContacts } =
  contactsSlice.actions

export default contactsSlice.reducer

export const selectContacts = (state) => state.contacts.contacts
export const selectFavorites = (state) => state.contacts.favorites
export const selectContactsMap = (state) => state.contacts.contactsMap
export const selectAirtouchContactsMap = (state) => state.contacts.airtouchContactsMap
export const selectPhoneContactsMap = (state) => state.contacts.phoneContactsMap
