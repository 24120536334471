import * as sdk from 'matrix-js-sdk'
import { store } from '../redux/store'
import { setAllMembers, setIsLoading, setPresence } from '../redux/reducers/matrixSlice'
import { localStorageService } from '../services/localStorage'
import { PRESENCE_TYPES, CUSTOM_EVENT_TYPES } from '../constants/matrixConst'

function findObjectById(set, id) {
  for (let obj of set) {
    if (obj.roomId === id) {
      return obj
    }
  }
  return null
}

function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

class MatrixSingleton {
  constructor() {
    if (MatrixSingleton.instance) {
      return MatrixSingleton.instance
    }
    MatrixSingleton.instance = this
    this.rooms = new Set()
    this.syncRooms = this.syncRooms.bind(this)
    this.getJinedRoomMembers = this.getJinedRoomMembers.bind(this)
    this.addRoom = this.addRoom.bind(this)
    this.roomMembers = new Map()
    this.allMembers = {}
    this.presence = ''
  }

  async init(username, password, serverUrl, deviceId) {
    try {
      console.log('Calling init matrix...')
      const indexedDBStore = new sdk.IndexedDBStore({
        indexedDB: window.indexedDB,
        localStorage: window.localStorage,
        dbName: 'web-sync-store',
      })
      await indexedDBStore.startup()
      this.client = await sdk.createClient({
        baseUrl: serverUrl,
        userId: `@${username}:${serverUrl.split('//')[1]}`,
        // accessToken: null,
        deviceId,
      })

      this.user = store.getState()?.auth?.user
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 2500)
      })
      const response = await this.client.login('m.login.password', {
        user: username,
        password: password,
      })
      this.client.accessToken = response.access_token
      this.client.on('sync', this.syncRooms)
      this.client.on('Room', this.addRoom)
      this.client.on('event', this.getPresenceForMembers)
      await this.client.startClient({
        lazyLoadMembers: true,
        initialSyncLimit: 10,
        disablePresence: false,
      })

      let matrixPresence = await this.client.getPresence(this.client.getUserId())

      let presence = localStorageService.get('presence')
      if (!presence)
        localStorageService.set(
          'presence',
          this.user?.status_msg || matrixPresence?.status_msg || PRESENCE_TYPES.online,
        )
      this.presence = presence || this.user?.status_msg || matrixPresence.status_msg
      await this.client.setPresence({
        presence: PRESENCE_TYPES.online,
        status_msg: this.presence,
      })
    } catch (err) {
      console.log('Error instantiation matrix', err)
    }
  }

  getPresenceForMembers = async (event) => {
    // listening for the event type m.presence that we can track changes of the presence
    if (!event || event.getType() !== 'm.presence') return
    const sender = event?.event?.sender
    let presence = event?.event?.content?.presence
    const presence_status_message = event?.event?.content?.status_msg
    presence = presence_status_message || presence
    const currentUserId = this.client.getUserId()
    if (sender === currentUserId) {
      this.presence = presence
      localStorageService.set('presence', this.presence)
      store.dispatch(setPresence(this.presence))
    }

    if (
      sender &&
      presence !== 'unavailable' &&
      (!this.allMembers[sender] || !this.allMembers[sender].presence !== presence)
    ) {
      this.allMembers[sender] = {
        presence: presence,
      }
      store.dispatch(setAllMembers(JSON.parse(JSON.stringify(this.allMembers))))
    }
  }

  async getJinedRoomMembers() {
    if (!this.rooms || !this.rooms.size) return
    for (let room of this.rooms) {
      const members = await this.client.getJoinedRoomMembers(room.roomId)
      if (members?.joined && Object.keys(members.joined).length) {
        Object.keys(members.joined).forEach((key) => {
          if (!this.allMembers[key]) this.allMembers[key] = { presence: null }
        })
      }
      if (!this.roomMembers.get(room.roomId))
        this.roomMembers.set(room.roomId, { ...members.joined })
    }
  }

  async getJinedRoomMembersForRoom(roomId) {
    if (!roomId) return

    const members = await this.client.getJoinedRoomMembers(roomId)
    if (members?.joined && Object.keys(members.joined).length) {
      Object.keys(members.joined).forEach((key) => {
        if (!this.allMembers[key]) this.allMembers[key] = { presence: null }
      })
    }
    const roomMebers = this.roomMembers.get(roomId)
    if (!roomMebers || !isEqual(members.joined, roomMebers))
      this.roomMembers.set(roomId, { ...members.joined })
  }

  async syncRooms(state, prevState) {
    if (state === 'PREPARED') {
      if (prevState === null || prevState === 'ERROR') {
        await this.getAllRooms()
        await this.getJinedRoomMembers()
        store.dispatch(setAllMembers(JSON.parse(JSON.stringify(this.allMembers))))
      }
    }
  }

  async addRoom(room) {
    if (!this.rooms.size || !findObjectById(this.rooms, room?.roomId)) {
      const data = this.client.getRoom(room?.roomId)
      if (data) {
        // notify in the RoomList component that the new room is created
        const custom = new CustomEvent(CUSTOM_EVENT_TYPES.newRoomCreated)
        document.dispatchEvent(custom)
        let rooms = Array.from(this.rooms)
        rooms = [room, ...rooms]
        this.rooms = new Set(rooms)
        await this.getJinedRoomMembersForRoom(room?.roomId)
      }
    }
  }

  async logout() {
    this.client?.removeListener('sync', this.syncRooms)
    this.client?.removeListener('Room', this.addRoom)
    this.client?.removeListener('event', this.getPresenceForMembers)
    this.client?.stopClient()
    await this.client?.logout()
    await this.client?.clearStores()
    window.location.reload()
  }

  async createRoom(options) {
    const { client } = this
    const response = await client?.createRoom(options)
    return response
  }

  async getRoomMembers(roomId) {
    const { client } = this
    const response = await client?.getRoomMembers(roomId)
    return response
  }

  async getAllRooms() {
    store.dispatch(setIsLoading(true))
    const response = await this.client?.getRooms()
    const sortedRoom = response.sort((room1, room2) => {
      // sort by number of unread messages
      // return room2.getLastActiveTimestamp() - room1.getLastActiveTimestamp()
      if (room2.getLastActiveTimestamp() < room1.getLastActiveTimestamp()) {
        return -1
      } else if (room2.getLastActiveTimestamp() > room1.getLastActiveTimestamp()) {
        return 1
      } else {
        if (room2.getUnreadNotificationCount() < room1.getUnreadNotificationCount()) {
          return -1
        } else if (room2.getUnreadNotificationCount() > room1.getUnreadNotificationCount()) {
          return 1
        } else {
          return 0
        }
      }
    })
    this.rooms = new Set()
    sortedRoom?.forEach((room) => this.rooms.add(room))
    store.dispatch(setIsLoading(false))
  }
}

const initMatrix = new MatrixSingleton()
export default initMatrix
