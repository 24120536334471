import { api } from './index'

const fileShareApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getS3UploadUrl: builder.query({
      query: () => {
        return {
          url: `/fileshare/upload`,
        }
      },
    }),
    uploadFileToS3: builder.mutation({
      query: (data) => {
        return {
          url: `${data.url}`,
          method: 'PUT',
          body: data.file,
        }
      },
    }),
  }),
})
export const { useLazyGetS3UploadUrlQuery, useUploadFileToS3Mutation } = fileShareApiSlice
