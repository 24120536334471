import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useVerifyEmailMutation, useVerifyMutation } from '../../api/authApiSlice'
import logo from '../../assets/images/logo.png'
import Input from '../../components/Input/Input'
import { setCredentials } from '../../redux/reducers/authSlice'

import 'react-toastify/dist/ReactToastify.css'
import Button from '../../components/Button/Button'
import toastService from '../../services/toastService'
import styles from './Login.module.scss'

const Login = () => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const location = useLocation()
  const ref = useRef(null)
  const navigate = useNavigate()
  const { state } = location
  const [verify, { isLoading }] = useVerifyMutation()
  const [verifyEmail, { isLoading: isVerifyEmailLodaing }] = useVerifyEmailMutation()
  const from = location.state?.from?.pathname || '/'
  const [displayName, setDisplayName] = useState('')

  useEffect(() => {
    ref.current.focus()
  }, [])

  useEffect(() => {
    if (!state?.data?.verificationToken || !state?.number) {
      navigate('/register')
    }
  }, [navigate, state?.data?.verificationToken, state?.number])

  const handleChange = (e) => {
    const { value } = e.target
    setError('')
    setCode(value)
  }

  const handleConfirm = async (e) => {
    e.preventDefault()
    if (!code) {
      toastService.show('error', 'Verification code can not be empty')
      return
    }
    if (!state?.data?.verificationToken || !state.number) {
      toastService.show('error', 'Something went wrong. Please go to register page and try again')
      return
    }
    if (!state?.data?.displayNameExists && displayName.trim() === '') {
      toastService.show('error', 'Display Name can not be empty')
      return
    }
    const verificationBody = { code, verificationToken: state.data.verificationToken, displayName }
    let response
    if (state?.number) {
      verificationBody.number = state.number
      response = await verify(verificationBody, {})
    } else if (state?.data?.email) {
      verificationBody.email = state.data.email
      verificationBody.type = state.data.type
      response = await verifyEmail(verificationBody, {})
    }
    if (response.error) {
      if (response.error.data?.error?.message === 'Bad request Data mismatch') {
        setError('Invalid verification code')
      } else {
        setError(response.error.data?.error?.message || 'Something went wrong')
      }
    } else {
      dispatch(setCredentials(response.data))
      navigate(from, { replace: true })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.registerContainer}>
        <div className={styles.logoDiv}>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
        {state?.number !== '' ? (
          <p className={styles.confirmMsg}>
            We sent verification code to <br /> <b>{state?.number}</b>, please enter it.
          </p>
        ) : (
          <p className={styles.confirmMsg}>
            We sent verification code to <br /> <b>{state?.data?.email}</b>, please enter it.
          </p>
        )}
        <form onSubmit={handleConfirm}>
          <div className={styles.confirmDiv}>
            <Input
              className={styles.inputLogin}
              placeholder={'Verificaton code'}
              onChange={handleChange}
              forwardRef={ref}
              type={'text'}
            />
            {error ? <span className={styles.errorMsg}>{error}</span> : null}
            {!state?.data?.displayNameExists && (
              <Input
                placeholder='Display Name'
                className={styles.displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            )}

            <Button label={'Confirm'} disabled={isLoading || isVerifyEmailLodaing} />
            <Link className={styles.linkToRegister} to={'/register'}>
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
