import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Discuss } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../components/Input/Input'
import { selectCurrentId, selectCurrentUser, setUser } from '../../redux/reducers/authSlice'
import { closeModal } from '../../redux/reducers/modalSlice'
import { MdOutlineBackspace, MdClose, MdSearch } from 'react-icons/md'
import CustomIcon from '../../components/CustomIcon/CustomIcon'
import CallContacts from '../../components/CallContacts/CallContacts'
import { ReactComponent as AnswerCall } from '../../assets/images/answerCall.svg'
import {
  useLazyGetExtensionsQuery,
  useUpdateOrganizationMutation,
} from '../../api/extensionsApiSlice'
import { selectSelectedExtension, setSelectedExtension } from '../../redux/reducers/extensionsSlice'
import { resetSearchText, setSearchText } from '../../redux/reducers/searchSlice'
import { segmentAnalytics } from '../../App'

import styles from './Dialer.module.scss'
import RadioBtn from '../../components/Input/RadioBtn'
import Button from '../../components/Button/Button'
import { ReactComponent as EndCall } from '../../assets/images/callEnd.svg'
import { ReactComponent as TransferOff } from '../../assets/images/transferOff.svg'
import { ReactComponent as MergeOff } from '../../assets/images/mergeOff.svg'
import { ReactComponent as Swap } from '../../assets/images/swap.svg'
import { isProduction } from '../../environment'
import initTwilioVoice from '../../lib/initTwilioVoice'
import {
  removeCallOnHold,
  removeCallSlot,
  selectCallSlot,
  selectCurrentCall,
  selectIsConference,
  selectIsInCall,
  setCallSlot,
  setCallsOnHold,
  setCurrentCall,
} from '../../redux/reducers/callSlice'
import {
  useAnswerConferenceCallOnHoldMutation,
  useTransferNewCallMutation,
  useEndConferenceCallLineMutation,
  useMergeConferenceCallOnHoldMutation,
  useTransferConferenceCallLineMutation,
} from '../../api/callsApiSlice'
import toastService from '../../services/toastService'

// The digits parameter is a string and can contain the characters 0-9, *, #, and w
const ALLOWED_DTMF_TWILIO_DIGITS_REGEX = /^[0-9*#w]+$/

function getCallStyle(call, currentCall) {
  if (!call || !call.callerId) {
    return styles.emptySlot
  } else if (currentCall?.callerId === call?.callerId) {
    return styles.inCall
  } else if (call?.isOnHold) {
    return styles.onHold
  } else {
    return styles.blfBtn
  }
}

const Dialer = ({ number, name }) => {
  const [value, setValue] = useState(number || '')
  const [activeConnection, setActiveConnection] = useState(null)
  const [showExtensions, setShowExtensions] = useState(false)
  const [selectedCall, setSelectedCall] = useState(null)
  const [showTransferCall, setShowTransferCall] = useState(false)
  const selectedExtension = useSelector(selectSelectedExtension)
  const callSlots = useSelector(selectCallSlot)
  const currentCall = useSelector(selectCurrentCall)
  const isInCall = useSelector(selectIsInCall)
  const userId = useSelector(selectCurrentId)
  const isInConference = useSelector(selectIsConference)
  const [updateOrganization] = useUpdateOrganizationMutation()
  const [answerConferenceCallOnHol] = useAnswerConferenceCallOnHoldMutation()
  const [transferNewCall] = useTransferNewCallMutation()
  const [endConferenceCallLine] = useEndConferenceCallLineMutation()
  const [mergeConferenceCallOnHold] = useMergeConferenceCallOnHoldMutation()
  const [transferConferenceCallLine] = useTransferConferenceCallLineMutation()
  const navigate = useNavigate()
  const serachContactRef = useRef(null)

  const callsOnHold = callSlots.filter((call) => call?.isOnHold) || []

  const inputRef = useRef()
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)

  const [getExtensionQuery, { data, isLoading, isError, error }] = useLazyGetExtensionsQuery(
    user?._id,
  )
  const { extensions } = data || []

  const userExtension = extensions?.find(
    (extension) => extension.organizationId === user.organizationId,
  )

  useEffect(() => {
    inputRef.current?.focus()
  }, [showTransferCall])

  useEffect(() => {
    dispatch(resetSearchText())
    serachContactRef.current?.focus()
  }, [dispatch, showTransferCall])

  useEffect(() => {
    if (user?._id) getExtensionQuery(user._id)
  }, [getExtensionQuery, user?._id])

  useEffect(() => {
    dispatch(setSelectedExtension(userExtension))
  }, [dispatch, userExtension])

  useEffect(() => {
    return () => {
      if (activeConnection) {
        activeConnection.disconnect()
        setActiveConnection(null)
      }
    }
  }, [activeConnection])

  const handleClickBtn = useCallback(
    (num) => {
      setValue(value + num)
      if (initTwilioVoice?.connection?.sendDigits && ALLOWED_DTMF_TWILIO_DIGITS_REGEX.test(num)) {
        initTwilioVoice.connection.sendDigits(num)
      }
    },
    [value],
  )

  const handleDeleteLastDigit = useCallback(() => {
    setValue(value.slice(0, -1))
  }, [value])

  const handleChange = (event) => {
    const newValue = event.target.value
    if (/^\d*$/.test(newValue)) {
      setValue(newValue)
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      const { key } = event

      // Allow numeric keys and backspace/delete
      if (!isNaN(parseInt(key, 10)) || key === 'Backspace' || key === 'Delete' || key === '+') {
        event.preventDefault()
        if (key === 'Backspace' || key === 'Delete') {
          handleDeleteLastDigit()
        } else {
          handleClickBtn(key)
        }
      }
    },
    [handleClickBtn, handleDeleteLastDigit],
  )

  useEffect(() => {
    let inputField = inputRef?.current
    inputField?.addEventListener('keydown', handleKeyDown)
    const handlePaste = (e) => {
      const value = e.clipboardData.getData('text')
      if (!isNaN(parseInt(value, 10))) {
        setValue(value)
      }
    }
    inputField?.addEventListener('paste', handlePaste)
    return () => {
      inputField?.removeEventListener('keydown', handleKeyDown)
      inputField?.removeEventListener('paste', handlePaste)
    }
  }, [handleKeyDown])

  useEffect(() => {
    return () => {
      dispatch(setSearchText(''))
    }
  }, [dispatch])

  const handleMakeCall = async () => {
    const params = {}
    if (isInCall) return
    if (!selectedCall) {
      params.To = value
    } else {
      params.To = selectedCall.callerId
      params.organizationId = user?.organizationId
      params.extensionId = user?.extensionId
    }
    const { token } = await initTwilioVoice.getToken()
    if (token) initTwilioVoice.updateToken(token)
    initTwilioVoice.connectDevice(params)
    if (isProduction) {
      segmentAnalytics.track('Start audio call', { username: user?.endpoint?.username })
    }
    navigate('/call', {
      state: { params, name: selectedCall?.caller },
    })
    dispatch(closeModal())
  }

  const handleParkBtnClick = (call) => {
    if (!call?.callerId) return
    selectedCall?.callerId === call?.callerId ? setSelectedCall(null) : setSelectedCall(call)
  }

  const handleExtensionChange = async (extension) => {
    const data = await updateOrganization({ accountId: user?._id, extensionId: extension?._id })
    if (data?.data) dispatch(setUser(data.data))
    setShowExtensions(false)
  }

  const handleTransferCall = async (contact) => {
    if (!selectedCall?.callerId || !contact) return
    const to = contact?.endpoint?.username || contact?.number
    const callerId = selectedCall?.callerId
    let response
    if (!isInConference) {
      // initTwilioVoice.connection.disconnect()
      response = await transferNewCall({ accountId: userId, callerId, to })
    } else {
      response = await transferConferenceCallLine({ accountId: userId, callerId, to })
    }
    const { data } = response
    if (data?.error) {
      toastService.show('error', 'Error transfer call')
      return
    }
    if (data?.isSuccess) {
      initTwilioVoice.notifyForCancel()
      // if (!isInConference) initTwilioVoice.connection.disconnect()
      if (!callsOnHold.length) {
        initTwilioVoice?.handleReject()
        navigate('/')
      }
    }
  }

  const handleFilterContacts = (e) => {
    const { value } = e.target
    dispatch(setSearchText(value.toLowerCase()))
  }

  const handleEndCall = async () => {
    if (!selectedCall?.callerId) return
    if (!callsOnHold.length) {
      initTwilioVoice.handleReject()
      navigate('/')
    } else {
      const callerIds = selectedCall.callerId.split('#') || []
      const callerId = callerIds[0]
      if (!callerId) return
      const { data } = await endConferenceCallLine({
        accountId: userId,
        callerId: callerId,
      })
      if (data?.isSuccess) {
        if (!currentCall) {
          initTwilioVoice.handleReject()
          navigate('/')
        } else if (selectedCall.callerId === currentCall?.callerId) {
          setSelectedCall(null)
          dispatch(setCurrentCall(null))
          dispatch(removeCallOnHold(selectedCall))
          dispatch(removeCallSlot(selectedCall))
        } else {
          dispatch(removeCallOnHold(selectedCall))
          dispatch(removeCallSlot(selectedCall))
        }
        toastService.show('success', `Ended call ${selectedCall.caller}`)
      }
    }
  }

  const handleSwapCall = async () => {
    const currentCallIds = selectedCall?.callerId.split('#') || []
    const currentCallId = currentCallIds[0]
    if (!currentCallId) return
    const { data } = await answerConferenceCallOnHol({
      accountId: userId,
      callerId: currentCallId,
    })
    if (data?.isSuccess) {
      dispatch(removeCallOnHold(selectedCall))
      if (currentCall) {
        dispatch(setCallsOnHold(currentCall))
        toastService.show('success', `Added on hold ${currentCall?.caller}`)
      }
      dispatch(setCurrentCall(selectedCall))

      dispatch(closeModal())
    }
  }

  const handleMergeCall = async () => {
    const { data } = await mergeConferenceCallOnHold({
      accountId: userId,
      callerId: selectedCall.callerId,
    })
    if (data?.isSuccess) {
      const mergeCallData = {
        callerId: `${selectedCall.callerId}#${currentCall.callerId}`,
        participants: currentCall?.participants?.length
          ? [selectedCall, ...currentCall.participants]
          : [currentCall, selectedCall],
        caller: 'Merge call',
      }
      dispatch(closeModal())
      dispatch(removeCallOnHold(selectedCall))
      dispatch(removeCallSlot(currentCall))
      dispatch(setCallSlot(mergeCallData))
      dispatch(removeCallSlot({ ...selectedCall, action: 'merge' }))
      dispatch(setCurrentCall(mergeCallData))
    }
  }

  const rendersCallBtns = () => {
    if (isInCall && callsOnHold?.length) {
      return (
        <>
          {selectedCall.caller !== 'Merge call' && (
            <CustomIcon
              icon={<TransferOff className={styles.icon} />}
              label='Transfer call'
              onClick={() => setShowTransferCall(true)}
            />
          )}

          {currentCall?.callerId !== selectedCall?.callerId && (
            <>
              <CustomIcon
                icon={<Swap className={styles.icon} />}
                label='Swap call'
                onClick={handleSwapCall}
              />
              {currentCall?.callerId && selectedCall.caller !== 'Merge call' && (
                <CustomIcon
                  icon={<MergeOff className={styles.icon} />}
                  label='Merge call'
                  onClick={handleMergeCall}
                />
              )}
            </>
          )}

          <CustomIcon
            icon={<EndCall className={styles.icon} />}
            label='End call'
            onClick={handleEndCall}
          />
        </>
      )
    } else if (!isInCall) {
      return (
        <CustomIcon
          icon={<AnswerCall className={styles.icon} />}
          label='Make call'
          onClick={handleMakeCall}
        />
      )
    } else {
      return (
        <>
          {!isInConference && (
            <CustomIcon
              icon={<TransferOff className={styles.icon} />}
              label='Transfer call'
              onClick={() => setShowTransferCall(true)}
            />
          )}

          <CustomIcon
            icon={<EndCall className={styles.icon} />}
            label='End call'
            onClick={handleEndCall}
          />
        </>
      )
    }
  }

  if (isInCall && showTransferCall) {
    return (
      <div className={styles.transferCallWrapper}>
        <div className={styles.closeTransferCall}>
          <span className={styles.closeIcon}>
            <MdClose onClick={() => setShowTransferCall(false)} />
          </span>
        </div>
        <div className={styles.callContactsWrapper}>
          <header className={styles.callContactsHeader}>
            <div className={styles.searchInputWrapper}>
              <input
                type='text'
                onChange={handleFilterContacts}
                placeholder='Search'
                className={styles.input}
                ref={serachContactRef}
              />
              <MdSearch className={styles.icon} />
            </div>
            <span style={{ marginRight: '10px' }} onClick={() => setShowTransferCall(false)}>
              Close
            </span>
          </header>
          <CallContacts handleTransferCall={handleTransferCall} />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <h2>Make a Call</h2>
      <div className={styles.name}>
        <span>{name || selectedCall?.caller || ''}</span>
      </div>
      <audio id='audio-element' autoPlay />
      {isLoading ? (
        <div className={styles.loader}>
          <Discuss
            visible={true}
            height='50'
            width='50'
            ariaLabel='comment-loading'
            wrapperClass='comment-wrapper'
          />
        </div>
      ) : (
        <div className={styles.extensionsWrapper}>
          {user ? (
            <div className={styles.registerDiv}>
              <div className={styles.orbDiv}></div> <span>Registered</span>
            </div>
          ) : (
            <div className={styles.registerDiv}>
              <div className={styles.orbDivOff}></div> <span>Unregistered</span>
            </div>
          )}
          {data && !data?.error && !isError && extensions.length ? (
            <div className={styles.extensions}>
              <button
                onClick={() => setShowExtensions((prev) => !prev)}
                className={styles.extensionBtn}
              >
                {selectedExtension?.deviceName
                  ? selectedExtension.deviceName
                  : 'Select organziation'}
              </button>
              {showExtensions && !isError && (
                <div className={styles.extensionList}>
                  {extensions?.map((extension) => {
                    return (
                      <RadioBtn
                        key={extension._id}
                        label={extension.deviceName}
                        onChange={() => handleExtensionChange(extension)}
                        value={extension._id}
                        checked={extension.organizationId === selectedExtension?.organizationId}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          ) : error ? (
            <div className={styles.warning}>Not able to fetch groups</div>
          ) : (
            <div>There is no groups</div>
          )}
        </div>
      )}

      <div className={styles.dialerWrapper}>
        <div className={styles.inputBox}>
          <Input
            onChange={handleChange}
            className={styles.inputDialer}
            value={value}
            forwardRef={inputRef}
          />
        </div>
        <div className={styles.blfBtnsWrapper}>
          {callSlots?.map((call) => {
            return (
              <div key={call?.callerId || call.caller}>
                <Button
                  className={getCallStyle(call, currentCall)}
                  onClick={() => handleParkBtnClick(call)}
                >
                  {call?.caller}
                </Button>
              </div>
            )
          })}
        </div>
        {selectedCall ? (
          <div className={styles.callBtnWrapper}>
            <div className={styles.callBtnBox}>{rendersCallBtns()}</div>
          </div>
        ) : (
          <>
            {' '}
            <div className={styles.buttonBox}>
              <button onClick={() => handleClickBtn('1')}>1</button>
              <button onClick={() => handleClickBtn('2')}>2</button>
              <button onClick={() => handleClickBtn('3')}>3</button>
            </div>
            <div className={styles.buttonBox}>
              <button onClick={() => handleClickBtn('4')}>4</button>
              <button onClick={() => handleClickBtn('5')}>5</button>
              <button onClick={() => handleClickBtn('6')}>6</button>
            </div>
            <div className={styles.buttonBox}>
              <button onClick={() => handleClickBtn('7')}>7</button>
              <button onClick={() => handleClickBtn('8')}>8</button>
              <button onClick={() => handleClickBtn('9')}>9</button>
            </div>
            <div className={styles.buttonBox}>
              <button onClick={() => handleClickBtn('+')}>+</button>
              <button onClick={() => handleClickBtn('0')}>0</button>
              <button onClick={() => handleClickBtn('#')}>#</button>
            </div>
            <div className={styles.buttonBox}>
              <button className={styles.asterisk} onClick={() => handleClickBtn('*')}>
                <span>*</span>
              </button>
              <button className={styles.deleteBtn} onClick={() => handleDeleteLastDigit()}>
                <MdOutlineBackspace />
              </button>
            </div>
            <div className={styles.makeCallWrapper}>
              <CustomIcon icon={<AnswerCall className={styles.icon} />} onClick={handleMakeCall} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Dialer

Dialer.propTypes = {
  number: PropTypes.string,
  name: PropTypes.string,
}
