import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectModal } from '../redux/reducers/modalSlice'

export function useOnClickOutside(ref, handler) {
  const { show } = useSelector(selectModal)
  useEffect(
    () => {
      const listener = (event) => {
        const { target } = event
        // Do nothing if clicking ref's element or descendent elements
        if (show || !ref.current || ref.current.contains(target)) {
          return
        }
        setTimeout(() => handler(event), 100)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, show],
  )
}
