import { createSlice } from '@reduxjs/toolkit'

const initalState = {
  callSlots: [
    { caller: 'Empty slot 1', participants: [], isOnHold: false },
    { caller: 'Empty slot 2', participants: [], isOnHold: false },
    { caller: 'Empty slot 3', participants: [], isOnHold: false },
    { caller: 'Empty slot 4', participants: [], isOnHold: false },
  ],
  isInCall: false,
  currentCall: null,
  isConference: false,
  isInVideoCall: false,
  isOnHold: false,
}

const callsSlice = createSlice({
  name: 'calls',
  initialState: initalState,
  reducers: {
    setCallsOnHold: (state, action) => {
      state.callSlots = state.callSlots?.map((call) => {
        if (call?.callerId === action?.payload?.callerId) {
          return {
            ...call,
            isOnHold: true,
          }
        } else {
          return call
        }
      })
    },
    removeCallOnHold: (state, action) => {
      state.callSlots = state.callSlots?.map((call) => {
        if (call?.callerId === action?.payload?.callerId) {
          return {
            ...call,
            isOnHold: false,
          }
        } else {
          return call
        }
      })
    },
    setIsInCall: (state, action) => {
      state.isInCall = action.payload
    },
    setCurrentCall: (state, action) => {
      state.currentCall = {
        ...action.payload,
        isOnHold: false,
      }
    },
    resetCall: () => {
      return initalState
    },
    setCallSlot: (state, action) => {
      const index = state.callSlots.findIndex(
        (call) => call?.callerId === action?.payload?.callerId,
      )
      const copyCallSlots = [...state.callSlots]
      if (index !== -1) {
        copyCallSlots.splice(index, 1, {
          caller: `Empty slot ${index + 1}`,
          participants: [],
          isOnHold: false,
        })
      } else {
        const freeSpotIndex = state.callSlots.findIndex(
          (call) => !Object.prototype.hasOwnProperty.call(call, 'callerId'),
        )
        if (freeSpotIndex !== -1) {
          copyCallSlots.splice(freeSpotIndex, 1, action.payload)
        }
      }
      state.callSlots = copyCallSlots
    },
    removeCallSlot: (state, action) => {
      state.callSlots = state.callSlots.map((call, index) => {
        const callerIds = call?.callerId?.split('#') || []
        if (
          (callerIds?.length === 1 && callerIds[0] === action?.payload?.callerId) ||
          call?.callerId === action.payload?.callerId
        ) {
          return { caller: `Empty slot ${index + 1}`, participants: [], isOnHold: false }
        } else {
          const isInMergeCall = callerIds.find((id) => id === action?.payload?.callerId)
          if (isInMergeCall && !action?.payload.action) {
            return {
              ...call,
              callerId: callerIds.filter((id) => id !== action?.payload?.callerId).join('#'),
              participants: call.participants?.filter(
                (participant) => participant?.callerId !== action?.payload.callerId,
              ),
            }
          }
        }
        return call
      })
    },
    updateCurrentCall: (state, action) => {
      if (state?.currentCall) {
        const currentCal = { ...state.currentCall }
        const callerIds = currentCal?.callerId?.split('#') || []
        if (callerIds?.length === 1 && callerIds[0] === action?.payload?.callerId) {
          state.currentCall = null
        } else {
          state.currentCall = {
            ...currentCal,
            callerId: callerIds.filter((id) => id !== action?.payload?.callerId).join('#'),
            participants: currentCal.participants?.filter(
              (participant) => participant?.callerId !== action?.payload.callerId,
            ),
          }
        }
      }
    },
    setIsConference: (state, action) => {
      state.isConference = action.payload
    },
    setIsInVideoCall: (state, action) => {
      state.isInVideoCall = action.payload
    },
    replaceCallInSlot: (state, action) => {
      const copyCallSlots = [...state.callSlots]
      copyCallSlots.splice(action.payload.index, 1, { ...action.payload.call })
      state.callSlots = copyCallSlots
    },
    setIsOnHold: (state, action) => {
      state.isConference = true
      state.isOnHold = action.payload
    },
  },
})

export const {
  setCallsOnHold,
  setIsInCall,
  removeCallOnHold,
  setCurrentCall,
  resetCall,
  setCallSlot,
  setIsConference,
  removeCallSlot,
  setIsInVideoCall,
  updateCurrentCall,
  replaceCallInSlot,
  setIsOnHold,
} = callsSlice.actions

export default callsSlice.reducer

export const selectCallsOnHold = (state) => state.calls.callsOnHold
export const selectIsInCall = (state) => state.calls.isInCall
export const selectCurrentCall = (state) => state.calls.currentCall
export const selectCallSlot = (state) => state.calls.callSlots
export const selectIsConference = (state) => state.calls.isConference
export const selectIsInVideoCall = (state) => state.calls.isInVideoCall
export const selectIsOnHold = (state) => state.calls.isOnHold
