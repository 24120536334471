import { MdOutlineLogout } from 'react-icons/md'
import initMatrix from '../../lib/initMatrix'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logOut } from '../../redux/reducers/authSlice'
import logo from '../../assets/images/logo.png'

import styles from './WithoutPlan.module.scss'

const WithoutPlan = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleLogout = () => {
    initMatrix.logout()
    dispatch(logOut())
    navigate('/register')
  }

  return (
    <div className={styles.withoutPlanWrapper}>
      <div className={styles.leftSide}>
        <header>
          <button onClick={handleLogout}>
            <MdOutlineLogout /> logout
          </button>
        </header>
        <div>
          <h3>Welcome to the AirTouch®</h3>
          <p>
            Welcome to the AirTouch®. To use AirTouch® aplication please folow the steps on the{' '}
            <a rel='noopener noreferrer' href='https://airtouch.me' target='_blank'>
              AirTouch®{' '}
            </a>
            web site
          </p>
        </div>
      </div>
      <div className={styles.rightSide}>
        <div>
          <img className={styles.logo} src={logo} alt='Air Touch' />
        </div>
      </div>
    </div>
  )
}

export default WithoutPlan
