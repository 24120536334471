import { useState, useRef, useEffect, useCallback, useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  MdSend,
  MdOutlineAttachFile,
  MdRestoreFromTrash,
  MdOutlineInsertDriveFile,
  MdOutlineModeEdit,
  MdClose,
} from 'react-icons/md'
import { Tooltip } from 'react-tooltip'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { BsEmojiSmile } from 'react-icons/bs'
import TextareaAutosize from 'react-autosize-textarea'
import EmojiPicker from 'emoji-picker-react'
import MsgBox from './MessageBox'
import RemovedMessage from './RemovedMessage'
import ImageBox from './ImageBox'
import DocumentBox from './DocumentBox'
import VideoBox from './VideoBox'

import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover'

import initMatrix from '../../lib/initMatrix'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import { selectContactsMap } from '../../redux/reducers/contactsSlice'
import { selectIsLoadingMatrix } from '../../redux/reducers/matrixSlice'
import { useSendGroupSmsMmsMutation, useSendSmsMmsMutation } from '../../api/smsMmsApiSlice'
import { useLazyGetS3UploadUrlQuery } from '../../api/fileShareApiSlice'
import { deleteMsg } from '../../utils/matrix'
import { isSameDay, formatDate } from '../../utils/dateParsing'

import { MSG_TYPES } from '../../constants/matrixConst'

import { filterEvents, addToMap, isEdited } from '../../utils/matrix'
import { Direction } from 'matrix-js-sdk'
import { useOutletContext } from 'react-router-dom'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { segmentAnalytics } from '../../App'
import { isProduction, matrixServerName } from '../../environment'

import styles from './Chat.module.scss'
import CallBox from './CallBox'
import toastService from '../../services/toastService'

/**
 *
 * @param {Object} event matrix event (message) that contains url of the file
 */
const downloadFile = (event) => {
  fetch(initMatrix.client.mxcUrlToHttp(event.content.url))
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      // the filename you want
      a.download = event.content.body
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.error('Failed to download file:', error)
    })
}

const checkFileType = (fileType) => {
  if (!fileType) return ''
  if (fileType.startsWith('image')) return MSG_TYPES.image
  if (fileType.startsWith('video')) return MSG_TYPES.video
  if (fileType.startsWith('audio')) return MSG_TYPES.audio
  return MSG_TYPES.file
}

/**
 * Find the firsts message in the room it has a m.room.create type
 * @param {Array} events an array of matrix events
 * @returns boolean
 */
const canPaginateBackward = (events = []) => {
  if (!events || !Array.isArray(events)) return false
  return !!events?.find((event) => event?.type === 'm.room.create')
}

const delay = async (timeout = 200) => {
  await new Promise((resolve) => setTimeout(resolve, timeout))
}

let isLoadingEvents = false
const getMeesages = (
  roomId,
  fromToken,
  editedEventsMap,
  messagesWrapperRef,
  messageRef,
  setEvents,
) => {
  isLoadingEvents = true
  delay()
  // preventing to fetch matrix messages
  if (fromToken.isEnd) {
    return
  } else {
    initMatrix?.client
      ?.createMessagesRequest(roomId, fromToken?.end || null, 30, Direction.Backward)
      .then((data) => {
        canPaginateBackward(data?.chunk)
        fromToken.start = data?.start
        fromToken.end = data?.end
        fromToken.isEnd = canPaginateBackward(data?.chunk) || data?.chunk?.length === 0
        const filteredEvents = filterEvents(data.chunk, editedEventsMap)
        setEvents((prev) => {
          return [...filteredEvents, ...prev]
        })
        messagesWrapperRef?.current?.scroll({
          top: messagesWrapperRef?.current?.scrollHeight - messageRef?.current?.offsetTop - 10,
        })
      })
      .catch((err) => {
        console.log('Error createMessagesRequest', err)
      })
      .finally(() => (isLoadingEvents = false))
  }
}

/**
 * set matrix messages as read for specific room
 * @param {string} roomId matrix roomId
 * @returns void
 */
const setAsRead = async (roomId) => {
  if (!roomId || !initMatrix?.client) return
  const room = initMatrix.client.getRoom(roomId)

  const timeline = room?.getLiveTimeline()
  if (!timeline) return
  const { events } = timeline
  const event = events[events.length - 1]
  await initMatrix.client.sendReadReceipt(event)
}

/**
 *
 * @param {File} file that we want ot upload
 * @param {string} url
 * @returns Object
 */
const uploadToS3 = async (file, url) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
    if (response.ok && response.status === 200) {
      return { data: response }
    }
  } catch (err) {
    console.log('Error upload to s3: ', err)
  }
}

/**
 *
 * @param {Object} members matrix mambers of the room
 * @returns {Array} an Array of strings that represent username in DB based ono the matrix_id
 */
const extractRoomMemberNames = (members) => {
  const memberNames = []
  if (!members) return memberNames
  Object.keys(members).forEach((key) => {
    const usernameFromMatrixId = key.split(`:${matrixServerName}`)[0].slice(1)
    if (usernameFromMatrixId.startsWith('acc_')) {
      memberNames.push(usernameFromMatrixId)
    }
  })
  return memberNames
}

let joinedRoomMembers = []

const ChatMessages = () => {
  const [msg, setMsg] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [isOpenEmojiPicer, setOpenEmojiPicker] = useState(false)
  const messageRef = useRef(null)
  const inputRef = useRef(null)
  const [events, setEvents] = useState([])
  const user = useSelector(selectCurrentUser)
  const contactsMap = useSelector(selectContactsMap)
  const [haveNewEvents, setHavenewEvents] = useState(false)
  const isLoading = useSelector(selectIsLoadingMatrix)
  const messagesWrapperRef = useRef(null)
  const [roomId, isSms] = useOutletContext()
  const divRef = useRef(null)
  const roomIdRef = useRef(null)
  useOnClickOutside(divRef, () => setOpenEmojiPicker(false))

  const [sendSmsMms, { isLoading: isLoadingSendSms }] = useSendSmsMmsMutation()
  const [sendGroupSmsMms, { isLoading: isLoadingSendGroupSms }] = useSendGroupSmsMmsMutation()
  const [getS3UplaodUrl] = useLazyGetS3UploadUrlQuery()

  const refMap = useMemo(() => {
    return new Map()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId])
  let token = useMemo(() => {
    return { start: undefined, end: undefined, isEnd: false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId])

  useEffect(() => {
    setEvents([])
    setHavenewEvents((prev) => !prev)
    return () => {
      setEvents([])
      setHavenewEvents((prev) => !prev)
    }
  }, [roomId])

  useLayoutEffect(() => {
    if (!initMatrix.client || isLoading || !roomId) return
    getMeesages(roomId, token, refMap, messagesWrapperRef, messageRef, setEvents, 'iz use efekta')
    const getRoomMember = async () => {
      const members = await initMatrix.client.getJoinedRoomMembers(roomId)
      joinedRoomMembers = extractRoomMemberNames(members?.joined)
    }
    if (initMatrix.roomMembers.has(roomId)) {
      joinedRoomMembers = extractRoomMemberNames(initMatrix.roomMembers.get(roomId))
    } else {
      getRoomMember()
    }
  }, [isLoading, refMap, roomId, token])

  useEffect(() => {
    const room = initMatrix?.client?.getRoom(roomId)
    setAsRead(roomId)
    roomIdRef.current = roomId
    const handleRoomTimeliineEvents = (event, r, toStartOfTimeline) => {
      // checking roomId to prevent display messages from the other rooms
      if (roomIdRef.current !== r.roomId) return
      if (toStartOfTimeline) {
        // This event is from before the start of the timeline.
        return
      }
      if (event.getType() !== 'm.room.message' && event.getType() !== 'm.room.redaction') {
        // This event is not a message.
        return
      }
      // checking is message deleted
      if (event.getType() === 'm.room.redaction') {
        const index = events.findIndex((ev) => ev.event_id === event.event.redacts)

        const redactedEvent = events[index]
        if (redactedEvent) {
          redactedEvent.content = {}
          redactedEvent.redacted_because = {
            ...event.event,
          }
          let updatedEvents = [...events]
          updatedEvents = updatedEvents.map((ev) => {
            if (ev.event_id !== redactedEvent.event_id) return ev
            let newEvent = { ...ev }
            newEvent.redacted_because = {
              ...redactedEvent.redacted_because,
            }
            newEvent.unsigned = {
              ...event.event,
              redacted_because: {
                ...event.event,
              },
            }
            return newEvent
          })
          setEvents(updatedEvents)
          setHavenewEvents(!haveNewEvents)
        }
        return
      }
      if (isEdited(event.event)) {
        event.event.updatedAtCustomKey = new Date().getTime()
        addToMap(event.event, refMap)
        setHavenewEvents(!haveNewEvents)
        return
      } else if (event.getType() === 'm.room.message') {
        const findEvent = events.find((ev) => ev.event_id === event.getId())
        if (!findEvent) {
          const newEvents = [...events]
          newEvents.push(event.event)
          setEvents(newEvents)
          messageRef.current?.scrollIntoView()
        }
        return
      }
    }
    room?.on('Room.timeline', handleRoomTimeliineEvents)
    return () => {
      room?.removeEvent('Room.timeline', handleRoomTimeliineEvents)
    }
  }, [events, refMap, roomId, events.length, haveNewEvents])

  useLayoutEffect(() => {
    if (isLoading) return
    inputRef.current?.focus()
    if (events.length <= 30) messageRef.current?.scrollIntoView()
  }, [events, isLoading, roomId])

  const handleChange = (e) => {
    const { value } = e.target
    setMsg(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      sendMessage()
      setMsg('')
      setSelectedFile(null)
      inputRef.current.value = ''
    }
  }

  const cancelSend = () => {
    setSelectedEvent(null)
    setMsg('')
  }

  const sendMessage = async () => {
    if (isLoadingSendSms || isLoadingSendGroupSms) return
    let content = {}
    const trimmedMsg = msg.trim()
    if (selectedEvent) {
      content.body = `* ${selectedEvent.content.body}`
      content.msgtype = MSG_TYPES.text
      content['m.new_content'] = { body: msg, msgtype: MSG_TYPES.text }
      content['m.relates_to'] = {
        event_id: selectedEvent.event_id,
        rel_type: 'm.replace',
      }
      initMatrix.client
        .sendMessage(roomId, content)
        .then(() => {
          setHavenewEvents(!haveNewEvents)
          setSelectedEvent(null)
          setMsg('')
          if (isProduction)
            segmentAnalytics.track('Matrix message sent', { username: user?.endpoint?.username })
        })
        .catch((err) => {
          console.log('ERROR EDIT MESSAGE', err)
        })
    } else {
      if (selectedFile) {
        const info = {
          mimetype: selectedFile.type,
          size: selectedFile.size,
        }
        if (isSms) {
          try {
            const { data, error } = await getS3UplaodUrl()
            if (error) return
            const uploadedFile = await uploadToS3(selectedFile, data.url)
            if (!uploadedFile?.data?.url) return
            const mediaUrl = uploadedFile.data.url.split('?')[0]
            initMatrix.client
              .uploadContent(selectedFile)
              .then((data) => {
                content = { info }
                content.msgtype = checkFileType(selectedFile.type)
                content.url = data.content_uri
                content.body = selectedFile.name || 'unknown file'
                initMatrix.client.sendMessage(roomId, content).then((data) => {
                  sendSmsMmsMsg(data.event_id, msg, mediaUrl)
                  setSelectedFile(null)
                })
              })
              .catch((err) => {
                console.log('Error uploading file', err)
              })
          } catch (err) {
            console.log('errror get url', err)
          }
        } else {
          initMatrix.client
            .uploadContent(selectedFile)
            .then((data) => {
              content = { info }
              content.msgtype = checkFileType(selectedFile.type)
              content.url = data.content_uri
              content.body = selectedFile.name || 'unknown file'
              initMatrix.client.sendMessage(roomId, content).then(() => {
                setSelectedFile(null)
                if (isProduction) {
                  segmentAnalytics.track('Matrix message sent', {
                    username: user?.endpoint?.username,
                  })
                }
              })
            })
            .catch((err) => {
              console.log('Error uploading file', err)
            })
        }
      } else {
        if (!trimmedMsg) return
        initMatrix.client
          .sendMessage(roomId, { msgtype: MSG_TYPES.text, body: msg })
          .then((data) => {
            if (isSms) sendSmsMmsMsg(data.event_id, msg)
            setMsg('')
            inputRef.current.value = ''
            if (isProduction) {
              segmentAnalytics.track('Matrix message sent', {
                username: user?.endpoint?.username,
              })
            }
          })
      }
    }
  }

  const sendSmsMmsMsg = async (eventId, msg, mediaUrl) => {
    let members = await initMatrix.client.getJoinedRoomMembers(roomId)
    const res = await initMatrix.client.getJoinedRoomMembers(roomId)
    if (Object.keys(res.joined).length < 2) {
      const room = initMatrix?.client?.getRoom(roomId)
      const roomState = room?.currentState?.members
      members = roomState
    } else {
      members = res.joined
    }
    joinedRoomMembers = extractRoomMemberNames(members)
    const memberNames = joinedRoomMembers.filter((name) => name !== user.matrix.username)
    if (!memberNames || !Array.isArray(memberNames) || !memberNames.length || !eventId || !roomId)
      return

    const sendingData = {
      id: user._id,
      message: msg,
      event_id: eventId,
      room_id: roomId,
    }
    if (mediaUrl) sendingData.mediaUrl = [mediaUrl]
    if (memberNames.length === 1) {
      const phoneNumber = memberNames[0].split('acc_')[1]
      sendingData.to = `+${phoneNumber}`
      const data = await sendSmsMms(sendingData)
      if (data?.error?.data?.error?.message) {
        toastService.show('error', data?.error?.data?.error?.message)
      }
      if (!data?.error && isProduction) {
        segmentAnalytics.track('SMS message sent', { username: user?.endpoint?.username })
      }
    } else {
      const data = await sendGroupSmsMms(sendingData)
      if (data?.error?.data?.error?.message) {
        toastService.show('error', data?.error?.data?.error?.message)
      }
      if (!data?.error && isProduction) {
        segmentAnalytics.track('SMS message sent', { username: user?.endpoint?.username })
      }
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setSelectedFile(file)
  }

  const handleSlectedEmoji = (e) => {
    setMsg((prev) => (prev += e.emoji))
    setOpenEmojiPicker(false)
    inputRef.current.focus()
  }

  const handleOpenEmojiPicer = () => {
    setOpenEmojiPicker((prev) => !prev)
  }

  /**
   * We are using IntersectionObserver to handle user scroll
   * and fetch data when it find DOM element with ref lastContestCardDivElementRef
   */
  const contestObserver = useRef()
  const lastContestCardDivElementRef = useCallback(
    (node) => {
      if (isLoading || !initMatrix.client || !roomId || isLoadingEvents) return
      if (contestObserver.current) contestObserver.current.disconnect()
      // here we are preventing inital load matrix messages
      if (token.isEnd || !token.start) return
      contestObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            getMeesages(roomId, token, refMap, messagesWrapperRef, messageRef, setEvents)
          }
        },
        { threshold: 1 },
      )
      if (node) contestObserver.current.observe(node)
    },
    [isLoading, refMap, roomId, token],
  )

  return (
    <div className={styles.chatWrapper}>
      <div className={styles.chatMessages} ref={messagesWrapperRef}>
        {isLoading && <div>Loading...</div>}
        {!isLoading && events && events.length
          ? events.map((event, idx) => {
              if (!event) return null
              let divider = null
              if (
                !events?.[idx - 1] ||
                !isSameDay(events?.[idx - 1]?.origin_server_ts, event.origin_server_ts)
              ) {
                divider = (
                  <div className={styles.divider}>
                    <span>{formatDate(event.origin_server_ts, 'D MMM YYYY')}</span>
                  </div>
                )
              }
              if (refMap.get(event?.event_id)) {
                event.content = refMap.get(event.event_id)[
                  refMap.get(event.event_id).length - 1
                ].content
              }
              const sender = event.sender
              let senderPhone = sender.split('@acc_')?.[1]
              senderPhone = senderPhone?.split(`:${matrixServerName}`)?.[0]
              const matrixUser = initMatrix.client.getUser(sender)
              const senderName =
                contactsMap[`+${senderPhone}`]?.name &&
                !contactsMap[`+${senderPhone}`]?.name.startsWith('acc_')
                  ? contactsMap[`+${senderPhone}`]?.name
                  : contactsMap[`+${senderPhone}`]?.name &&
                    contactsMap[`+${senderPhone}`]?.name.startsWith('acc_') &&
                    contactsMap[`+${senderPhone}`]?.displayName
                  ? contactsMap[`+${senderPhone}`]?.displayName
                  : contactsMap[`+${senderPhone}`]?.name || matrixUser?.displayName

              const amISender = sender?.includes(user?.endpoint?.username)
              if (event.type !== 'm.room.message') return null

              const content = event.content
              const bodyText = event.content?.['m.new_content']?.body
                ? `${event.content?.['m.new_content']?.body} (edited)`
                : event.content.body

              if (event.content.msgtype === 'm.image') {
                return (
                  <div
                    key={event.event_id}
                    className={styles.msgWrapper}
                    ref={idx === 0 ? lastContestCardDivElementRef : null}
                    // style={idx === 0 ? { background: 'yellow' } : {}}
                  >
                    {divider}
                    {/* {amISender ? (
                      <Popover placement={'top'}>
                        <PopoverTrigger
                          style={
                            divider
                              ? { position: 'absolute', top: '67px', right: '15px', zIndex: 1 }
                              : {
                                  position: 'absolute',
                                  top: '20px',
                                  right: '15px',
                                  zIndex: 1,
                                }
                          }
                        >
                          <a data-tooltip-id={`options-${event.event_id}`}>
                            <Tooltip
                              id={`options-${event.event_id}`}
                              content='Options'
                              place='top'
                            />
                            <HiOutlineDotsVertical style={{ cursor: 'pointer' }} />
                          </a>
                        </PopoverTrigger>
                        <PopoverContent className={styles.popover}>
                          <div
                            className={styles.itemWrapper}
                            onClick={() => {
                              deleteMsg(roomId, event.event_id)
                              setHavenewEvents(!haveNewEvents)
                            }}
                          >
                            <span>Delete</span>
                            <MdRestoreFromTrash />
                          </div>
                        </PopoverContent>
                      </Popover>
                    ) : null} */}
                    <ImageBox
                      key={event.event_id}
                      position={amISender ? 'right' : 'left'}
                      sender={amISender ? '' : senderName || senderPhone}
                      url={initMatrix.client.mxcUrlToHttp(event.content.url)}
                      date={formatDate(event.origin_server_ts, 'HH:mm')}
                      onClick={() => downloadFile(event)}
                    />
                  </div>
                )
              } else if (event.content.msgtype === 'm.video') {
                return (
                  <div
                    key={event.event_id}
                    className={styles.msgWrapper}
                    ref={idx === 0 ? lastContestCardDivElementRef : null}
                    // style={idx === 0 ? { background: 'yellow' } : {}}
                  >
                    {divider}
                    {/* {amISender ? (
                      <Popover placement={'top'}>
                        <PopoverTrigger
                          style={
                            divider
                              ? { position: 'absolute', top: '67px', right: '15px', zIndex: 1 }
                              : {
                                  position: 'absolute',
                                  top: '20px',
                                  right: '15px',
                                  zIndex: 1,
                                }
                          }
                        >
                          <a data-tooltip-id={`options-${event.event_id}`}>
                            <Tooltip
                              id={`options-${event.event_id}`}
                              content='Options'
                              place='top'
                            />
                            <HiOutlineDotsVertical style={{ cursor: 'pointer' }} />
                          </a>
                        </PopoverTrigger>
                        <PopoverContent className={styles.popover}>
                          <div
                            className={styles.itemWrapper}
                            onClick={() => {
                              deleteMsg(roomId, event.event_id)
                              setHavenewEvents(!haveNewEvents)
                            }}
                          >
                            <span>Delete</span>
                            <MdRestoreFromTrash />
                          </div>
                        </PopoverContent>
                      </Popover>
                    ) : null} */}
                    <VideoBox
                      key={event.origin_server_ts}
                      position={amISender ? 'right' : 'left'}
                      sender={amISender ? '' : senderName || senderPhone}
                      url={initMatrix.client.mxcUrlToHttp(event.content.url)}
                      date={formatDate(event.origin_server_ts, 'HH:mm')}
                      onDownload={() => downloadFile(event)}
                    />
                  </div>
                )
              } else if (event.content.msgtype === 'm.file') {
                return (
                  <div
                    key={event.event_id}
                    className={styles.msgWrapper}
                    ref={idx === 0 ? lastContestCardDivElementRef : null}
                    // style={idx === 0 ? { background: 'yellow' } : {}}
                  >
                    {divider}
                    {/* {amISender ? (
                      <Popover placement={'top'}>
                        <PopoverTrigger
                          style={
                            divider
                              ? { position: 'absolute', top: '67px', right: '15px', zIndex: 1 }
                              : {
                                  position: 'absolute',
                                  top: '20px',
                                  right: '15px',
                                  zIndex: 1,
                                }
                          }
                        >
                          <a data-tooltip-id={`options-${event.event_id}`}>
                            <Tooltip
                              id={`options-${event.event_id}`}
                              content='Options'
                              place='top'
                            />
                            <HiOutlineDotsVertical style={{ cursor: 'pointer' }} />
                          </a>
                        </PopoverTrigger>
                        <PopoverContent className={styles.popover}>
                          <div
                            className={styles.itemWrapper}
                            onClick={() => {
                              deleteMsg(roomId, event.event_id)
                              setHavenewEvents(!haveNewEvents)
                            }}
                          >
                            <span>Delete</span>
                            <MdRestoreFromTrash />
                          </div>
                        </PopoverContent>
                      </Popover>
                    ) : null} */}

                    <DocumentBox
                      key={event.event_id}
                      position={amISender ? 'right' : 'left'}
                      sender={amISender ? '' : senderName || senderPhone}
                      fileName={event.content.body}
                      url={initMatrix.client.mxcUrlToHttp(event.content.url)}
                      date={formatDate(event.origin_server_ts, 'HH:mm')}
                      onDownload={() => {
                        downloadFile(event)
                      }}
                    />
                  </div>
                )
              } else if (event?.unsigned?.redacted_because) {
                return (
                  <div key={event.event_id} ref={idx === 0 ? lastContestCardDivElementRef : null}>
                    <RemovedMessage
                      sender={amISender ? 'You' : senderName || senderPhone || sender}
                      position={amISender ? 'right' : 'left'}
                    />
                    {divider}
                  </div>
                )
              } else if (content?.callType === 'video' || content?.callType === 'voice') {
                return (
                  <div
                    key={event.event_id}
                    className={styles.msgWrapper}
                    ref={idx === 0 ? lastContestCardDivElementRef : null}
                    // style={idx === 0 ? { background: 'yellow' } : {}}
                  >
                    {divider}
                    <CallBox
                      sender={amISender ? '' : senderName || senderPhone || sender}
                      message={bodyText}
                      date={formatDate(event.origin_server_ts, 'HH:mm')}
                      content={content}
                    />
                  </div>
                )
              }
              return (
                <div
                  key={event.event_id}
                  className={styles.msgWrapper}
                  ref={idx === 0 ? lastContestCardDivElementRef : null}
                  // style={idx === 0 ? { background: 'yellow' } : {}}
                >
                  {divider}
                  {amISender ? (
                    <Popover placement={'top'}>
                      <PopoverTrigger
                        style={
                          divider
                            ? { position: 'absolute', top: '67px', right: '15px', zIndex: 1 }
                            : {
                                position: 'absolute',
                                top: '20px',
                                right: '15px',
                                zIndex: 1,
                              }
                        }
                      >
                        <a data-tooltip-id={`options-${event.event_id}`}>
                          <Tooltip id={`options-${event.event_id}`} content='Options' place='top' />
                          <HiOutlineDotsVertical style={{ cursor: 'pointer' }} />
                        </a>
                      </PopoverTrigger>
                      <PopoverContent className={styles.popover}>
                        <div
                          className={styles.itemWrapper}
                          onClick={() => {
                            deleteMsg(roomId, event.event_id)
                            setHavenewEvents(!haveNewEvents)
                          }}
                        >
                          <span>Delete</span>
                          <MdRestoreFromTrash />
                        </div>
                        <div
                          className={styles.itemWrapper}
                          onClick={() => {
                            setSelectedEvent(event)
                            setMsg(event.content?.['m.new_content']?.body || event.content.body)
                            inputRef.current.focus()
                          }}
                        >
                          <span>Edit</span>
                          <MdOutlineModeEdit />
                        </div>
                      </PopoverContent>
                    </Popover>
                  ) : null}

                  {content?.body && (
                    <div
                      // style={idx === 0 ? { background: 'yellow' } : {}}
                      ref={idx === 0 ? lastContestCardDivElementRef : null}
                    >
                      <MsgBox
                        position={amISender ? 'right' : 'left'}
                        sender={
                          amISender ? '' : senderName || matrixUser?.displayName || senderPhone
                        }
                        message={bodyText}
                        date={formatDate(event.origin_server_ts, 'HH:mm')}
                      />
                    </div>
                  )}
                </div>
              )
            })
          : null}

        <div style={{ height: '10px' }} ref={messageRef}></div>
      </div>
      {isOpenEmojiPicer ? (
        <div ref={divRef} style={{ position: 'absolute', bottom: '60px' }}>
          <EmojiPicker onEmojiClick={handleSlectedEmoji} />
        </div>
      ) : null}
      <div className={styles.chatInput}>
        <label htmlFor='inputFile' className={styles.inputFile}>
          <span>
            <MdOutlineAttachFile />
          </span>
          <input
            id='inputFile'
            type='file'
            onChange={handleFileChange}
            value=''
            className={styles.inputFile}
          />
        </label>
        <BsEmojiSmile onClick={handleOpenEmojiPicer} className={styles.inputFile} />

        <div className={styles.inputArea}>
          {selectedFile && <PreviewFile file={selectedFile} onRemove={setSelectedFile} />}
          {!selectedFile && (
            <TextareaAutosize
              value={msg}
              ref={inputRef}
              name='message'
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              placeholder='Enter a message...'
            />
          )}
        </div>
        <div className={styles.inputBtns}>
          {selectedEvent && (
            <button onClick={cancelSend}>
              <MdClose />
            </button>
          )}
          <button onClick={sendMessage}>
            <MdSend />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatMessages

const imageMimeType = /image\/(png|jpg|jpeg)/i

const PreviewFile = ({ file, onRemove }) => {
  if (!file) return null

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {file.type.match(imageMimeType) ? (
        <img style={{ height: '40px' }} src={URL.createObjectURL(file)} />
      ) : (
        <>
          <span>{file.name}</span>
          <MdOutlineInsertDriveFile />
        </>
      )}
      <MdRestoreFromTrash onClick={() => onRemove(null)} />
    </div>
  )
}

PreviewFile.propTypes = {
  file: PropTypes.object,
  onRemove: PropTypes.func,
}
