import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CallContact from './CallContact'
import { useGetContactsQuery } from '../../api/contactsApiSlice'
import { selectCurrentId, selectCurrentUser } from '../../redux/reducers/authSlice'
import { sortContacts } from '../../utils/contacts'
import { selectFavorites } from '../../redux/reducers/contactsSlice'
import { selectAllMembers } from '../../redux/reducers/matrixSlice'
import { getPresenceFromContact } from '../../utils/contacts'
import { filterOrganizationContacts } from '../../utils/contacts'
import { selectSearchText } from '../../redux/reducers/searchSlice'

import styles from './CallContacts.module.scss'

const CallContacts = ({ handleTransferCall }) => {
  const id = useSelector(selectCurrentId)
  const favorites = useSelector(selectFavorites)
  const searchText = useSelector(selectSearchText)
  const user = useSelector(selectCurrentUser)
  let { data } = useGetContactsQuery(id, { refetchOnMountOrArgChange: true })
  const filteredOrganizationContacts = filterOrganizationContacts(
    data?.accountContacts,
    data?.organizationContacts,
  )

  data = [...filteredOrganizationContacts, ...(data?.accountContacts || [])]

  const allMembers = useSelector(selectAllMembers)

  const sortedContacts = useMemo(() => sortContacts(data, searchText), [data, searchText])

  return (
    <div className={styles.wrapper}>
      {sortedContacts?.length ? (
        sortedContacts?.map((contact) => {
          if (
            (user?.number && contact?.number === user.number) ||
            (user?.email && contact?.email === user.email)
          ) {
            return null
          }
          return (
            <CallContact
              contact={contact}
              key={contact._id || contact?.matrix?.matrixId}
              isFavorite={!!favorites?.[contact?.matrix?.matrixId]}
              presence={getPresenceFromContact(allMembers, contact)}
              handleTransferCall={handleTransferCall}
              searchText={searchText}
            />
          )
        })
      ) : (
        <CallContact
          contact={{ number: searchText }}
          handleTransferCall={handleTransferCall}
          searchText={searchText}
        />
      )}
    </div>
  )
}

CallContacts.propTypes = {
  handleTransferCall: PropTypes.func.isRequired,
}

export default CallContacts
