import { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatSeconds } from '../../utils/dateParsing'
import { closeModal, openModal } from '../../redux/reducers/modalSlice'
import initTwilioVoice from '../../lib/initTwilioVoice'

import avatar from '../../assets/images/defaultAvatar.svg'
import Dialer from '../../pages/dialer/Dialer'
import { ReactComponent as MuteOff } from '../../assets/images/micOff.svg'
import { ReactComponent as MuteOn } from '../../assets/images/micOn.svg'
// import { ReactComponent as CameraOn } from '../../assets/images/camOn.svg'
// import { ReactComponent as PlussOff } from '../../assets/images/plusOff.svg'
import { ReactComponent as ReacordingOff } from '../../assets/images/recordingOff.svg'
import { ReactComponent as ReacordingOn } from '../../assets/images/recordingOn.svg'
// import { ReactComponent as TranscribeOff } from '../../assets/images/transcribeOff.svg'
import { ReactComponent as EndCall } from '../../assets/images/callEnd.svg'
// import { ReactComponent as HodlOff } from '../../assets/images/holdOff.svg'
import { ReactComponent as DialerOff } from '../../assets/images/dialerOff.svg'
// import { ReactComponent as Swap } from '../../assets/images/swap.svg'
import CustomIcon from '../CustomIcon/CustomIcon'
import {
  removeCallOnHold,
  removeCallSlot,
  selectCurrentCall,
  selectIsInCall,
  selectIsInVideoCall,
  setCurrentCall,
  setIsInCall,
  updateCurrentCall,
  selectCallSlot,
  setIsOnHold,
  selectIsOnHold,
} from '../../redux/reducers/callSlice'
import { useRecordCallMutation, useStopRecordingCallMutation } from '../../api/callsApiSlice'

import { useDispatch, useSelector } from 'react-redux'
import { CUSTOM_EVENT_TYPES } from '../../constants/matrixConst'

import styles from './InCall.module.scss'
import { selectCurrentId } from '../../redux/reducers/authSlice'

const Call = () => {
  const [duration, setDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  const [callData, setCallData] = useState(null)
  const isInCall = useSelector(selectIsInCall)
  const callSlots = useSelector(selectCallSlot)
  const currentCall = useSelector(selectCurrentCall)
  const userId = useSelector(selectCurrentId)
  const isOnHold = useSelector(selectIsOnHold)

  const [recordCall] = useRecordCallMutation()
  const [stopRecordingCall] = useStopRecordingCallMutation()

  const callsOnHold = callSlots?.filter((call) => call?.isOnHold)

  const location = useLocation()
  const dispatch = useDispatch()
  const { state } = location
  const navigate = useNavigate()
  const isInVideCall = useSelector(selectIsInVideoCall)

  const callerName = useMemo(() => {
    if (!currentCall?.participants?.length) return currentCall?.caller || ''

    const name =
      'Merged call: ' +
      currentCall.participants
        .map((participant) => {
          if (participant?.caller) return participant.caller
          return participant.callerId
        })
        .join('-')
    return name
  }, [currentCall?.caller, currentCall?.participants])

  useEffect(() => {
    if (!state?.params && !state?.incommingCall) {
      initTwilioVoice.handleReject()
      if (!isInVideCall) navigate('/')
    }
  }, [isInVideCall, navigate, state?.incommingCall, state?.params])

  useEffect(() => {
    const handleCallEnded = (event) => {
      dispatch(closeModal())
      const hasNewCall = event?.detail?.message?.hasNewCall
      const hasCallOnHold = event?.detail?.message?.hasCallOnHold
      const callerId = event?.detail?.message?.callerId
      if (callerId) {
        const currentCallIds = currentCall?.callerId?.split('#') || []
        const shouldUpdateCurrentCall = currentCallIds.find((id) => id === callerId)
        if (currentCallIds.length > 1 && shouldUpdateCurrentCall) {
          dispatch(updateCurrentCall({ callerId }))
        }
        dispatch(removeCallOnHold({ callerId }))
        dispatch(removeCallSlot({ callerId }))
        if (currentCall?.callerId === callerId) {
          dispatch(setCurrentCall(null))
        }
      }
      if (!hasNewCall && !hasCallOnHold) {
        initTwilioVoice.handleReject()
        if (!isInVideCall) navigate('/')
        return
      }
    }

    document.addEventListener(CUSTOM_EVENT_TYPES.call_ended, handleCallEnded)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.call_ended, handleCallEnded)
    }
  }, [currentCall?.callerId, dispatch, isInVideCall, navigate])

  useEffect(() => {
    const handleMuteCall = () => {
      setIsMuted(true)
    }
    const handleUnmuteCall = () => {
      setIsMuted(false)
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.muteCall, handleMuteCall)
    document.addEventListener(CUSTOM_EVENT_TYPES.unmuteCall, handleUnmuteCall)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.muteCall, handleMuteCall)
      document.removeEventListener(CUSTOM_EVENT_TYPES.unmuteCall, handleUnmuteCall)
    }
  }, [])

  useEffect(() => {
    let interval
    const handleAnsweredCall = () => {
      setDuration(0)
      clearInterval(interval)
      dispatch(setIsInCall(true))
      interval = setInterval(() => {
        setDuration((prev) => prev + 1)
      }, 1000)
    }
    if (state?.incommingCall) {
      handleAnsweredCall()
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.answered_call, handleAnsweredCall)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.answered_call, handleAnsweredCall)
      clearInterval(interval)
    }
  }, [dispatch, state?.incommingCall])

  useEffect(() => {
    const hendlePutOnHold = () => {
      dispatch(setIsOnHold(true))
    }

    const hendleActiveInConference = () => {
      dispatch(setIsOnHold(false))
    }
    document.addEventListener(CUSTOM_EVENT_TYPES.put_on_hold, hendlePutOnHold)
    document.addEventListener(CUSTOM_EVENT_TYPES.active_in_conference, hendleActiveInConference)
    return () => {
      document.removeEventListener(CUSTOM_EVENT_TYPES.put_on_hold, hendlePutOnHold)
      document.removeEventListener(
        CUSTOM_EVENT_TYPES.active_in_conference,
        hendleActiveInConference,
      )
    }
  }, [dispatch])

  const handleHangup = () => {
    initTwilioVoice.handleReject()
    navigate('/')
  }

  const handleShowDialer = () => {
    dispatch(
      openModal({
        show: true,
        content: <Dialer />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const handleRecording = async () => {
    let action
    let props = { accountId: userId }
    if (callData && callData?.callSid && callData?.recordingSid) {
      action = stopRecordingCall
      props = {
        ...props,
        ...callData,
      }
    } else {
      action = recordCall
    }

    const { data } = await action(props)
    if (data?.isSuccess && data?.callSid && data?.recordingSid) {
      setCallData({ callSid: data.callSid, recordingSid: data.recordingSid })
    } else {
      setCallData(null)
    }
  }

  return (
    <div className={styles.callWrapper}>
      <div className={styles.call}>
        <div className={styles.onHoldWrapper}>
          {callsOnHold?.length
            ? callsOnHold?.map((call) => {
                if (!call?.callerId) return null
                return (
                  <div className={styles.onHold} key={call.callerId}>
                    <span className={styles.name}>{call.caller}</span>
                    <span className={styles.holdBnt}>HOLD</span>
                  </div>
                )
              })
            : null}
        </div>
        <div className={styles.contactInfo}>
          <img src={avatar} alt='' />
          {isOnHold ? <span className={styles.callingInfo}>On hold</span> : null}
          {!currentCall ? (
            <span className={styles.name}>
              You have{' '}
              {callsOnHold?.length > 1
                ? `${callsOnHold?.length} calls `
                : `${callsOnHold?.length} call `}
              on hold
            </span>
          ) : (
            <>
              <span className={styles.name}>{callerName || ''}</span>
              {state?.organizationName ? (
                <span className={styles.callingInfo}>({state.organizationName})</span>
              ) : null}
              <span className={styles.callingInfo}>
                {duration === 0 ? 'Calling...' : formatSeconds(duration)}
              </span>
            </>
          )}
        </div>
        {isInCall && (
          <div className={styles.btnWrapper}>
            {isMuted ? (
              <CustomIcon
                icon={<MuteOff className={styles.icon} />}
                label='Mute'
                onClick={initTwilioVoice.unmuteCall}
              />
            ) : (
              <CustomIcon
                icon={<MuteOn className={styles.icon} />}
                label='Mute'
                onClick={initTwilioVoice.muteCall}
              />
            )}
            {callData ? (
              <CustomIcon
                icon={<ReacordingOn className={styles.icon} />}
                label='Record'
                onClick={handleRecording}
              />
            ) : (
              <CustomIcon
                icon={<ReacordingOff className={styles.icon} />}
                label='Record'
                onClick={handleRecording}
              />
            )}
            {/* <CustomIcon
              icon={<CameraOn className={styles.icon} />}
              label='Video'
              onClick={() => console.log('Click')}
            />
            <CustomIcon
              icon={<PlussOff className={styles.icon} />}
              label='Add'
              onClick={() => console.log('Click')}
            />
            <CustomIcon
              icon={<Swap className={styles.icon} />}
              label='Swap'
              onClick={() => console.log('Click')}
            />
            <CustomIcon
              icon={<TranscribeOff className={styles.icon} />}
              label='Transcribe'
              onClick={() => console.log('Click')}
            /> */}
          </div>
        )}
        <div className={styles.callBtns}>
          {/* {isInCall && (
            <CustomIcon
              icon={<HodlOff className={styles.icon} />}
              onClick={() => console.log('Click')}
            />
          )} */}
          <CustomIcon icon={<EndCall className={styles.icon} />} onClick={handleHangup} />
          {isInCall && (
            <CustomIcon icon={<DialerOff className={styles.icon} />} onClick={handleShowDialer} />
          )}
        </div>
      </div>
    </div>
  )
}

export default Call
