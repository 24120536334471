import React from 'react'
import PropTypes from 'prop-types'
import { MdStar } from 'react-icons/md'
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import avatar from '../../assets/images/defaultAvatar.svg'
import atouch from '../../assets/images/atouch.svg'

// import Button from '../Button/Button'
import ContactProfile from '../ContactProfile/ContactProfile'
// import Share from '../Share/Share'
import { setFavorites } from '../../redux/reducers/contactsSlice'
// import { useInvitePersonMutation } from '../../api/authApiSlice'
// import { selectCurrentId } from '../../redux/reducers/authSlice'
import { openModal } from '../../redux/reducers/modalSlice'
// import toastService from '../../services/toastService'

import styles from './Contact.module.scss'

/**
 * TODO, after implementation primary user on web app,
 * we should uncomment comented line of code, so that we can invite people
 * from contact to join our organization
 */

const Contact = ({ contact, message, isFavorite, presence }) => {
  const dispatch = useDispatch()
  // const userId = useSelector(selectCurrentId)
  // const [invitePerson] = useInvitePersonMutation()
  if (!contact) return null

  // const clickHandler = async () => {
  //   if (!userId || !contact?.number) return
  //   const { data, error } = await invitePerson({
  //     accountId: userId,
  //     number: contact.number,
  //   })
  //   if (error) {
  //     toastService.show('error', `Error invite contact ${contact?.name}`)
  //     return
  //   }
  //   if (data?.link) {
  //     dispatch(
  //       openModal({
  //         show: true,
  //         content: <Share url={data.link} title={`Invite ${contact?.name} to join Airtouch`} />,
  //         size: 'md',
  //       }),
  //     )
  //   }
  // }

  const openModalHandler = () => {
    dispatch(
      openModal({
        show: true,
        content: <ContactProfile contact={contact} contactName={contactName} />,
        size: 'md',
        closeOnOutsideClick: false,
      }),
    )
  }

  const contactName =
    contact?.name && contact?.name.startsWith('acc_') && contact?.displayName
      ? contact?.displayName
      : contact?.name && contact?.email && contact?.displayName
      ? contact?.displayName
      : contact?.name && contact?.email && !contact?.displayName
      ? contact?.email
      : contact?.name

  return (
    <div>
      <div className={styles.contact}>
        <div className={styles.avatarWrapper} onClick={openModalHandler}>
          <img className={styles.avatarImg} src={avatar} alt='' />
          {contact?.osc ? <span className={styles[presence]}></span> : null}
        </div>
        <div className={styles.contactInfo}>
          <div>
            <div className={styles.airtouchContacts} onClick={openModalHandler}>
              <span className={styles.name}> {contactName} </span>
              {contact?.osc ? (
                <div className={styles.logoDiv}>
                  <img className={styles.googleImg} src={atouch} alt='' />
                </div>
              ) : null}
            </div>
            {contact?.osc ? (
              <span
                className={isFavorite ? styles.favoriteActive : styles.favorite}
                onClick={() => {
                  dispatch(setFavorites(contact))
                }}
              >
                <MdStar />
              </span>
            ) : // <Button onClick={clickHandler} className={styles.inviteBtn} label={'Invite'} />
            null}
          </div>
          <div className={styles.organizationNameDiv} onClick={openModalHandler}>
            <span className={styles.organizationName}>{contact.organizationName}</span>
          </div>
          {message && (
            <div>
              {' '}
              <span className={styles.msg}>message</span>{' '}
              <span className={styles.dateMsg}>12.5.2023.</span>{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  message: PropTypes.string,
  isFavorite: PropTypes.bool.isRequired,
  presence: PropTypes.string.isRequired,
}

export default Contact
