import { api } from './index'
import { setUser } from '../redux/reducers/authSlice'
import { PRESENCE_TYPES } from '../constants/matrixConst'
import { setPresence } from '../redux/reducers/matrixSlice'
import { localStorageService } from '../services/localStorage'
import { tag } from './rtkTags'

const authApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: '/account/web/login',
        method: 'POST',
        body: {
          ...user,
        },
      }),
    }),
    register: builder.mutation({
      query: (data) => {
        return {
          url: 'account/register',
          method: 'POST',
          body: { number: data.number },
        }
      },
    }),
    registerWithGoogle: builder.mutation({
      query: (data) => {
        return {
          url: 'google/register',
          method: 'POST',
          body: { data },
        }
      },
    }),
    registerWithMicrosoft: builder.mutation({
      query: (data) => {
        return {
          url: 'microsoft/register',
          method: 'POST',
          body: { data },
        }
      },
    }),
    verify: builder.mutation({
      query: (data) => {
        return {
          url: 'account/number/verification',
          method: 'POST',
          headers: { 'x-verification-token': data.verificationToken },
          body: { number: data.number, code: data.code, displayName: data.displayName },
        }
      },
    }),
    getById: builder.query({
      query: (id) => ({
        url: `/account/${id}`,
      }),
      providesTags: [tag.user],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
          dispatch(
            setPresence(
              data?.status_msg || localStorageService.get('presence') || PRESENCE_TYPES.online,
            ),
          )
        } catch (err) {
          console.log(err)
        }
      },
    }),
    verifyEmail: builder.mutation({
      query: (data) => {
        return {
          url: `${data.type}/email/verification`,
          method: 'POST',
          headers: { 'x-verification-token': data.verificationToken },
          body: { email: data.email, code: data.code },
        }
      },
    }),
    getCallToken: builder.query({
      query: (accountId) => {
        return {
          url: `/account/${accountId}/call/token/ios`,
        }
      },
    }),
    videoToken: builder.mutation({
      query: (data) => {
        return {
          url: `/account/${data.accountId}/video/token/ios`,
          method: 'POST',
          body: { roomName: data.roomName },
        }
      },
    }),
    videoCall: builder.mutation({
      query: (data) => {
        return {
          url: `account/video/call/${data.accountId}/sandbox`,
          method: 'POST',
          body: { room_id: data.room_id, audioOnly: data.audioOnly },
        }
      },
    }),
    roomId: builder.mutation({
      query: (data) => {
        return {
          url: `matrix/${data.accountId}/createRoom`,
          method: 'POST',
          body: { accountContactNumbers: [data.contactNumber] },
        }
      },
    }),
    webToken: builder.mutation({
      query: (data) => {
        return {
          url: `account/${data.accountId}/web`,
          method: 'PUT',
          body: { webToken: data.token },
        }
      },
    }),
    setStatus: builder.mutation({
      query: (data) => {
        return {
          url: `account/${data.accountId}/status`,
          method: 'PUT',
          body: { presence: PRESENCE_TYPES.online, status_msg: data.status_msg },
        }
      },
    }),
    updateDisplayName: builder.mutation({
      query: (data) => {
        return {
          url: `account/${data.accountId}/displayName`,
          method: 'PUT',
          headers: { 'x-access-token': data.accessToken },
          body: { displayName: data.displayName },
        }
      },
    }),
    getShareVideoLink: builder.query({
      query: (data) => {
        return {
          url: `account/${data.accountId}/video/link/${data.roomId}`,
        }
      },
    }),
    getGuestVideoToken: builder.query({
      query: (code) => {
        return {
          url: `account/video/link/join/${code}`,
        }
      },
    }),
    invitePerson: builder.mutation({
      query: (data) => {
        return {
          url: `account/${data.accountId}/invite`,
          method: 'POST',
          body: { number: data.number },
        }
      },
    }),
    sendVoiceAnswered: builder.mutation({
      query: (data) => {
        return {
          url: `twilio/${data.accountId}/voice/answered`,
          method: 'POST',
          body: { callSid: data.callSid },
        }
      },
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyMutation,
  useRegisterWithGoogleMutation,
  useGetByIdQuery,
  useVerifyEmailMutation,
  useRegisterWithMicrosoftMutation,
  useGetCallTokenQuery,
  useVideoTokenMutation,
  useVideoCallMutation,
  useRoomIdMutation,
  useWebTokenMutation,
  useSetStatusMutation,
  useUpdateDisplayNameMutation,
  useLazyGetShareVideoLinkQuery,
  useLazyGetGuestVideoTokenQuery,
  useInvitePersonMutation,
  useSendVoiceAnsweredMutation,
} = authApiSlice
