import React, { useEffect, useState } from 'react'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { useNavigate, useLocation } from 'react-router-dom'

import dayjs from 'dayjs'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectContactsMap } from '../../redux/reducers/contactsSlice'
import avatar from '../../assets/images/defaultAvatar.svg'

import { selectCurrentUser } from '../../redux/reducers/authSlice'
import { getPresence, getRoomName } from '../../utils/matrix'
import { truncateString } from '../../utils/string'
import { selectAllMembers } from '../../redux/reducers/matrixSlice'
import initMatrix from '../../lib/initMatrix'

import styles from './Room.module.scss'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

// COMMENTED TIS LINE LIB DOES NOT SUPPORT latestPreviewableEvent
// const formatDate = (date) => {
//   let checkDate = dayjs(date)
//   if (!checkDate || !checkDate.isValid()) return ''
//   if (checkDate.isToday()) {
//     return checkDate.format('HH:mm')
//   } else if (checkDate.isYesterday()) {
//     return 'Yesterday'
//   }
//   return checkDate.format('YYYY.M.D')
// }

const Room = ({ room }) => {
  const user = useSelector(selectCurrentUser)
  const contactsMap = useSelector(selectContactsMap)
  const navigate = useNavigate()
  const location = useLocation()
  const [roomName, setRoomName] = useState('')
  // COMMENTED TIS LINE LIB DOES NOT SUPPORT latestPreviewableEvent
  // const [lastMessage, setLastMessage] = useState('')
  // const [lastMessageTimestamp, setLastMessageTimestamp] = useState(undefined)
  const [notificationCounts, setNotificationCounts] = useState(0)
  const allMembers = useSelector(selectAllMembers)
  const { roomMembers } = initMatrix

  const roomId = room?.roomId
  const membersOfTheRoom = roomMembers.get(roomId) || {}

  const presence = getPresence(allMembers, membersOfTheRoom)

  useEffect(() => {
    if (room?.roomId && user?.endpoint?.username) {
      const test = async () => {
        await getRoomName(user.endpoint.username, room.roomId, contactsMap, setRoomName, true)
      }
      test()
    }
  }, [contactsMap, room?.roomId, user?.endpoint?.username])

  useEffect(() => {
    if (location?.pathname?.includes(roomId) && location?.state?.roomName) {
      setRoomName(location?.state?.roomName)
    }
  }, [location, location?.pathname, location?.state?.roomName, roomId])

  useEffect(() => {
    // COMMENTED TIS LINE LIB DOES NOT SUPPORT latestPreviewableEvent
    // const timeline = room?.getLiveTimeline()
    // const events = timeline?.getEvents()
    // const lastEvent = events?.[events.length - 1]
    // const message = lastEvent?.getContent()?.body || ''
    // const timeStampMessage = lastEvent?.getTs() ? lastEvent?.getTs() : ''
    // setLastMessage(message)
    // setLastMessageTimestamp(timeStampMessage)
    setNotificationCounts(room?.notificationCounts?.total)
    if (location.pathname.includes(room.roomId)) {
      setNotificationCounts(0)
    }
    const handleMessages = (event, timeline) => {
      // COMMENTED TIS LINE LIB DOES NOT SUPPORT latestPreviewableEvent
      // const message2 = event.getContent()
      // const timeStampMessage2 = event.getTs()
      // setLastMessage(message2.body)
      // setLastMessageTimestamp(timeStampMessage2)
      if (!location.pathname.includes(room.roomId)) {
        setNotificationCounts(timeline?.notificationCounts?.total)
      }
    }
    room.on('Room.timeline', handleMessages)
    return () => {
      room.removeListener('Room.timeline', handleMessages)
    }
  }, [location.pathname, room, room.roomId])

  if (!room || !room.roomId || !user) return null

  const name = roomName || room?.name || ''

  const handleRoomClick = async () => {
    try {
      navigate(`/chat/${room.roomId}`, { state: { roomName: name } })
    } catch (error) {
      console.log('ERROR IN HANDLE ROOM CLICK: ', error)
    }
  }

  return (
    <div>
      <div
        className={`${styles.room} ${
          location.pathname.includes(room.roomId) ? styles.roomActive : ''
        }`}
        onClick={handleRoomClick}
      >
        <div className={styles.imgWrapper}>
          <img src={avatar} alt='' />
          <span className={styles[presence.toLowerCase()]}></span>
        </div>
        <div className={styles.contactInfo}>
          <div className={styles.nameWrapper}>
            <span className={styles.name}> {truncateString(name, 23)}</span>
            {notificationCounts ? (
              <span className={styles.notificationCounts}>{notificationCounts}</span>
            ) : null}
          </div>
          {/* COMMENTED TIS LINE LIB DOES NOT SUPPORT latestPreviewableEvent *}
          {/* {lastMessage && (
            <div>
              {' '}
              <span className={styles.msg}>{truncateString(lastMessage, 20)}</span>{' '}
              <span className={styles.dateMsg}>{formatDate(lastMessageTimestamp)}</span>{' '}
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}

Room.propTypes = {
  room: PropTypes.object,
  message: PropTypes.string,
}

export default Room
