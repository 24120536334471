import { api } from './index'
import { tag } from './rtkTags'

const invitationApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvites: builder.query({
      query: (id) => {
        return {
          url: `/account/${id}/extensions/invites`,
        }
      },
    }),
    acceptInvitation: builder.mutation({
      query: (data) => {
        return {
          url: `/account/${data.accountId}/extensions/invite/${data.invitationId}/accept`,
          method: 'PUT',
        }
      },
      invalidatesTags: [tag.invites],
    }),
    rejectInvitation: builder.mutation({
      query: (data) => {
        return {
          url: `/account/${data.accountId}/extensions/invite/${data.invitationId}/reject`,
          method: 'PUT',
          body: { number: data.number, code: data.code },
        }
      },
      invalidatesTags: [tag.invites],
    }),
  }),
})

export const { useGetInvitesQuery, useAcceptInvitationMutation, useRejectInvitationMutation } =
  invitationApiSlice
