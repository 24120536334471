import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import { selectContacts, selectContactsMap } from '../../redux/reducers/contactsSlice'
import Checkbox from '../Input/Checkbox'
import Button from '../Button/Button'
import { useCreateRoomMutation } from '../../api/messageApiSlice'

import Input from '../Input/Input'
import { selectCurrentUser } from '../../redux/reducers/authSlice'
import { closeModal } from '../../redux/reducers/modalSlice'
import RadioBtn from '../Input/RadioBtn'
import { getRoomName } from '../../utils/matrix'
import { truncateString } from '../../utils/string'

import styles from './AddToChat.module.scss'

const AddToChat = ({ isGroupChat }) => {
  const [selectedContacts, setSelectedContacts] = useState({})
  const [roomName, setRoomName] = useState('')
  const contacts = useSelector(selectContacts)
  const user = useSelector(selectCurrentUser)
  const [createRoom, { isLoading, error }] = useCreateRoomMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const contactsMap = useSelector(selectContactsMap)

  const airtouchContacts = useMemo(() => contacts.filter((contact) => contact?.osc), [contacts])

  const handleCheckboxChange = (contact) => {
    let checkedContacts = {}
    if (isGroupChat) {
      checkedContacts = { ...selectedContacts }
      if (!checkedContacts[contact.matrix.matrixId]) {
        checkedContacts[contact.matrix.matrixId] = contact
      } else {
        delete checkedContacts[contact.matrix.matrixId]
      }
    } else {
      checkedContacts[contact.matrix.matrixId] = contact
    }
    setSelectedContacts(checkedContacts)
  }

  const clearData = () => {
    setSelectedContacts({})
  }

  const handleCreateRoom = async () => {
    const selectedContactIds = Object.keys(selectedContacts)
    if (!Array.isArray(selectedContactIds)) return
    const res = await createRoom({
      accountContactNumbers: selectedContactIds,
      roomName,
      id: user._id,
    })

    if (!res.error) {
      const roomName = await getRoomName(
        user?.matrix?.username,
        res.data.room_id,
        contactsMap,
        null,
        true,
      )
      navigate(`/chat/${res.data.room_id}`, { state: { roomName } })
      dispatch(closeModal())
    }
  }
  const handleRoomNameChange = (e) => {
    setRoomName(e.target.value)
  }

  if (!airtouchContacts) return null
  console.log('this is airtouch contacts: ', { airtouchContacts })
  return (
    <div className={styles.addToChatWrapper}>
      <h3>Add to chat</h3>
      {error && <p className={styles.error}>{error?.message || 'Something went wrong'}</p>}
      {isGroupChat && (
        <div className={styles.roomName}>
          <Input
            className={styles.inputName}
            placeholder='Enter room name'
            value={roomName}
            onChange={handleRoomNameChange}
          />
          {isGroupChat && Object.keys(selectedContacts).length > 0 && !roomName && (
            <span className={styles.error}>Room name is required</span>
          )}
        </div>
      )}
      {airtouchContacts.map((contact) => {
        if (
          (user?.number && contact?.number === user.number) ||
          (user?.email && contact?.email === user.email)
        ) {
          return null
        }
        const contactName =
          contact?.name && contact?.name.startsWith('acc_') && contact?.displayName
            ? contact?.displayName
            : contact?.name && contact?.email && contact?.displayName
            ? contact?.displayName
            : contact?.name && contact?.email && !contact?.displayName
            ? contact?.email
            : contact?.name

        if (!contact?.matrix?.matrixId) return null

        return isGroupChat ? (
          <div key={contact.matrix.matrixId}>
            <a data-tooltip-id='add-to-chart-check' data-tooltip-content={contact.name}>
              <Checkbox
                label={truncateString(contactName)}
                checked={!!selectedContacts[contact.matrix.matrixId]}
                onChange={() => handleCheckboxChange(contact)}
              />
            </a>
            <Tooltip id='add-to-chart-check' />
          </div>
        ) : (
          <div key={contact.matrix.matrixId}>
            <a data-tooltip-id='add-to-chart-radio' data-tooltip-content={contact.name}>
              <RadioBtn
                label={truncateString(contactName)}
                value={contact.matrix.matrixId}
                checked={!!selectedContacts[contact.matrix.matrixId]}
                onChange={() => handleCheckboxChange(contact)}
              />
            </a>
            <Tooltip id='add-to-chart-radio' />
          </div>
        )
      })}
      <div
        className={styles.btnsWrapper}
        style={!isGroupChat ? { justifyContent: 'flex-end' } : {}}
      >
        {isGroupChat && (
          <Button
            disabled={isLoading}
            label='Clear all'
            className={styles.clear}
            onClick={clearData}
          />
        )}
        <Button
          disabled={
            isLoading ||
            !Object.keys(selectedContacts).length ||
            (isGroupChat && Object.keys(selectedContacts).length < 2) ||
            (isGroupChat && !roomName)
          }
          label='Add to chat'
          className={styles.add}
          onClick={handleCreateRoom}
        />
      </div>
    </div>
  )
}

AddToChat.propTypes = {
  isGroupChat: PropTypes.bool,
}

export default AddToChat
