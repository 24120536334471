// import initMatrix from '../lib/initMatrix'

// /**
//  *
//  * @param {String} roomId
//  * @param {String} eventId
//  * @returns
//  */
// export const deleteMsg = async (roomId, eventId) => {
//   try {
//     if (!eventId) return false
//     initMatrix.client?.redactEvent(roomId, eventId)
//   } catch (err) {
//     console.log('This is error redecting msg', err)
//   }
// }

// export function getRelateToId(mEvent) {
//   const relation = mEvent.getRelation()
//   return relation && relation.event_id
// }

// export const filterMessages = (messages, refMap) => {
//   const filtered = []
//   if (!messages || !Array.isArray(messages)) return filtered
//   messages?.timeline?.forEach((event) => {
//     const rel = getRelateToId(event)
//     if (rel) {
//       if (!refMap.get(rel)) {
//         refMap.set(rel, [event])
//       } else {
//         refMap.get(rel).push(event)
//       }
//     }
//     if (event.getType() === 'm.room.message') filtered.push(event)
//   })
//   return filtered
// }

// export const filterEvents = (events, editedEventsMap) => {
//   const filtered = []
//   if (!events || !Array.isArray(events)) return filtered
//   events.forEach((event) => {
//     // const rel = getRelateToId(event)
//     // if (rel) {
//     //   if (!refMap.get(rel)) {
//     //     refMap.set(rel, [event])
//     //   } else {
//     //     refMap.get(rel).push(event)
//     //   }
//     // }
//     if (isEdited(event)) addToMap(event, editedEventsMap)
//     if (event.getType() === 'm.room.message' && !isEdited(event)) filtered.push(event)
//   })
//   return filtered
// }

// // const isEdited = (event) => {
// //   return event.getRelation()?.rel_type === 'm.replace'
// // }

// function addToMap(event, refMap) {
//   const relateToId = getRelateToId(event)
//   if (relateToId === null) return null
//   const eventId = event.getId()

//   if (typeof refMap.get(relateToId) === 'undefined') refMap.set(relateToId, [])
//   const mEvents = refMap.get(relateToId)
//   if (mEvents.find((ev) => ev.getId() === eventId)) return event
//   mEvents.push(event)
// }

// const isEdited = (event) => {
//   if (event?.event?.content?.['m.relates_to']?.['rel_type'] === 'm.replace') return true
//   return false
// }

import initMatrix from '../lib/initMatrix'
import { CUSTOM_PRESENCE_TYP_MSGS } from '../constants/matrixConst'
import { matrixServerName } from '../environment'

/**
 *
 * @param {String} roomId
 * @param {String} eventId
 * @returns
 */
export const deleteMsg = async (roomId, eventId) => {
  try {
    if (!eventId) return false
    await initMatrix.client?.redactEvent(roomId, eventId)
  } catch (err) {
    console.log('This is error redecting msg', err)
  }
}

export function getRelateToId(event) {
  const relation = event?.content?.['m.relates_to']
  return relation && relation.event_id
}

export const filterMessages = (messages, refMap) => {
  const filtered = []
  if (!messages || !Array.isArray(messages)) return filtered
  messages?.timeline?.forEach((event) => {
    const rel = getRelateToId(event)
    if (rel) {
      if (!refMap.get(rel)) {
        refMap.set(rel, [event])
      } else {
        refMap.get(rel).push(event)
      }
    }
    if (event.getType() === 'm.room.message') filtered.push(event)
  })
  return filtered
}

export const filterEvents = (events, editedEventsMap) => {
  const filtered = []
  if (!events || !Array.isArray(events)) return filtered
  events.reverse().forEach((event) => {
    // const rel = getRelateToId(event)
    // if (rel) {
    //   if (!refMap.get(rel)) {
    //     refMap.set(rel, [event])
    //   } else {
    //     refMap.get(rel).push(event)
    //   }
    // }
    if (isEdited(event)) addToMap(event, editedEventsMap)
    if (event.type === 'm.room.message' && !isEdited(event)) filtered.push(event)
  })
  return filtered
}

// const isEdited = (event) => {
//   return event.getRelation()?.rel_type === 'm.replace'
// }

export function addToMap(event, refMap) {
  const relateToId = getRelateToId(event)
  if (relateToId === null) return null
  const eventId = event.event_id
  event.updateAt = new Date().getTime()
  if (typeof refMap.get(relateToId) === 'undefined') refMap.set(relateToId, [])
  let mEvents = refMap.get(relateToId)
  if (mEvents.find((ev) => ev.event_id === eventId)) return event
  mEvents.push(event)
  mEvents = mEvents.sort((a, b) => (a.origin_server_ts > b.origin_server_ts ? 1 : -1))
}

export const isEdited = (event) => {
  if (event?.content?.['m.relates_to']?.['rel_type'] === 'm.replace') return true
  return false
}

export const hasTwoMembers = (joined) => {
  return Object.keys(joined)?.length === 2
}

export const getRoomName = async (
  username,
  roomId,
  contactsMap,
  setRoomName,
  forceGetMembers = false,
) => {
  try {
    let members
    let roomName = ''
    const roomMember = initMatrix.roomMembers.get(roomId)

    if (!roomMember || forceGetMembers) {
      const res = await initMatrix.client.getJoinedRoomMembers(roomId)
      if (Object.keys(res.joined).length < 2) {
        const room = initMatrix?.client?.getRoom(roomId)
        const roomState = room?.currentState?.members
        members = roomState
      } else {
        members = res.joined
      }
    } else {
      members = roomMember
    }
    if (members && hasTwoMembers(members)) {
      for (const key in members) {
        const usernameFromMatrixId = key.split(`:${matrixServerName}`)[0].slice(1)
        if (usernameFromMatrixId === username) continue
        let displayNameFromMatrix = members[key]?.display_name
        if (usernameFromMatrixId !== username) {
          const usernameFromMatrixIdWithPhonePrefix = usernameFromMatrixId.split('acc_')[1]
          const contactFromPhoneBook =
            contactsMap[`+${usernameFromMatrixIdWithPhonePrefix}`] ||
            contactsMap[usernameFromMatrixIdWithPhonePrefix]
          if (displayNameFromMatrix) {
            if (contactFromPhoneBook?.name) {
              if (!contactFromPhoneBook?.name.startsWith('acc_')) {
                roomName = contactFromPhoneBook?.name
              } else if (contactFromPhoneBook?.displayName) {
                roomName = contactFromPhoneBook?.displayName
              } else if (!contactFromPhoneBook?.displayName) {
                roomName = displayNameFromMatrix
              } else {
                roomName = contactFromPhoneBook?.name
              }
            } else {
              roomName = displayNameFromMatrix
            }
          } else {
            if (contactFromPhoneBook?.name) {
              if (!contactFromPhoneBook?.name.startsWith('acc_')) {
                roomName = contactFromPhoneBook?.name
              } else if (contactFromPhoneBook?.displayName) {
                roomName = contactFromPhoneBook?.displayName
              }
            }
          }
        }
      }
    } else {
      const room = initMatrix?.client?.getRoom(roomId)
      if (room?.name) roomName = room.name
    }
    if (setRoomName && typeof setRoomName === 'function') setRoomName(roomName)
    return roomName
  } catch (err) {
    console.log('This is error get joined room mebers: ', err)
  }
}

export const getPresence = (allMembers = {}, membersOfTheRoom) => {
  let presence = CUSTOM_PRESENCE_TYP_MSGS.offline.value
  if (Object.keys(membersOfTheRoom).length === 2) {
    for (const key in membersOfTheRoom) {
      if (allMembers[key] && allMembers[key].presence && key !== initMatrix.client?.getUserId()) {
        const status = allMembers[key].presence
        presence = CUSTOM_PRESENCE_TYP_MSGS[status]?.value || presence
      }
    }
  }
  return presence
}
