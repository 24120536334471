import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from '../../services/localStorage'

const matrixSlice = createSlice({
  name: 'matrix',
  initialState: {
    isLoading: true,
    allMembers: {},
    presence: localStorageService.get('presence') || 'online',
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setAllMembers: (state, action) => {
      state.allMembers = action.payload
    },
    setPresence: (state, action) => {
      state.presence = action.payload
    },
  },
})

export const { setIsLoading, setAllMembers, setPresence } = matrixSlice.actions

export default matrixSlice.reducer

export const selectIsLoadingMatrix = (state) => state.matrix.isLoading
export const selectAllMembers = (state) => state.matrix.allMembers
export const selectPresence = (state) => state.matrix.presence
