import PropTypes from 'prop-types'

import styles from './MessageBox.module.scss'

const MessageBox = ({ sender, message, position, date }) => {
  return (
    <div
      style={
        position === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }
      }
      className={styles.msgBoxWrapper}
    >
      <div className={styles[position]}>
        <div className={styles.sender}>{sender}</div>
        <div className={styles.message}>{message}</div>
        <div className={styles.date}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  )
}

MessageBox.propTypes = {
  sender: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default MessageBox
