export const MSG_TYPES = {
  text: 'm.text',
  emote: 'm.emote',
  notice: 'm.notice',
  image: 'm.image',
  file: 'm.file',
  audio: 'm.audio',
  location: 'm.location',
  video: 'm.video',
  keyVerificationRequest: 'm.key.verification.request',
}

export const PRESENCE_TYPES = {
  online: 'online',
}

export const CUSTOM_PRESENCE_TYP_MSGS = {
  online: {
    value: 'online',
    name: 'Online',
  },
  offline: {
    value: 'offline',
    name: 'Offline',
  },
  busy: {
    value: 'busy',
    name: 'Busy',
  },
  dnd: {
    value: 'dnd',
    name: 'Do not disturb',
  },
}

export const CUSTOM_EVENT_TYPES = {
  newRoomCreated: 'new-room-created',
  incommingCall: 'incommingCall',
  rejectCall: 'rejectCall',
  cancelCall: 'cancelCall',
  disconnectCall: 'disconnectCall',
  muteCall: 'muteCall',
  unmuteCall: 'unmuteCall',
  call_ended: 'call_ended',
  answered_call: 'answered_call',
  socketResponse: 'socket_response',
  put_on_hold: 'put_on_hold',
  active_in_conference: 'active_in_conference',
}
