import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { BsLink } from 'react-icons/bs'
import {
  MdCallEnd,
  MdClose,
  MdLocalPhone,
  MdMic,
  MdMicOff,
  MdOutlineInfo,
  MdScreenShare,
  MdVideocam,
  MdVideocamOff,
} from 'react-icons/md'
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import Video, { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video'
import {
  useLazyGetGuestVideoTokenQuery,
  useLazyGetShareVideoLinkQuery,
  useRoomIdMutation,
  useVideoCallMutation,
  useVideoTokenMutation,
} from '../../api/authApiSlice'
import { selectCurrentUser } from '../../redux/reducers/authSlice'

import styles from './TwilioVideo4.module.scss'

import { segmentAnalytics } from '../../App'
import ringing from '../../assets/audio/ringing.mp3'
import avatar from '../../assets/images/defaultAvatar.svg'
import { adminPanelUrl, isProduction } from '../../environment'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { selectContactsMap } from '../../redux/reducers/contactsSlice'
import toastService from '../../services/toastService'
import { getRoomName } from '../../utils/matrix'
import InfoVideo from './InfoVideo'
import RemoteParticipant from './RemoteParticipant2'
import { setIsInVideoCall } from '../../redux/reducers/callSlice'

const getVideoToken = async (user, state, videoCall, generateVideoToken, getRoomId) => {
  if (!state?.localCalling && (state?.contact?.chatRoom?.room_id || state?.roomId)) {
    const videoCallResponse = await videoCall({
      accountId: user?._id,
      room_id: state?.roomId,
      audioOnly: state?.audioOnly ? state.audioOnly : false,
    })
    if (videoCallResponse?.data?.roomName) {
      const videToken = await generateVideoToken({
        accountId: user?._id,
        roomName: videoCallResponse?.data?.roomName,
      })
      return {
        token: videToken?.data?.token,
        roomId: state?.roomId,
        group: videToken?.data?.group,
      }
    }
  } else {
    const response = await getRoomId({
      accountId: user?._id,
      contactNumber: state?.contact?.matrix?.matrixId,
    })
    if (response?.data?.room_id) {
      const videoCallResponse = await videoCall({
        accountId: user?._id,
        room_id: response?.data?.room_id,
      })
      if (videoCallResponse?.data?.roomName) {
        const videToken = await generateVideoToken({
          accountId: user?._id,
          roomName: videoCallResponse?.data?.roomName,
        })
        return {
          token: videToken?.data?.token,
          roomId: response?.data?.room_id,
          group: videToken?.data?.group,
        }
      }
    }
  }
}

const TwilioVideo = () => {
  const [videoCall] = useVideoCallMutation()
  const [getRoomId] = useRoomIdMutation()
  const [generateVideoToken] = useVideoTokenMutation()
  const [getShareVideoLink, { data: videoLinkShare, error: videoLinkShareError }] =
    useLazyGetShareVideoLinkQuery()
  const [getGuestVideoToken] = useLazyGetGuestVideoTokenQuery()

  const location = useLocation()
  const { state } = location
  const contactsMap = useSelector(selectContactsMap)
  const [roomName, setRoomName] = useState('')
  const [localVideoTrack, setLocalVideoTrack] = useState(null)
  const [localAudioTrack, setLocalAudioTrack] = useState(null)
  const [remoteParticipants, setRemoteParticipants] = useState([])
  const [isAnswered, setIsAnswered] = useState(false)
  const [answerNotification, setAnswerNotification] = useState(false)
  const [isCallStarted, setIsCallStarted] = useState(false)
  const [participantsSize, setParticipantSize] = useState(0)
  const [show, setShow] = useState(false)
  const [isRejoined, setIsRejoined] = useState(false)
  const [roomId, setRoomId] = useState('')
  const [isOpenShare, setOpenShare] = useState(false)
  const [isGroupCall, setIsGroupCall] = useState(false)

  const [push, setPush] = useState(state?.push)

  const { isMobile } = useWindowDimensions()

  const navigate = useNavigate()
  const params = useParams()

  const localVideoRef = useRef(null)
  const timeoutRef = useRef(null)
  const localParticipantRef = useRef(true)

  const micRef = useRef(null)
  const micOffRef = useRef(null)
  const camRef = useRef(null)
  const camOffRef = useRef(null)
  const chatVideoCallRef = useRef(false)
  const audioRef = useRef(null)
  const roomRef = useRef(null)
  const showInfoRef = useRef(null)

  const startTimeRef = useRef(true)
  const durationRef = useRef(0)

  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (remoteParticipants.length > 0 && audioRef.current) {
      audioRef.current?.pause()
      audioRef.current.currentTime = 0
    }
  }, [remoteParticipants.length])

  useEffect(() => {
    dispatch(setIsInVideoCall(true))
    return () => {
      dispatch(setIsInVideoCall(false))
    }
  }, [dispatch])

  useEffect(() => {
    const fetchRoomName = async (matrixId, roomId, contactsMap, stateFn) => {
      if (!matrixId || !roomId || !contactsMap) return
      await getRoomName(matrixId, roomId, contactsMap, stateFn, true)
    }
    if (state?.roomName)
      fetchRoomName(user?.matrix?.username, state.roomName, contactsMap, setRoomName)
  }, [contactsMap, state?.roomName, user?.matrix?.username])

  useEffect(() => {
    if (!roomRef.current && !localVideoTrack) return
    const handleParticipantConnected = (participant) => {
      const existsParticipant = remoteParticipants.find((p) => p.sid === participant.sid)
      if (!existsParticipant) {
        const newParticipants = [...remoteParticipants, participant]
        setRemoteParticipants(newParticipants)
        setParticipantSize(newParticipants.length)
        setShow(!show)
      } else {
        setRemoteParticipants((prev) => (prev.sid === participant.sid ? participant : prev))
      }
    }
    const participantDisconnected = (participant) => {
      const filteredParticipants = remoteParticipants.filter(
        (p) => p?.identity !== participant?.identity,
      )
      setRemoteParticipants(filteredParticipants)
      setParticipantSize(filteredParticipants.length)
      // if (!filteredParticipants.length) hangup()
    }

    const handleTruckSubscribed = (track, _, participant) => {
      const newParticipants = [...remoteParticipants].map((p) => {
        if (p?.sid === participant?.sid) {
          return participant
          // const key = p.videoTracks.keys()[0]
          // if (key) p.videoTracks.set(key, track)
          // return { ...p, videoTrack: track }
        }
        return p
      })
      setRemoteParticipants(newParticipants)
      setShow(!show)
    }

    const handleTruckUnsubscribed = () => {
      setShow(!show)
    }

    roomRef.current?.on('trackSubscribed', handleTruckSubscribed)
    roomRef.current?.on('participantConnected', handleParticipantConnected)
    roomRef.current?.on('participantDisconnected', participantDisconnected)
    roomRef.current?.on('trackUnsubscribed', handleTruckUnsubscribed)

    roomRef.current?.participants?.forEach(handleParticipantConnected)
    return () => {
      roomRef.current?.off('trackSubscribed', handleTruckSubscribed)
      roomRef.current?.off('participantConnected', handleParticipantConnected)
      roomRef.current?.off('participantDisconnected', participantDisconnected)
      roomRef.current?.off('trackUnsubscribed', handleTruckUnsubscribed)
    }
  }, [localVideoTrack, remoteParticipants, isAnswered, show])

  useEffect(() => {
    if (participantsSize !== remoteParticipants.length && roomRef.current?.participants) {
      const participants = [...Array.from(roomRef.current.participants.values())].map(
        (participant) => participant,
      )
      setRemoteParticipants(participants)
    }
  }, [participantsSize, remoteParticipants.length])

  useEffect(() => {
    if (!answerNotification && !isCallStarted && push) {
      timeoutRef.current = setTimeout(() => {
        hangup()
      }, 30000)
    } else {
      clearTimeout(timeoutRef.current)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [answerNotification, isCallStarted, push])

  useEffect(() => {
    if (isRejoined && !participantsSize) hangup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRejoined, participantsSize])

  const handleVideo = async () => {
    if (localVideoTrack) return
    let token, roomId
    if (params?.code) {
      const { data, isError } = await getGuestVideoToken(params.code)
      if (data && !isError) {
        token = data.token
        roomId = data.roomName
      }
    } else {
      const res = await getVideoToken(user, state, videoCall, generateVideoToken, getRoomId)
      token = res.token
      roomId = res.roomId
      setIsGroupCall(res?.group)
    }
    setRoomId(roomId)
    if (token && !roomRef.current) {
      try {
        connect(token, {
          video: true,
          audio: true,
          name: roomId,
          dominantSpeaker: true,
        }).then(async (room) => {
          setParticipantSize(room.participants.size)
          setIsAnswered(true)
          const track = await createLocalVideoTrack({ name: user?.displayName || 'Unknown' })
          const audio = await createLocalAudioTrack()
          if (state?.audioOnly) {
            room.localParticipant.videoTracks.forEach((track) => {
              track.track.disable()
            })
            track.disable()
          }
          roomRef.current = room
          setLocalVideoTrack(track)
          setLocalAudioTrack(audio)
          startTimeRef.current = false
          if (room.participants.size) setIsRejoined(true)
          setTimeout(() => {
            setShow(!show)
          }, 3000)
          if (isProduction) {
            segmentAnalytics.track('Start video call', { username: user?.endpoint?.username })
          }
        })
      } catch (error) {
        console.error('Error connecting to Twilio:', error)
      }
    }
  }

  useEffect(() => {
    if ((localParticipantRef.current && state?.localCalling) || state?.roomId || params?.code) {
      if (!chatVideoCallRef.current) handleVideo()
      chatVideoCallRef.current = true
      setPush(false)
    }
    return () => {
      localParticipantRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (localVideoTrack && localVideoRef.current) {
      localVideoRef.current.srcObject = localVideoTrack?.mediaStreamTrack
        ? new MediaStream([localVideoTrack.mediaStreamTrack])
        : null
    }
  }, [localVideoTrack, localVideoTrack?.isEnabled])

  const hangup = () => {
    if (roomRef.current) {
      navigator?.mediaDevices?.getUserMedia({ audio: true, video: true }).then((stream) => {
        roomRef.current.disconnect()

        roomRef.current?.localParticipant?.tracks?.forEach((track) => track?.stop())
        roomRef.current?.participants?.tracks?.forEach((track) => track?.stop())
        roomRef.current = null
        setRemoteParticipants([])
        setLocalVideoTrack(null)
        setLocalAudioTrack(null)
        if (localVideoTrack) {
          localVideoTrack.stop()
          localVideoTrack.detach()
        }
        if (localAudioTrack) {
          localAudioTrack.stop()
          localAudioTrack.detach()
        }

        if (remoteParticipants?.tracks) {
          remoteParticipants.tracks.forEach((track) => track.stop())
        }

        stream?.getVideoTracks()?.forEach((track) => track?.stop())
        stream?.getAudioTracks()?.forEach((track) => track?.stop())
        navigate('/')
      })
    } else {
      navigate('/')
    }
  }

  const micOff = () => {
    if (localAudioTrack) {
      roomRef.current?.localParticipant?.audioTracks.forEach((track) => {
        track.track.disable()
      })
    }

    if (micRef.current) {
      micRef.current.style.display = 'flex'
    }
    if (micOffRef.current) {
      micOffRef.current.style.display = 'none'
    }
  }

  const micOn = () => {
    if (localAudioTrack) {
      roomRef.current?.localParticipant?.audioTracks.forEach((track) => {
        track?.track?.enable()
      })
      if (micRef.current) {
        micRef.current.style.display = 'none'
      }
      if (micOffRef.current) {
        micOffRef.current.style.display = 'flex'
      }
    }
  }

  const camOff = async () => {
    if (!localVideoTrack) return

    roomRef.current.localParticipant.videoTracks.forEach((track) => {
      if (track?.trackName !== 'screenShare') track?.track?.disable()
      // COMENTED THIS LINE BECAUSE THE ISSUE ON THE MOBILE APP, THEY DO NOT SEE REMOTE PARTICIPANTS WHEN YOU JUST DISABLE A TRACK
      // track.track.detach()
      // track.track.stop()
      // track.unpublish(track)
    })
    setLocalVideoTrack((prev) => {
      prev?.disable()
      // COMENTED THIS LINE BECAUSE THE ISSUE ON THE MOBILE APP, THEY DO NOT SEE REMOTE PARTICIPANTS WHEN YOU JUST DISABLE A TRACK
      // prev.detach()
      // prev.stop()
      return prev
    })

    if (camRef.current) {
      camRef.current.style.display = 'block'
    }
    if (camOffRef.current) {
      camOffRef.current.style.display = 'none'
    }
  }

  const camOn = async () => {
    if (!localAudioTrack) return

    roomRef.current.localParticipant.videoTracks.forEach((track) => {
      track?.track?.enable()
    })

    setLocalVideoTrack((prev) => prev?.enable())

    // COMENTED THIS LINE BECAUSE THE ISSUE ON THE MOBILE APP, THEY DO NOT SEE REMOTE PARTICIPANTS WHEN YOU JUST DISABLE A TRACK
    // const track = await createLocalVideoTrack()
    // roomRef.current.localParticipant.publishTrack(track)
    // setLocalVideoTrack(track)

    if (camRef.current) {
      camRef.current.style.display = 'none'
    }
    if (camOffRef.current) {
      camOffRef.current.style.display = 'block'
    }
  }

  const handleIncomingCall = async () => {
    if (localVideoTrack) return
    const videoTokenResponse = await generateVideoToken({
      accountId: user?._id,
      roomName: state?.roomName,
    })
    const token = videoTokenResponse?.data?.token
    if (token) {
      try {
        setIsGroupCall(videoTokenResponse?.data?.group)
        const room = await connect(token, {
          video: true,
          audio: true,
          name: state?.roomName,
          dominantSpeaker: true,
        })
        setRoomId(room?.name)
        setParticipantSize(room.participants.size)
        const track = await createLocalVideoTrack()
        const audio = await createLocalAudioTrack()
        if (state?.audioOnly) {
          room.localParticipant.videoTracks.forEach((track) => {
            track.track.disable()
          })
          track.disable()
        }
        roomRef.current = room
        setLocalVideoTrack(track)
        setLocalAudioTrack(audio)
        setTimeout(() => {
          setShow(!show)
        }, 2000)
        setPush(false)
        setIsAnswered(true)
        setAnswerNotification(true)
        setIsCallStarted(true)
        clearTimeout(timeoutRef.current)
      } catch (error) {
        console.error('Error connecting to Twilio:', error)
      }
    }
  }

  const [localTrackSize, setLocalTrackSize] = useState(0)

  useEffect(() => {
    const videoTracks = Array.from(roomRef.current?.localParticipant?.videoTracks?.values() || [])

    const isSharingLocalparticipant = videoTracks.find((track) => track.trackName === 'screenShare')
    // if (localVideoTrack?.name === 'screenShare') {
    if (isSharingLocalparticipant) {
      const stopShareScreen = async (track) => {
        // localVideoTrack?.track?.stop()
        // const screenTrack = await createLocalVideoTrack()
        // screenTrack?.track?.enable()
        // await roomRef.current?.localParticipant.unpublishTrack(localVideoTrack)
        // roomRef.current?.localParticipant?.videoTracks?.forEach((track) => {
        //   track.track.stop()
        //   track?.unpublish()
        // })
        // setLocalVideoTrack(null)
        // await roomRef.current?.localParticipant?.publishTrack(screenTrack)
        // setLocalVideoTrack(screenTrack)

        // localVideoTrack?.track?.stop()
        // const screenTrack = await createLocalVideoTrack()
        isSharingLocalparticipant.unpublish(track)
        await roomRef.current?.localParticipant?.unpublishTrack(track)
        roomRef.current?.localParticipant?.videoTracks?.forEach((track) => {
          if (track.trackName === 'screenShare') {
            track.track.stop()
            track?.unpublish()
          }
        })
        setShow(!show)
        setLocalTrackSize(roomRef.current?.localParticipant?.videoTracks?.size)
        // setLocalVideoTrack(null)
        // await roomRef.current?.localParticipant?.publishTrack(screenTrack)
        // setLocalVideoTrack(screenTrack)
      }

      isSharingLocalparticipant?.track?.on('stopped', stopShareScreen)
      return () => isSharingLocalparticipant.track?.off('stopped', stopShareScreen)
      // localVideoTrack?.on('stopped', stopShareScreen)
      // return () => localVideoTrack?.off('stopped', stopShareScreen)
    }
  }, [localVideoTrack, localVideoTrack?.name, localTrackSize, show])

  const shareScreenHandler = async () => {
    if (!roomRef.current?.localParticipant) return
    const videoTracks = Array.from(roomRef.current?.localParticipant?.videoTracks?.values() || [])

    const isSharingLocalParticipant = videoTracks.find((track) => track.trackName === 'screenShare')
    // if (localVideoTrack.name !== 'screenShare') {
    if (!isSharingLocalParticipant) {
      navigator.mediaDevices
        .getDisplayMedia()
        .then(async (stream) => {
          const screenTrack = new Video.LocalVideoTrack(stream.getVideoTracks()[0], {
            name: 'screenShare',
          })

          // roomRef.current.localParticipant.videoTracks.forEach((track) => {
          //   track?.unpublish()
          //   track?.track.stop()
          // })
          // await roomRef.current.localParticipant.unpublishTrack(localVideoTrack)
          // localVideoTrack.stop()

          // await roomRef.current.localParticipant.publishTrack(screenTrack)
          // setLocalVideoTrack(screenTrack)

          // roomRef.current.localParticipant.videoTracks.forEach((track) => {
          //   track?.unpublish()
          //   track?.track.stop()
          // })
          // await roomRef.current.localParticipant.unpublishTrack(localVideoTrack)
          // localVideoTrack.stop()

          await roomRef.current.localParticipant.publishTrack(screenTrack)
          setLocalTrackSize(roomRef.current?.localParticipant?.videoTracks?.size)
          // setLocalVideoTrack(screenTrack)
        })
        .catch(() => {})
    }
  }

  useEffect(() => {
    const getShareLink = async () => {
      if (!user?._id || !roomId) return
      getShareVideoLink({ accountId: user._id, roomId })
      if (videoLinkShareError) {
        toastService.show('error', 'Error get share link')
        return
      }
    }
    getShareLink()
  }, [getShareVideoLink, roomId, user?._id, videoLinkShareError])

  let isSharingRemoteParticipant = null
  remoteParticipants.forEach((participant) =>
    participant?.videoTracks?.forEach((track) => {
      if (track?.trackName === 'screenShare') isSharingRemoteParticipant = track
    }),
  )

  // let isLocalParticipantSharing = !!(localVideoTrack?.name === 'screenShare')
  let isLocalParticipantSharing = null
  roomRef.current?.localParticipant?.videoTracks?.forEach((track) => {
    if (track?.trackName === 'screenShare') isLocalParticipantSharing = track
  })

  const someoneSharing = isLocalParticipantSharing || isSharingRemoteParticipant

  const toggleInfoDiv = () => {
    if (showInfoRef.current) {
      if (
        showInfoRef.current.style.display === 'none' ||
        showInfoRef.current.style.display === ''
      ) {
        showInfoRef.current.style.display = 'block'
      } else {
        showInfoRef.current.style.display = 'none'
      }
    }
  }

  return (
    <div className={styles.twilio}>
      {state?.title === 'Incoming Video Call' && push && (
        <div className={styles.notificationWrapper}>
          {!participantsSize && !isAnswered && <audio src={ringing} autoPlay loop />}
          <img src={avatar} alt='' />
          <span>
            <em>Incoming call...</em>
          </span>
          <h3>{roomName || state?.caller}</h3>
          <div className={styles.notifBtn}>
            {state?.audioOnly ? (
              <MdLocalPhone onClick={handleIncomingCall} className={styles.call} />
            ) : (
              <MdVideocam
                onClick={handleIncomingCall}
                ref={camRef}
                className={styles.incomingCall}
              />
            )}
            {!isLocalParticipantSharing && !isAnswered && (
              <MdCallEnd className={styles.incomingCallEnd} onClick={hangup} />
            )}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div
          className={`${styles.videoWrapper} ${
            isLocalParticipantSharing?.track || isSharingRemoteParticipant?.track
              ? styles.sharingScreen
              : ''
          }`}
          style={isMobile ? { flexWrap: 'wrap' } : {}}
        >
          <div
            className={styles.rcp}
            style={
              isMobile
                ? {
                    alignContent: 'center',
                    overflowY: 'hidden',
                    width: '100%',
                    maxHeight: someoneSharing ? '65%' : '100%',
                  }
                : {}
            }
          >
            {remoteParticipants.map((participant, index) => {
              let trackName = ''
              participant?.videoTracks?.forEach((track) => {
                trackName = track.trackName
              })
              if (!participant) return null
              return (
                <React.Fragment key={participant?.sid + trackName}>
                  <RemoteParticipant
                    room={roomRef.current}
                    participant={participant}
                    key={participant?.sid + trackName}
                    id={participant?.sid}
                    trackName={trackName}
                    numberOfParticipants={remoteParticipants.length}
                    isMobileAndHaveMoreParticipants={
                      remoteParticipants.length > 4 && isMobile && index > 4
                    }
                    style={{
                      // height: isMobile ? '30%' : someoneSharing ? '18%' : `${height}%`,
                      height: isMobile
                        ? '18%'
                        : someoneSharing
                        ? '18%'
                        : remoteParticipants.length > 4
                        ? '20%'
                        : '35%',
                      // width: isMobile ? '47%' : someoneSharing ? '47%' : `${width}%`,
                      width: isMobile
                        ? '27%'
                        : someoneSharing
                        ? '47%'
                        : remoteParticipants.length > 4
                        ? '28%'
                        : '47%',
                    }}
                  />
                </React.Fragment>
              )
            })}
            {localVideoTrack && (
              <div
                className={styles.videoContainer}
                style={{
                  // height: isMobile ? '30%' : someoneSharing ? '18%' : `${height}%`,
                  height: isMobile
                    ? '18%'
                    : someoneSharing
                    ? '18%'
                    : remoteParticipants.length > 4
                    ? '20%'
                    : '35%',
                  // width: someoneSharing ? '48%' : `${width}%`,
                  width: isMobile
                    ? '27%'
                    : someoneSharing
                    ? '47%'
                    : remoteParticipants.length > 4
                    ? '28%'
                    : '47%',
                  order: 11,
                }}
              >
                {!participantsSize && !isAnswered && (
                  <audio src={ringing} autoPlay loop ref={audioRef} />
                )}
                <video
                  className={styles.video}
                  ref={localVideoRef}
                  autoPlay
                  id='localVideo'
                  muted
                />
              </div>
            )}
          </div>
          {videoLinkShare?.inviteLink && isOpenShare && isGroupCall && (
            <div className={styles.shareBtns}>
              <ViberShareButton
                title='Meeting'
                url={`You have been invited to a video call on Airtouch. To join the meeting, click on this link here: ${videoLinkShare?.inviteLink}`}
              >
                <ViberIcon size={30} round={true} />
              </ViberShareButton>
              <WhatsappShareButton
                title='Meeting'
                url={`You have been invited to a video call on Airtouch. To join the meeting, click on this link here: ${videoLinkShare?.inviteLink}`}
              >
                <WhatsappIcon size={30} round={true} />
              </WhatsappShareButton>
              <TelegramShareButton
                title='Meeting'
                url={`You have been invited to a video call on Airtouch. To join the meeting, click on this link here: ${videoLinkShare?.inviteLink}`}
              >
                <TelegramIcon size={30} round={true} />
              </TelegramShareButton>
              <EmailShareButton
                openShareDialogOnClick
                url={videoLinkShare?.inviteLink}
                subject='Meeting'
                separator='To join the meeting: '
                body={`You have been invited to a video call on Airtouch. To join the meeting, click on this link here: ${
                  videoLinkShare?.inviteLink
                }

To create your own Airtouch account for your business or organization, click on this link here: ${
                  adminPanelUrl + '/register'
                }\n`}
                onClick={(e) => e.preventDefault()}
              >
                <EmailIcon size={30} round={true} />
              </EmailShareButton>
              <MdClose
                style={{ color: 'white', cursor: 'pointer', marginLeft: '0.5rem' }}
                onClick={() => setOpenShare(false)}
              />
            </div>
          )}
          <div className={styles.btns}>
            {localVideoTrack && !isLocalParticipantSharing && (
              <button onClick={hangup} className={styles.endCall}>
                <MdCallEnd />
              </button>
            )}

            {localAudioTrack && (
              <>
                <button onClick={micOff} ref={micOffRef} className={` ${styles.showMic} `}>
                  <MdMicOff />
                </button>
                <button onClick={micOn} ref={micRef} className={`${styles.hideMic} `}>
                  <MdMic />
                </button>
                {!isLocalParticipantSharing && !isSharingRemoteParticipant && (
                  <button className={styles.shareScreen} onClick={shareScreenHandler}>
                    <MdScreenShare />
                  </button>
                )}
              </>
            )}
            {localVideoTrack && (
              <>
                <button
                  onClick={camOff}
                  ref={camOffRef}
                  className={` ${state?.audioOnly ? styles.showMicOnlyAudio : styles.showCam} `}
                >
                  <MdVideocamOff />
                </button>
                <button
                  onClick={camOn}
                  ref={camRef}
                  className={`${state?.audioOnly ? styles.hideMicOnlyAudio : styles.hideCam} `}
                >
                  <MdVideocam />
                </button>
                {videoLinkShare?.inviteLink && isGroupCall && (
                  <>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(videoLinkShare?.inviteLink)
                        toastService.show('success', `Copied meeting link`)
                        setOpenShare(true)
                      }}
                      className={` ${styles.shareLink} `}
                    >
                      <BsLink />
                    </button>
                  </>
                )}
              </>
            )}
            {localVideoTrack && (
              <button onClick={toggleInfoDiv} className={styles.showMic}>
                <MdOutlineInfo />
              </button>
            )}
          </div>
          <InfoVideo
            showInfoRef={showInfoRef}
            duration={durationRef.current}
            localVideoTrack={localVideoTrack}
            videoLinkShare={videoLinkShare}
            remoteParticipants={remoteParticipants}
          />
          {/* <div className={styles.lcp}>
            {localVideoTrack && (
              <div>
                {!participantsSize && !isAnswered && (
                  <audio src={ringing} autoPlay loop ref={audioRef} />
                )}
                <video
                  className={
                    remoteParticipants.length > 0 ? styles.videoLocal : styles.videoParticipant
                  }
                  ref={localVideoRef}
                  autoPlay
                  id='localVideo'
                  muted
                />
              </div>
            )}
          </div> */}
          {(isLocalParticipantSharing?.track || isSharingRemoteParticipant?.track) && (
            <div
              className={styles.sharingVideoContainer}
              style={isMobile ? { order: 1, height: '30%' } : {}}
            >
              <div className={styles.videoContainer}>
                <video
                  className={styles.video}
                  ref={(ref) => {
                    if (ref) {
                      const stream =
                        isLocalParticipantSharing?.track?.mediaStreamTrack ||
                        isSharingRemoteParticipant?.track?.mediaStreamTrack ||
                        null

                      ref.srcObject = new MediaStream([stream])
                    }
                  }}
                  autoPlay
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TwilioVideo
