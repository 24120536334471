import { Discuss } from 'react-loader-spinner'

import styles from './Loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div>
        <Discuss
          visible={true}
          height='50'
          width='50'
          ariaLabel='comment-loading'
          wrapperClass='comment-wrapper'
        />
      </div>
    </div>
  )
}

export default Loader
