import PropTypes from 'prop-types'
import { MdOutlineRestoreFromTrash } from 'react-icons/md'

import styles from './RemovedMessage.module.scss'

const RemovedMessage = ({ sender, position }) => {
  return (
    <div
      style={
        position === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }
      }
      className={styles.removedMessage}
    >
      <div className={styles[position]}>
        <MdOutlineRestoreFromTrash className={styles.icon} />
        <span>{sender} deleted a message</span>
      </div>
    </div>
  )
}

RemovedMessage.propTypes = {
  sender: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
}

export default RemovedMessage
