import { useState, useEffect } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { localStorageService } from '../services/localStorage'
import { selectCurrentId, selectCurrentUser } from '../redux/reducers/authSlice'
import { useGetByIdQuery } from '../api/authApiSlice'
import { useGetContactsQuery } from '../api/contactsApiSlice'
import WithoutPlan from '../pages/WithoutPlan/WithoutPlan'
import Loader from '../components/Loader/Loader'

const PrivateRoute = () => {
  const [skip, setSkip] = useState(true)
  const location = useLocation()
  const user = useSelector(selectCurrentUser)
  const id = useSelector(selectCurrentId)

  useGetByIdQuery(id, { skip })
  useGetContactsQuery(id, { skip })

  useEffect(() => {
    if (id && !user) setSkip(false)
    return () => setSkip(false)
  }, [id, user])
  if (localStorageService.isAuth()) {
    if (!user) {
      return <Loader />
    }
    const { activePackage } = user
    if (!activePackage || activePackage === '0') {
      return <WithoutPlan />
    }
    return <Outlet />
  } else {
    return <Navigate to='register' replace state={{ from: location }} />
  }
}

export default PrivateRoute
