import { api } from './index'

const smsMmmsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    sendSmsMms: builder.mutation({
      query: (data) => {
        return {
          url: `/twilio/${data.id}/sendSMS`,
          method: 'POST',
          body: {
            message: data.message,
            to: data.to,
            mediaUrl: data.mediaUrl,
            event_id: data.event_id,
            room_id: data.room_id,
          },
        }
      },
    }),
    sendGroupSmsMms: builder.mutation({
      query: (data) => {
        return {
          url: `/twilio/${data.id}/sendGroupSMS`,
          method: 'POST',
          body: {
            message: data.message,
            mediaUrl: data.mediaUrl,
            event_id: data.event_id,
            room_id: data.room_id,
          },
        }
      },
    }),
  }),
})
export const { useSendSmsMmsMutation, useSendGroupSmsMmsMutation } = smsMmmsApiSlice
