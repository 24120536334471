import { createSlice } from '@reduxjs/toolkit'

const extensionsSlice = createSlice({
  name: 'extensions',
  initialState: { extensions: [], selectedExtension: null },
  reducers: {
    setExtensions: (state, action) => {
      state.extensions = action.payload
    },
    setSelectedExtension: (state, action) => {
      state.selectedExtension = action.payload
    },
  },
})

export const { setExtensions, setSelectedExtension } = extensionsSlice.actions

export default extensionsSlice.reducer

export const selectExtensions = (state) => state.extensions
export const selectSelectedExtension = (state) => state.extensions.selectedExtension
