import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetCallsQuery } from '../../api/callsApiSlice'
import Call from './Call'

import styles from './Calls.module.scss'
import { selectCurrentId } from '../../redux/reducers/authSlice'
import { isSameDay, formatDate } from '../../utils/dateParsing'
import { localStorageService } from '../../services/localStorage'

const Calls = () => {
  const userId = useSelector(selectCurrentId)
  const [page, setPage] = useState(1)
  const [callHistory, setCallHistory] = useState([])

  const { data, isLoading, error, isFetching } = useGetCallsQuery(
    { accountId: userId, limit: 20, page },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    const activeTab = localStorageService.get('activeTab')
    if (activeTab !== '2') setCallHistory([])
  }, [])

  useEffect(() => {
    if (!isLoading && Array.isArray(data?.callHistory) && !error) {
      setCallHistory((prev) => [...prev, ...data.callHistory])
    }
  }, [data, error, isLoading])

  const observer = useRef(null)

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetching) return
      if (observer.current) observer.current.disconnect()
      const hasMore = callHistory.length < data?.total
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prev) => prev + 1)
          }
        },
        { threshold: 0.7 },
      )
      if (node) observer.current.observe(node)
    },
    [callHistory.length, data?.total, isFetching, isLoading],
  )
  if (!Array.isArray(callHistory) || !callHistory.length)
    return (
      <div className={styles.noData}>
        <span>Currently there are no recent calls</span>
      </div>
    )
  return (
    <div className={styles.callsWrapper}>
      {callHistory.map((call, index) => {
        let divider = null
        if (
          !callHistory?.[index - 1] ||
          !isSameDay(callHistory?.[index - 1]?.callStart, call.callStart)
        ) {
          divider = (
            <div className={styles.divider}>
              <span>{formatDate(call.callStart, 'D MMM YYYY')}</span>
            </div>
          )
        }
        if (index === callHistory.length - 1)
          return (
            <div key={call._id}>
              {divider}
              <Call ref={lastElementRef} call={call} />
            </div>
          )
        return (
          <div key={call._id}>
            {divider}
            <Call key={call._id} call={call} />
          </div>
        )
      })}
    </div>
  )
}

export default Calls
