import PropTypes from 'prop-types'
import { MdOutlineFileDownload, MdOutlineInsertDriveFile } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'

import styles from './DocumentBox.module.scss'

const DocumentBox = ({ sender, url, fileName, position, date, onDownload }) => {
  return (
    <div
      style={
        position === 'left' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' }
      }
      className={styles.msgBoxWrapper}
    >
      <div className={styles[position]}>
        <div className={styles.sender}>
          <span>{sender}</span>
          <span>
            <a data-tooltip-id={`download-file-${url}`}>
              <Tooltip
                id={`download-file-${url}`}
                className={styles.documentTooltip}
                content={`Download: ${fileName}`}
                place='top'
              />

              {onDownload && typeof onDownload === 'function' && (
                <MdOutlineFileDownload className={styles.downloadIcon} onClick={onDownload} />
              )}
            </a>
          </span>
        </div>
        <div className={styles.file}>
          <MdOutlineInsertDriveFile />
          <span>{fileName}</span>
        </div>
        <div className={styles.date}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  )
}

DocumentBox.propTypes = {
  sender: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
}

export default DocumentBox
