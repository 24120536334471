import React from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'

import styles from './SwitchTab.module.scss'

const SwitchTab = ({ title, checked, onChange, width = 48, height = 20, handleDiameter = 30 }) => {
  return (
    <label className={styles.switchWrapper}>
      {title && <span>{title}</span>}
      <Switch
        checked={checked}
        onChange={onChange}
        onColor='#498bf5'
        onHandleColor='#fff'
        handleDiameter={handleDiameter}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
        height={height}
        width={width}
      />
    </label>
  )
}

export default SwitchTab

SwitchTab.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  handleDiameter: PropTypes.number,
  title: PropTypes.string,
}
