const env = process.env.NODE_ENV
let url,
  wsUrl,
  clientUrl,
  matrixUrl,
  matrixServerName,
  isProduction,
  adminPanelUrl,
  marketingSiteUrl,
  wsPrefix

if (process.env.REACT_APP_PROD === 'prod') {
  url = 'https://api.airtouch.me/api/v1/'
  clientUrl = 'https://meet.airtouch.me'
  // TODO replace with valid socket address on the production
  wsUrl = 'wss://socket.hyperether.com'
  matrixUrl = 'https://messaging.airtouch.me'
  matrixServerName = 'messaging.airtouch.me'
  adminPanelUrl = 'https://admin.airtouch.me'
  marketingSiteUrl = 'https://airtouch.me'
  isProduction = true
  wsPrefix = 'airtouch'
} else if (process.env.REACT_APP_STAGING === 'staging') {
  url = 'https://airtouch.hyperether.com/api/v1/'
  clientUrl = 'https://airtouch-app.hyperether.com'
  wsUrl = 'wss://socket.hyperether.com'
  matrixUrl = 'https://matrix.hyperether.com'
  matrixServerName = 'matrix.hyperether.com'
  adminPanelUrl = 'https://airtouchtemp.hyperether.com'
  marketingSiteUrl = 'https://airtouch.me'
  isProduction = false
  wsPrefix = 'airtouchqa'
} else if (env === 'development') {
  url = 'http://localhost:3000/api/v1/'
  clientUrl = 'http://localhost:3001'
  // wsUrl = 'wss://socket.hyperether.com'
  // TODO we shold use wss://socket.hyperether.com address instead local IP address of the socket server
  wsUrl = 'ws://192.168.0.15:5656'
  matrixUrl = 'https://matrix.hyperether.com'
  matrixServerName = 'matrix.hyperether.com'
  adminPanelUrl = 'http://localhost:3002'
  marketingSiteUrl = 'https://airtouch.me'
  isProduction = false
  wsPrefix = 'airtouchqa'
} else {
  url = 'http://localhost:3000/api/v1/'
  wsUrl = 'wss://socket.hyperether.com'
  clientUrl = 'http://localhost:3001'
  matrixUrl = 'https://matrix.hyperether.com'
  matrixServerName = 'matrix.hyperether.com'
  adminPanelUrl = 'http://localhost:3002'
  marketingSiteUrl = 'https://airtouch.me'
  isProduction = false
  wsPrefix = 'airtouchqa'
}
export {
  url,
  wsUrl,
  matrixUrl,
  clientUrl,
  matrixServerName,
  isProduction,
  adminPanelUrl,
  marketingSiteUrl,
  wsPrefix,
}
