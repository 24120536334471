import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  show: false,
  content: null,
  size: 'md',
  closeOnOutsideClick: true,
  showCloseIcon: true,
  callbackOnClose: undefined,
  customStyle: '',
}
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      //it is possible to return it like this
      //return action.payload
      state.content = action.payload.content
      state.show = action.payload.show
      state.size = action.payload.size || initialState.size
      state.closeOnOutsideClick = action.payload.closeOnOutsideClick
      state.showCloseIcon =
        action.payload.showCloseIcon !== undefined
          ? action.payload.showCloseIcon
          : initialState.showCloseIcon
      state.callbackOnClose = action.payload.callbackOnClose
      state.customStyle = action.payload.customStyle
    },
    closeModal: (state) => {
      state = initialState
      return state
    },
  },
})
export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer

export const selectModal = (state) => state.modal
