import { useEffect, useState } from 'react'
import { MdLocalPhone, MdVideocam } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { truncateString } from '../../utils/string'
import SwitchTab from '../SwitchTab/SwitchTab'

import { CUSTOM_PRESENCE_TYP_MSGS } from '../../constants/matrixConst'
import initMatrix from '../../lib/initMatrix'
import { selectAllMembers } from '../../redux/reducers/matrixSlice'
import { getPresence } from '../../utils/matrix'

import styles from './Chat.module.scss'

const ChatWrapper = () => {
  const location = useLocation()
  const [isSMS, setIsSMS] = useState(false)
  const { roomId } = useParams()
  const { state } = location
  const allMembers = useSelector(selectAllMembers)
  const { roomMembers } = initMatrix
  const membersOfTheRoom = roomMembers.get(roomId) || {}

  const navigate = useNavigate()

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (Object(state).hasOwnProperty('isSms')) {
      setIsSMS(state.isSms)
    } else {
      setIsSMS(false)
    }
  }, [state, state?.isSms, roomId])

  const handleChangeSms = () => {
    setIsSMS((prev) => !prev)
  }

  const presence = getPresence(allMembers, membersOfTheRoom)

  const handleVideoCall = async () => {
    navigate('/video', { state: { roomId, chatCalling: true } })
  }

  const handleAudioCall = async () => {
    navigate('/video', { state: { roomId, chatCalling: true, audioOnly: true } })
  }

  const contactNameChat =
    state?.contactName && !state?.contactName.startsWith('acc_') && !state.isEmailContact
      ? state.contactName
      : (state?.contactName?.startsWith('acc_') || state?.isEmailContact) && state?.displayName
      ? state.displayName
      : state?.contactName || state?.roomName

  return (
    <div className={styles.chatWrapper}>
      <div className={styles.chatHeader}>
        <div className={styles.roomName}>
          <a data-tooltip-id='room-name'>
            <Tooltip
              id='room-name'
              className={styles.roomNameTooltip}
              content={state?.roomName}
              place='top'
            />
            {truncateString(contactNameChat, 23) || ''}
            <br />
          </a>
          {presence && (
            <span className={styles[presence]}>
              {CUSTOM_PRESENCE_TYP_MSGS?.[presence]?.name || presence}
            </span>
          )}
        </div>
        <div className={styles.callIconWrapper}>
          <SwitchTab
            title='SMS'
            onChange={handleChangeSms}
            checked={isSMS}
            width={24}
            height={10}
            handleDiameter={16}
          />
          <MdLocalPhone onClick={handleAudioCall} className={styles.callIcons} />{' '}
          <MdVideocam onClick={handleVideoCall} className={styles.callIcons} />
        </div>
      </div>
      <Outlet context={[roomId, isSMS]} />
    </div>
  )
}

export default ChatWrapper
