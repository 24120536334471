import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdClose, MdSearch } from 'react-icons/md'

import CustomIcon from '../CustomIcon/CustomIcon'
import CallContacts from '../CallContacts/CallContacts'

import { selectAirtouchContactsMap } from '../../redux/reducers/contactsSlice'
import { resetSearchText, setSearchText } from '../../redux/reducers/searchSlice'
import { selectCurrentId } from '../../redux/reducers/authSlice'
import {
  useDeclineNewCallMutation,
  useTransferNewCallMutation,
  useHoldAndAcceptMutation,
  useEndAndAcceptMutation,
  useMergeCallMutation,
  useMergeConferenceCallOnHoldMutation,
} from '../../api/callsApiSlice'
import { useSendVoiceAnsweredMutation } from '../../api/authApiSlice'
import { closeModal } from '../../redux/reducers/modalSlice'
import initTwilioVoice from '../../lib/initTwilioVoice'
import toastService from '../../services/toastService'
import { ReactComponent as EndCall } from '../../assets/images/callEnd.svg'
import { ReactComponent as AnswerCall } from '../../assets/images/answerCall.svg'
import { ReactComponent as HoldAndAccept } from '../../assets/images/holdAndAccept.svg'
import { ReactComponent as EndAndAccept } from '../../assets/images/endAndAccept.svg'
import { ReactComponent as MergeOff } from '../../assets/images/mergeOff.svg'
// import { ReactComponent as VoicemailOff } from '../../assets/images/voicemailOff.svg'
import { ReactComponent as TransferOff } from '../../assets/images/transferOff.svg'
import { dispatchCustomEvent } from '../../utils/fireEvents'

import styles from './IncomingCall.module.scss'

import { CUSTOM_EVENT_TYPES } from '../../constants/matrixConst'
import {
  removeCallSlot,
  selectCallSlot,
  replaceCallInSlot,
  selectCurrentCall,
  selectIsConference,
  setCallSlot,
  setCallsOnHold,
  setCurrentCall,
  setIsConference,
} from '../../redux/reducers/callSlice'

const IncomingCall = ({ incomingConnection, handleAnswer, handleReject, newCallData }) => {
  const [isOpenTransfer, setIsOpenTransfer] = useState(false)

  const dispatch = useDispatch()

  const currentCall = useSelector(selectCurrentCall)
  const userId = useSelector(selectCurrentId)
  const airtouchContacts = useSelector(selectAirtouchContactsMap)
  const isConferenceCall = useSelector(selectIsConference)
  const [declineNewCall] = useDeclineNewCallMutation()
  const [transferNewCall] = useTransferNewCallMutation()
  const [holdAndAccept] = useHoldAndAcceptMutation()
  const [endAndAccept] = useEndAndAcceptMutation()
  const [sendVoiceAnsweredMutation] = useSendVoiceAnsweredMutation()
  const [mergeCall] = useMergeCallMutation()
  const [mergeConferenceCallOnHol] = useMergeConferenceCallOnHoldMutation()
  const navigate = useNavigate()
  const callSlots = useSelector(selectCallSlot)
  const isInConference = useSelector(selectIsConference)

  const callsOnHold = callSlots?.filter((call) => call?.isOnHold) || []

  useEffect(() => {
    dispatch(resetSearchText())
  }, [dispatch])

  const handleDeclineNewCall = async () => {
    if (!newCallData?.incomingCallSid || !userId) return
    const data = await declineNewCall({
      accountId: userId,
      incomingCallSid: newCallData?.incomingCallSid,
    })
    if (data?.error) {
      toastService.show('error', 'Error decline new call')
      return
    }
    if (data?.data.isSuccess) {
      initTwilioVoice.notifyForCancel()
    }
  }

  const handleTransferCall = async (contact) => {
    if (!newCallData?.incomingCallSid || !userId || !contact) return

    const to = contact?.endpoint?.username || contact?.number
    const data = await transferNewCall({
      accountId: userId,
      incomingCallSid: newCallData?.incomingCallSid,
      to,
    })
    if (data?.error) {
      toastService.show('error', 'Error transfer call')
      return
    }
    if (data?.data.isSuccess) {
      initTwilioVoice.notifyForCancel()
    }
  }

  const handleFilterContacts = (e) => {
    const { value } = e.target
    dispatch(setSearchText(value.toLowerCase()))
  }

  const handleEndAndAccept = async (name, organizationName) => {
    if (!newCallData) return
    if (!isInConference) initTwilioVoice.connection?.disconnect()
    // calling this api server can send push notification that call is accepted
    const data = await endAndAccept({
      accountId: userId,
      callerId: newCallData.callerId,
      inProgressCallSid: newCallData.inProgressCallSid,
      incomingCallSid: newCallData.incomingCallSid,
    })
    if (data?.data?.isSuccess) {
      // nofity for ansered on call
      dispatchCustomEvent(CUSTOM_EVENT_TYPES.answered_call, newCallData)
      dispatch(removeCallSlot(currentCall))
      dispatch(
        setCallSlot({
          caller: name,
          organizationName,
          callerId: newCallData?.callerId,
          isOnHold: false,
          participants: [],
        }),
      )
      dispatch(setCurrentCall({ caller: name, organizationName, callerId: newCallData?.callerId }))
      navigate('/call', {
        state: { incommingCall: true, name, organizationName },
      })
      dispatch(closeModal())
    }
  }

  const handleHoldAndAccept = async () => {
    if (!newCallData) return

    if (!isConferenceCall) initTwilioVoice.connection?.disconnect()

    const data = await holdAndAccept({
      accountId: userId,
      incomingCallSid: newCallData?.incomingCallSid,
      inProgressCallSid: newCallData?.inProgressCallSid,
      callerId: newCallData?.callerId,
    })
    if (data?.data?.isSuccess) {
      dispatch(setIsConference(true))
      await sendVoiceAnsweredMutation({
        callSid: newCallData.incomingCallSid,
        accountId: userId,
      })
      dispatch(setCallsOnHold(currentCall))
      dispatch(
        setCurrentCall({
          callerId: newCallData?.callerId,
          caller: newCallData?.caller,
          participants: [],
          isOnHold: false,
        }),
      )
      dispatch(
        setCallSlot({
          callerId: newCallData?.callerId,
          caller: newCallData?.caller,
          participants: [],
          isOnHold: false,
        }),
      )
      dispatch(closeModal())
      toastService.show('success', `Added on hold ${currentCall?.caller}`)
    }
  }

  const handleMergeCall = async () => {
    if (!newCallData) return
    let data
    if (!shouldDisplayMergeCall) {
      if (!isConferenceCall) initTwilioVoice.connection?.disconnect()
      data = await mergeCall({
        accountId: userId,
        callerId: newCallData.callerId,
        inProgressCallSid: newCallData.inProgressCallSid,
        incomingCallSid: newCallData.incomingCallSid,
      })
      if (data?.data?.isSuccess) {
        const participants = currentCall?.participants?.length
          ? [...currentCall.participants, newCallData]
          : [currentCall, newCallData]
        const mergeCallData = {
          caller: `Merge call`,
          callerId: `${currentCall?.callerId}#${newCallData?.callerId}`,
          participants,
        }
        const indexOfCallSlot = callSlots?.findIndex(
          (call) => call?.callerId === currentCall?.callerId,
        )
        if (indexOfCallSlot > -1) {
          dispatch(replaceCallInSlot({ index: indexOfCallSlot, call: mergeCallData }))
        } else {
          dispatch(removeCallSlot({ ...currentCall, action: 'merge' }))
          dispatch(setCallSlot(mergeCallData))
        }
        dispatch(setCurrentCall(mergeCallData))
        dispatch(setIsConference(true))
        navigate('/call', {
          state: { incommingCall: true, name: `Merge call`, organizationName },
        })
      }
    } else {
      data = await mergeConferenceCallOnHol({
        accountId: userId,
        callerId: newCallData.callerId,
      })
    }
    if (data?.data?.isSuccess) {
      dispatch(closeModal())
    }
  }

  let from = incomingConnection?.parameters?.From || 'Unknown number'
  let customCallerId =
    incomingConnection?.customParameters.get('customCallerId') || newCallData?.callerId
  if (from?.startsWith('+222')) from = `+${from.slice(4)}`
  if (from?.startsWith('client:acc_')) from = `${from.slice(7)}`
  if (from?.startsWith('client:')) from = `+${from.slice(7)}`

  let fromAsAirtouch = `acc_${from.slice(1)}`
  const displayName =
    newCallData?.caller ||
    airtouchContacts?.[customCallerId]?.displayName ||
    airtouchContacts?.[customCallerId]?.name ||
    airtouchContacts?.[from]?.displayName ||
    airtouchContacts?.[from]?.name ||
    airtouchContacts?.[fromAsAirtouch]?.displayName ||
    from ||
    customCallerId

  const organizationName =
    airtouchContacts?.[customCallerId]?.organizationName ||
    airtouchContacts?.[from]?.organizationName ||
    airtouchContacts?.[fromAsAirtouch]?.organizationName

  const occupiedSlots = callSlots.filter((call) => call?.callerId) || []
  const shouldDisplayMergeCall = occupiedSlots.length || callsOnHold?.length ? false : true

  return (
    <div className={styles.wrapper}>
      {isOpenTransfer ? (
        <div className={styles.callContactsWrapper}>
          <header className={styles.callContactsHeader}>
            <div className={styles.searchInputWrapper}>
              <input
                type='text'
                onChange={handleFilterContacts}
                placeholder='Search'
                className={styles.input}
              />
              <MdSearch className={styles.icon} />
            </div>
            <span style={{ marginRight: '10px' }} onClick={() => setIsOpenTransfer(false)}>
              <MdClose />
            </span>
          </header>
          <CallContacts handleTransferCall={handleTransferCall} />
        </div>
      ) : null}
      <div className={styles.contactInfo}>
        <span className={styles.callingInfo}>Incoming call</span>
        <span className={styles.name}>{displayName}</span>
        {organizationName && <span className={styles.callingInfo}>{organizationName}</span>}
      </div>
      <div className={styles.btnWrapper}>
        {newCallData ? (
          <>
            <div className={styles.newCallBtns}>
              {!shouldDisplayMergeCall && (
                <CustomIcon
                  icon={<MergeOff className={styles.icon} />}
                  onClick={handleMergeCall}
                  label='Merge'
                />
              )}
              {/* <CustomIcon
                icon={<VoicemailOff className={styles.icon} />}
                onClick={() => handleReject()}
                label='Voicemal'
              /> */}
              <CustomIcon
                icon={<TransferOff className={styles.icon} />}
                onClick={() => setIsOpenTransfer(true)}
                label='Transfer'
              />
            </div>
            <div className={styles.newCallBtns}>
              <CustomIcon
                icon={<EndAndAccept className={styles.icon} />}
                label='End & accept'
                onClick={() => handleEndAndAccept(displayName, organizationName)}
              />
              <CustomIcon
                icon={<EndCall className={styles.icon} />}
                onClick={handleDeclineNewCall}
              />
              <CustomIcon
                label='Hold & accept'
                icon={<HoldAndAccept className={styles.icon} />}
                onClick={() => handleHoldAndAccept()}
              />
            </div>
          </>
        ) : (
          <div className={styles.answerBtn}>
            <CustomIcon
              icon={<AnswerCall className={styles.icon} />}
              onClick={() =>
                handleAnswer(displayName, organizationName, customCallerId || from, newCallData)
              }
            />
            <CustomIcon icon={<EndCall className={styles.icon} />} onClick={() => handleReject()} />
          </div>
        )}
      </div>
    </div>
  )
}

export default IncomingCall

IncomingCall.propTypes = {
  incomingConnection: PropTypes.object,
  newCallData: PropTypes.object,
  setShowIncomingCall: PropTypes.func,
  handleAnswer: PropTypes.func,
  handleReject: PropTypes.func,
}
