export const truncateString = (str, maxLength = 25) => {
  if (!str || str.length <= maxLength) {
    return str
  }
  return str.substring(0, maxLength) + '...'
}

export const parseString = (payload) => {
  if (!payload || typeof payload !== 'string') return {}
  return JSON.parse(payload)
}
