import { api } from './index'

const messageApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createRoom: builder.mutation({
      query: (data) => {
        return {
          url: `/matrix/${data.id}/createRoom`,
          method: 'POST',
          body: {
            accountContactNumbers: data.accountContactNumbers,
            roomName: data.roomName,
          },
        }
      },
    }),
  }),
})
export const { useCreateRoomMutation } = messageApiSlice
