import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { wsPrefix, wsUrl } from './environment'
import { localStorageService } from './services/localStorage'
import { dispatchCustomEvent } from './utils/fireEvents'
import { selectCurrentUser } from './redux/reducers/authSlice'
import { CUSTOM_EVENT_TYPES } from './constants/matrixConst'

const PING_INTERVAL = 30 * 1000 // 30 sec
const PONG_INTERVAL = 10 * 1000 // 10 sec

const Socket = () => {
  const user = useSelector(selectCurrentUser)
  const socketRef = useRef(null)
  const liveRef = useRef(false)
  const reconnectTimeRef = useRef(500)

  const pingIntervalRef = useRef(null)
  const pongTimeoutRef = useRef(null)

  const pongNotReceived = useCallback(() => {
    socketRef.current?.close()
  }, [])

  const ping = useCallback(() => {
    try {
      socketRef.current?.send(JSON.stringify({ ping: true }))
      if (pongTimeoutRef?.current) clearTimeout(pongTimeoutRef.current)
      pongTimeoutRef.current = setTimeout(pongNotReceived, PONG_INTERVAL)
    } catch (err) {
      console.log('Error sending ping', err)
    }
  }, [pongNotReceived])

  const disconnect = useCallback(() => {
    liveRef.current = false
    if (socketRef.current?.close && socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.close()
    }
  }, [])

  const connect = useCallback(
    (username) => {
      console.log(new Date() + ' Connecting to WebSocket')
      const apiToken = localStorageService.get('sct')
      liveRef.current = true
      socketRef.current = new WebSocket(`${wsUrl}/ws/${wsPrefix}_${username}/${apiToken}`)

      socketRef.current.onopen = () => {
        console.log(new Date() + 'Connected to WebSocket')
        reconnectTimeRef.current = 1000
        if (pingIntervalRef?.current) clearInterval(pingIntervalRef.current)
        pingIntervalRef.current = setInterval(ping, PING_INTERVAL)
      }

      socketRef.current.onclose = (reason) => {
        console.log(new Date() + 'Closing WebSocket', reason)
        if (!liveRef.current) return
        setTimeout(() => {
          if (liveRef.current) connect(username)
        }, reconnectTimeRef.current)
      }

      socketRef.current.onmessage = (message) => {
        try {
          const event = JSON.parse(message.data)
          if (event) {
            if (event.pong === true) {
              clearTimeout(pongTimeoutRef.current)
              return
            }
          }
          // here we are dispatching a custom event and we can listen in the components for this event
          dispatchCustomEvent(CUSTOM_EVENT_TYPES.socketResponse, event)
        } catch (err) {
          console.log('socket error parsing websocket data')
        }
      }

      socketRef.current.onerror = (err) => {
        console.log(new Date() + 'Websocket error', err)
        reconnectTimeRef.current *= 2
        if (reconnectTimeRef.current > 30000) {
          reconnectTimeRef.current = 30000
        }
      }
    },
    [ping],
  )

  useEffect(() => {
    if (user?.endpoint?.username) {
      connect(user.endpoint.username)
    } else {
      disconnect()
    }
  }, [connect, disconnect, user?.endpoint?.username])

  /**
   * This is example how we can send a cutom message to the socket server
   * useing custom event
   */
  // useEffect(() => {
  //   const handleSendSocketMessage = (event) => {
  //     if (!socketRef.current?.readyState !== 1) return
  //     const { organizationId } = event.detail
  //     socketRef.current?.send(
  //       JSON.stringify({ type: 'subscribe', channel: `${wsPrefix}_${organizationId}` }),
  //     )
  //   }
  //   document.addEventListener('socket-message', handleSendSocketMessage)

  //   return () => {
  //     document.removeEventListener('socket-message', handleSendSocketMessage)
  //   }
  // }, [])

  useEffect(() => {
    return () => disconnect()
  }, [disconnect])

  return <div />
}

export default Socket
