import { api } from './index'
import { setExtensions } from '../redux/reducers/extensionsSlice'
import { tag } from './rtkTags'

const extensionApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getExtensions: builder.query({
      query: (id) => ({
        url: `/account/${id}/extensions`,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setExtensions(data.extensions))
        } catch (err) {
          console.log(err)
        }
      },
    }),
    updateOrganization: builder.mutation({
      query: (data) => {
        return {
          url: `account/${data.accountId}/selectExtension`,
          method: 'PUT',
          body: { extensionId: data.extensionId },
        }
      },
      invalidatesTags: [tag.user],
    }),
  }),
})
export const { useLazyGetExtensionsQuery, useUpdateOrganizationMutation } = extensionApiSlice
