import React from 'react'
import PropTypes from 'prop-types'

import styles from './Input.module.scss'

const Input = ({ className, placeholder, onChange, forwardRef, type, value }) => {
  return (
    <div className={`${className} ${styles.wrapper} `}>
      <input
        className={`${className} ${styles.defaultInput} `}
        placeholder={placeholder}
        onChange={onChange}
        ref={forwardRef}
        type={type}
        value={value}
      />
    </div>
  )
}

export default Input

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  forwardRef: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
}
