import React from 'react'
import PropTypes from 'prop-types'

import styles from './Button.module.scss'

const Button = ({ label, disabled = false, type, className, onClick, children }) => {
  return (
    <div className={styles.wrapper}>
      <button
        type={type}
        className={`${className} ${styles.primaryBtn}`}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
        {children}
      </button>
    </div>
  )
}

export default Button

Button.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}
