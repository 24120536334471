import PropTypes from 'prop-types'
import audioIncoming from '../../assets/images/audioIncoming.png'
import audioMade from '../../assets/images/audioMade.svg'
import audioMissed from '../../assets/images/missed.svg'
import videoIncoming from '../../assets/images/videoIncoming.svg'
import videoMade from '../../assets/images/videoMade.svg'
import videoMissed from '../../assets/images/videoMissed.svg'
import { formatSeconds } from '../../utils/dateParsing'

import styles from './CallBox.module.scss'

const callTypeMessage = {
  voice: '',
  video: 'Video',
}

const callDirectionIcons = {
  inbound: <img className={styles.callIcon} src={audioIncoming} alt='' />,
  'inbound-no-answer': <img className={styles.callIconMiss} src={audioMissed} alt='' />,
  outbound: <img className={styles.callIcon} src={audioMade} alt='' />,
}

const videoCallDirectionIcons = {
  inbound: <img className={styles.videoCallIcon} src={videoIncoming} alt='' />,
  'inbound-no-answer': <img className={styles.videoCallIcon} src={videoMissed} alt='' />,
  outbound: <img className={styles.videoCallIcon} src={videoMade} alt='' />,
}

const CallBox = ({ sender, content, date }) => {
  const callDirection = sender ? 'inbound' : 'outbound'
  const callStatus = content?.callStatus === 'no-answer' && callDirection === 'inbound'
  return (
    <div className={styles.callBoxWrapper}>
      <div className={callStatus ? styles.missedCall : styles.callMade}>
        {content.callType === 'voice' ? (
          <span>
            {callStatus
              ? callDirectionIcons[callDirection + '-' + content?.callStatus]
              : callDirectionIcons[callDirection]}
          </span>
        ) : (
          <span>
            {callStatus
              ? videoCallDirectionIcons[callDirection + '-' + content?.callStatus]
              : videoCallDirectionIcons[callDirection]}
          </span>
        )}
        <div className={styles.call}>
          <span>
            {sender
              ? `Incoming ${callTypeMessage[content?.callType]} call`
              : `Outgoing ${callTypeMessage[content?.callType]} call`}
          </span>
          {callStatus ? (
            <span className={styles.duration}>Missed call</span>
          ) : (
            <span className={styles.duration}>Duration {formatSeconds(content?.duration)}</span>
          )}
        </div>
        <div className={styles.date}>
          <span>{date}</span>
        </div>
      </div>
    </div>
  )
}

CallBox.propTypes = {
  sender: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
}

export default CallBox
